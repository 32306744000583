import React from 'react'

import './RadioInput.css'

const RadioInput = ({inputName, inputValue, iniputId, text, assessmentInput,handleChange,checked}) => {
  return (
    <div className={`${assessmentInput ? 'radioBtn-assessment' : 'radioBtn'}`}>
        <input
          className='hidden'
          type="radio"
          name={inputName}
          value={inputValue}
          id={iniputId}
          onChange={handleChange}
          checked={checked}
        />
        <label htmlFor={iniputId} className={`${assessmentInput ? 'radioLabel-assessment' : 'radioLabel'}`}>
            {text}
        </label>                        
    </div>
  )
}

export default RadioInput
