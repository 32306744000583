import React from 'react';
import { Link } from 'react-router-dom';

import ArchiveCandidates from './ArchiveCandidates';

const ArchiveMain = ({tab,candidates,schedulesLoading}) => {
    // const tabs = ['Candidates','Questions','Assessments']
    const tabs = ['Candidates']
    
    return (
        <div className='main-container mx-auto md:ml-20 mt-1 flex flex-col lg-px-10 py-2'>
            <div className="card relative lg:h-[90vh] overflow-hidden">
                
                <div className='application-filter-bar flex gap-2 h-[10%] pr-4'>
                    <div className='left-item w-full flex items-center gap-3 py-2 px-4 overflow-x-auto scrollbar-none'>
                        {tabs?.map((tabName)=>{
                            return (
                                <Link
                                    to ={`/archive${tabName}`}
                                    key={tabName}
                                    id={`tab-${tabName}`}
                                    className={`tab-btn capitalize text-sm font-medium rounded-full px-8 py-2 cursor-pointer ${tab === tabName.toLowerCase() ? 'active' : 'not-active'}`}
                                >
                                    {tabName}
                                </Link>
                            )
                        })}
                    </div>
                </div>
                
                <div className='w-ful h-[90%] px-4 py-2'>
                    {tab === 'candidates' && (
                        <ArchiveCandidates
                            candidates={candidates}
                            schedulesLoading={schedulesLoading}
                        />
                    )}
                    {tab === 'questions' && (
                        <>

                        </>
                    )}
                    {tab === 'assessments' && (
                        <>

                        </>
                    )}
                </div>
            </div>
        </div>
  )
}

export default ArchiveMain