import React, { useState } from 'react'
import axios from 'axios';

import {Button, TextInput} from '../../components'

const CreateAssessmentType = ({questionSheetId,closeCreateAssessmentModule,setUpdateAssessmentTypes}) => {
  const [newAssessment, setNewAssessment] = useState()

  const [successModuleOpen, setSuccessModuleOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const handleCreate = async (e) => {
    e.preventDefault()
    setLoading(true)
    setSuccessModuleOpen(true)
    const sheetData = `${questionSheetId}|${newAssessment}`;
    const response = await axios.post(process.env.REACT_APP_CREATE_ASSESSMENT_TYPE,sheetData)
    if(response.data.status === 'success'){
      setUpdateAssessmentTypes(prev => prev+1)
    }else{
       setSuccessModuleOpen(false)
    }
    setLoading(false)
  }
  return (
    <div className='flex flex-col w-[95%] common-popup gap-8 card p-10 relative'>
        {closeCreateAssessmentModule && (
          <>
            <div
              onClick={closeCreateAssessmentModule}
              className='flex justify-center items-center absolute top-4 right-4 rounded-full w-10 h-10 bg-[#F9FAFB] cursor-pointer'
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-7 h-7">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </div>

            <div className={`${successModuleOpen ? 'flex' : 'hidden'} card absolute top-0 left-0 h-full w-full flex gap-5 flex-col justify-center items-center`}>
                {loading ? (
                    <div className='font-bold text-[#888] text-2xl'>
                        Loading ...
                    </div>
                ) : (
                    <>
                        <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                            <p className='text-white text-4xl'>✓</p> 
                        </div>
                        <h1 className='text-lg'>The Assessment Type was successfully added.</h1>
                            <Button
                                text=" OK "
                                width='6rem'
                                handleClick = {() => {
                                  setSuccessModuleOpen(false)
                                  closeCreateAssessmentModule()
                                }}
                            />
                    </>
                )}                               
            </div>
          </>          
        )}        

        <h1 className='heading2 w-[100%]'>
            Create New Assessment Type
        </h1>
        <form className='flex flex-col w-full gap-6'>
            <div>
              <label className="text-xs mb-1 text-[#888]">
                Assessment Type
              </label>
              <TextInput
                type="text"
                idName="assessmentType"
                inputName = "assessmentType"
                isRequired={true}
                placeholderText="Assessment Type"
                inputValue={newAssessment}
                handleChange={e => setNewAssessment(e.target.value)}
              />
            </div>
            <Button
              text="Create Assessment"
              handleClick={handleCreate}
            />
        </form>
    </div>
  )
}

export default CreateAssessmentType