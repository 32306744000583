import React from 'react'

const Logo = ({width}) => {
  return (
    <div className="logo flex justify-center items-center">
        <img className={`w-[${width}]`} src="./img/logo_full.png" alt="logo" />
    </div>
  )
}
Logo.defaultProps = {
  width:"10rem"
}

export default Logo
