import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import {Button} from '../../components'
import CreateAssessmentType from './CreateAssessmentType';
import EditAssessmentType from './EditAssessmentType';

const AssessmentType = ({assessmentNames,questionSheetId,assessmentNamesLoading,updateRecords,setUpdateAssessmentTypes}) => {

    const [newType, setNewType] = useState('')

    const [createAssessmentModule,setCreateAssessmentModule ] = useState()
    useEffect(() => setCreateAssessmentModule(document.getElementById('createAssessmentModule')),[])
    const [deleteAssessmentModule,setDeleteAssessmentModule ] = useState()
    useEffect(() => setDeleteAssessmentModule(document.getElementById('deleteAssessmentModule')),[])
    const [editAssessmentModule,setEditAssessmentModule ] = useState()
    useEffect(() => setEditAssessmentModule(document.getElementById('editAssessmentModule')),[])
    const [successModule,setSuccessModule ] = useState()
    useEffect(() => setSuccessModule(document.getElementById('successModule')),[])

    const showCreateAssessmentModule = () => {
        createAssessmentModule?.classList.remove('hidden')
        createAssessmentModule?.classList.add('flex')
    }
    const closeCreateAssessmentModule = () => {
        createAssessmentModule?.classList.remove('flex')
        createAssessmentModule?.classList.add('hidden')
    }

    const [deleteSuccessModuleOpen, setDeleteSuccessModuleOpen] = useState(false)
    const [loading,setLoading] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const deleteAssessmentType = async () => {
        setLoading(true)
        closeDeleteModule()
        setDeleteSuccessModuleOpen(true)
        const deleteData = JSON.stringify([questionSheetId, deleteId])
        const response = await axios.post(process.env.REACT_APP_RENAMNE_ASSESSMENT_TYPE,deleteData)
        if(response.data.status === 'success'){            
            setUpdateAssessmentTypes(prev =>prev+1)
        }else{
            setDeleteSuccessModuleOpen(false)
        }
        setLoading(false)
        
    }
    const openDeleteModule = (e) => {
        setDeleteId(e.target.id)
        deleteAssessmentModule?.classList.remove('hidden')
        deleteAssessmentModule?.classList.add('flex')
    }
    const closeDeleteModule = () => {
        deleteAssessmentModule?.classList.remove('flex')
        deleteAssessmentModule?.classList.add('hidden')
    }
    const openEdit = (e) => {
        setNewType(e.target.id)
        editAssessmentModule?.classList.remove('hidden')
        editAssessmentModule?.classList.add('flex')
    }
    const closeEditAssessmentModule = () => {
        editAssessmentModule?.classList.remove('flex')
        editAssessmentModule?.classList.add('hidden')
    }


  return (
    <div className='main-container'>
        <div className="card w-full lg:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col sm:px-4 lg:px-10 pt-6 pb-2 overflow-hidden">

            <div id='createAssessmentModule' className='hidden z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
                <CreateAssessmentType
                    setUpdateAssessmentTypes={setUpdateAssessmentTypes}
                    questionSheetId={questionSheetId}
                    closeCreateAssessmentModule={closeCreateAssessmentModule}
                />
            </div>
            <div id='editAssessmentModule' className='hidden z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
                <EditAssessmentType
                    questionSheetId={questionSheetId}
                    newType={newType}
                    closeEditAssessmentModule={closeEditAssessmentModule}
                    updateRecords = {updateRecords}
                    setUpdateAssessmentTypes = {setUpdateAssessmentTypes}
                />
            </div>
            <div id='deleteAssessmentModule' className='hidden z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
                <div className='card p-10 flex flex-col justify-center items-center w-[95%] common-popup'>
                    <p className='font-semibold text-xl mb-5 text-center w-[100%]'>
                        Do you really want to delete this assessment Type ? 
                    </p>
                    <div className='w-full flex mt-8 gap-5'>
                        <Button
                            text="Delete"
                            version="red"
                            width='50%'
                            handleClick={deleteAssessmentType}
                        />
                        <Button
                            text="Cancel"
                            version='secondary'
                            width='50%'
                            handleClick={closeDeleteModule}
                        />
                    </div>                
                </div>
            </div>
            {/*success module */}
            <div className={`${deleteSuccessModuleOpen ? 'flex' :'hidden'} z-10 absolute top-0 left-0 justify-center items-center w-full h-full bg-[#000]/10 backdrop-blur-[2px]`}>
                <div className='common-popup card p-8 flex flex-col justify-center items-center gap-5 text-center'>
                    {
                        loading ? 
                        <div className='text-2xl text-[#888] font-bold'>
                            Loading...
                        </div>
                        :
                        <>
                            <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                                <p className='text-white text-4xl'>✓</p> 
                            </div>
                            <h1 className='text-lg'>Assessment type was successfully deleted.</h1>
                            <Link to='/assessmentType'>
                                <Button
                                    text=" OK "
                                    width='6rem'
                                    handleClick={() => setDeleteSuccessModuleOpen(false)}
                                />
                            </Link>
                        </>
                    }               
                    
                </div>
            </div>

            { assessmentNamesLoading ? (
                <div className='w-full h-full flex justify-center items-center'>
                    <p className='text-2xl font-bold text-[#888]'>
                        Loading . . .
                    </p>
                </div>
            ) : (assessmentNames?.length === 0 ? (
                    <div className='h-full w-full flex flex-col justify-center items-center gap-5'>
                        <h1 className='font-semibold text-2xl text-[#292D32]'>
                            Create Your First Assessment Type
                        </h1>
                        <p className='w-2/5 text-center text-sm text-[#666]'>
                            Oops! It looks like you haven't created an assessment type yet. Please create an assessment type to get started.
                        </p>
                            <Button
                                handleClick={showCreateAssessmentModule}
                                width='16rem'
                                text='Create Assessment Type'
                            />
                    </div>
                ) : (
                <>
                    <div className='flex justify-end mb-4 items-center mr-4 sm:mx-0'>
                        <div>
                            <Button
                                handleClick={showCreateAssessmentModule}
                                width='16rem'
                                text='Create Assessment Type'
                            />                    
                        </div>
                    </div>
                    <div className="w-full flex flex-col">
                        <div className='flex py-4 pl-10 lg:pl-16 bg-[#E9EAEB]/60 backdrop-blur-lg font-medium text-sm capitalize text-black md:rounded-t-xl'>
                            <div className="w-1/2 sm:w-3/5">Assessment Type</div>
                            <div className="hidden sm:flex w-2/5">Total Questions</div>
                            <div className="w-1/2 sm:w-1/5 text-center">Actions</div>
                        </div>
                        <div className='h-[70vh] lg:h-[65vh] overflow-y-scroll scrollbar-none'>
                            {
                                assessmentNames
                                    ?.map((assessment)=> {
                                    return (
                                        <div key={assessment} className='flex items-center text-xs py-4 pl-10 lg:pl-16 bg-[#fff] even:bg-[#F9FAFB] backdrop-blur-lg sticky top-0 font-medium text-[#414143]'>
                                            <div className="w-1/2 sm:w-3/5 capitalize">{assessment}</div>
                                            <div className="hidden sm:flex w-2/5">Total</div>
                                            <div className="w-1/2 sm:w-1/5 flex     justify-center items-center gap-8">
                                                <div className='relative'>
                                                    <div
                                                        id={assessment}
                                                        onClick={openEdit}
                                                        className='absolute top-0 left-0 w-full h-full cursor-pointer'
                                                    />
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 text-[#666]">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                </div>
                                                <div className='relative'>
                                                    <div
                                                        id={assessment}
                                                        onClick={(e) =>openDeleteModule(e)}
                                                        className='absolute top-0 left-0 w-full h-full cursor-pointer'
                                                    />
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-[#f00]">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> 
                </>
                )
            )}          
        </div>
    </div>
  )
}

export default AssessmentType