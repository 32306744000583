import React from 'react'

const LogoLoading = ({width}) => {
  return (
    <div className="flex h-full w-full justify-center items-center">
        <img className={`w-[${width}]`} src="./img/logo_full.png" alt="logo" />
    </div>
  )
}

export default LogoLoading
