
const emailBody = {

    candidateEmail : (companyName,name,email,startDate,startTime,expDate,expTime,code) => {
        return (`
        Hello <b>${name}</b>,<br>
        Thank you for applying at <b>${companyName}</b>.<br><br>
        
        This is to inform you that you have been selected for the next round of <b>Online Assessment Test</b>.
        <br><br>
        Kindly read the instruction and visit the link below :
        <br>
        <br><b>Instructions - </b><br>
            1. You have to attempt the test in the time window given below.<br>
            2. Make sure to join from a Laptop/PC with a working webcam and microphone.<br><br>

            <b>Assessment Date & Time - </b></br>
            ${startDate} - ${startTime} to ${expDate} - ${expTime}<br><br>
        
        <b>Test Credentials - </b><br>
            <b>Assessment Link - </b> ${process.env.REACT_APP_URL}?key=${companyName.replaceAll(" ", "")} <br>
            <b>Login ID - </b> ${email}<br>
            <b>Login Code - </b> ${code}<br> <br>
        
        <b>Need to make changes to this event?</b> <br>
        Login with the same credentials and reschedule the test <br> <br>
        
        <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
        LinkedIn - https://www.linkedin.com/company/talentigo/ <br><br>
        
        Good Luck!<br>
        <b>${companyName}</b>
        `)
    },

    hrEmail : (name,email,startDate,startTime,profile,expDate,expTime) => {
        return (`
        
Team Talentigo has scheduled an Assessment for you.<br>

Please find the details below :<br><br>
        
    Assessment Date & Time - 
    ${startDate} - ${startTime} to ${expDate} - ${expTime}<br>
    Candidate Name - ${name}<br>
    Candidate Email id - ${email}<br>
    Assessment - ${profile}<br><br>
        
Regards<br>
Team Talentigo
        
        `)
    },

    dateUpdateCandidaateEmail : (companyName,companyEmail,name,email,startDate,startTime,expDate,expTime,code) => {
        return (`
Hello ${name},<br>
As per your request, we have rescheduled/updated your Interview.<br>
Please, find the details of your updated interview round for the Online Assessment Test:<br><br>

<b>Assessment Date & Time - </b></br>
${startDate} - ${startTime} to ${expDate} - ${expTime}<br><br>

Kindly read the instruction and visit the link below :<br><br>

Instructions - <br>
    1. Make sure to take the Assessment on the designated date and time.
         It won't open if you try on any other dates.<br>
    2. Make sure to join from a Laptop/PC with a working webcam and microphone.<br>
    3. Should you wish to change the assessment dates, please request back at ${companyEmail}.<br><br>
            
Test Credentials -<br>
    Assessment Link - ${process.env.REACT_APP_URL}?key=${companyName.replaceAll(" ", "")}<br>
    Login ID - ${email}<br>
    Login Code - ${code}<br><br>
    
<b>Please follow our LinkedIn page for Future Job Updates</b> <br>
LinkedIn - https://www.linkedin.com/company/talentigo/ <br><br>
         
Good Luck!<br>
${companyName}<br>
        `)
    },

    resultEmail : (name,email,exam,totalQuestion,attempted,correct,score,warnings,questions) => {
        return (`
        
Technical Assessment Results<br><br>

Name : ${name}<br>
Emaili : ${email}<br>
Assessment : ${exam}<br><br>

Questions and Responses :<br>
${questions}<br><br>

Result : <br>
Total Question : ${totalQuestion}<br>
Attempted : ${attempted}<br>
Warnings : ${warnings}<br>
correct : ${correct}<br>
Candidate Score : ${score}<br>
        
        `)
    },

    shortlistEmail : (candidateName,companyName,companyEmail,examDate) => {
        return (`
Hey <b>${candidateName},</b><br><br>

This is in accordance with your Interview with <b>${companyName}</b> on <b>${examDate}</b><br>
We are pleased to inform you that you have been shortlisted for the next round of Interviews.
Kindly look forward to receiving the comprehensive details of the next round of interviews via email. <br><br>

If you have any questions or concerns, please do not hesitate to contact us at <b>${companyEmail}</b><br><br>

<b> Please follow our LinkedIn page for future Job Updates <br>
LinkedIn - </b> https://www.linkedin.com/company/talentigo/ <br><br>

Thanks & Regards<br>
<b> ${companyName} </b>
        `)
    },

    rejectedEmail : (candidateName,companyName) => {
        return (`
Hey <b>${candidateName}!</b><br><br>
Thank you very much for taking the time to apply at ${companyName}. We appreciate your interest in the company and the job.<br><br>

We regret to inform you that we shall not be able to proceed with your application at this time, as we have selected the candidate whom we believe most closely matches the current requirements of the position.<br><br>

We will definitely keep your resume in our talent database, and in case we have a job opening that better fits your profile, we will make sure to get in touch with you.<br><br>

Again, thank you for your time. We wish you good luck in your future endeavors! <br><br>

<b>Please follow our LinkedIn page for future Job Updates<b><br>
LinkedIn - https://www.linkedin.com/company/talentigo/ <br><br>

Thanks & Regards,<br>
<b>${companyName}.</b>
        `)
    },

    verificationEmail : (companyName,companyId) => {
        return (`
Hello ${companyName},<br>
Please Verify your email id and activate account by clicking on the link below.<br><br>

Verification Link - ${process.env.REACT_APP_URL}/verification?key=${companyId} <br><br>

Regards<br>
Team Talentigo<br>
        `)
    },

    forgetPass : (companyName,id) => {
        return (`
Hello ${companyName},<br><br>

Please follow the link to reset your password<br><br>

Password Reset Link - ${process.env.REACT_APP_URL}/resetpass?key=${id}<br><br>

Regards<br>
Team Talentigo<br>
        `)
    }
}

export default emailBody