import React, { useState,useEffect } from 'react'
import axios from 'axios';

import { TextInput,Button, Success } from '../../components'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import linkedin from './../../pages/candidateApplyForm/linkedin.png'
import { LINKEDIN_CLIENT_ID,REDIRECT_URI,SCOPE,constgetLinkedInUserInfoFromAccessCode } from '../../constant/Constants'
import { constLinkedInPostJob } from '../../constant/Constants';

const NewJob = ({dataSheetId}) => {
    const [jobTitle, setJobTitle] = useState("");
    const [jobTitleError, setJobTitleError] = useState("");
    const [positions, setPositions] = useState("")
    const [department, setDepartment] = useState("");
    const [departmentError, setDepartmentError] = useState("");
    const [tags, setTags] = useState([]);
    const [employmentType, setEmploymentType] = useState("");
    const [employmentTypeError, setEmploymentTypeError] = useState("");
    const [category, setCategory] = useState("");
    const [edcationReq, setEdcationReq] = useState("");
    const [edcationReqError, setEdcationReqError] = useState("");
    const [experienceReq, setExperienceReq] = useState("");
    const [experienceReqError, setExperienceReqError] = useState("");
    const [minHours, setMinHours] = useState("");
    const [maxHours, setMaxHours] = useState("");
    const [minSalary, setMinSalary] = useState("");
    const [maxSalary, setMaxSalary] = useState("");
    const [salaryPeriod, setSalaryPeriod] = useState("");
    const [currency, setCurrencye] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [requirment, setRequirment] = useState("");
    const [requirmentError, setRequirmentError] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [pin, setPin] = useState("");
    const [remoteJob, setRemoteJob] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [jobOwnerId, setjobOwnerId] = useState("")

    // function markdown(text) {
    //     return { html: marked.parse(String(text)) };
    // }
    
    const [countries, setCountries] = useState([]);
    const [linkedInAuthToken,setLinkedInAuthToken] = useState(null);
    useEffect(() => {
        const fetchCountries = async () => {
        const response = await fetch('https://restcountries.com/v2/all');
        const data = await response.json();
        const formattedCountries = data.map((country) => country.name);
        setCountries(formattedCountries);
        };
        fetchCountries();
    }, []);

    const linkedAutoPosttJob = async (accessCode, ownerId) => {

        var body1 ={
            "distribution": {
                "linkedInDistributionTarget": {}
            },
            "owner": "urn:li:person:"+ownerId,
            "subject": "this is subject",
            "text": {
                "text": jobTitle+"  \n \n Department: "+department+" \n Number of position: "+positions+" \n\n Tags: \n Employement Type: "+employmentType+" \n Category: "+category+" \n Required Education: "+edcationReq+" \n \n "+description+" \n "+description+" \n "+requirment+" \n \n Location \n Country: "+country+" \n State: "+state+" \n City: "+city+" \n Street: "+street+" \n Pin code: "+pin+" \n Remote Work: "+remoteJob
            }
        }
        fetch(constLinkedInPostJob, {
            method: 'POST',
            body: JSON.stringify(body1),
            headers:{
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+accessCode
            }
          }).then(res => res.json())
          .then(response =>{
            // console.log(JSON.stringify(response))
            if(response.owner != null && response.activity !=null){
                setisSuccessCustomMessage(true)
                setcustomMessage("Post uploaded on linked in")
            }else{
                setErrorMessage('Duplicate post, Add new content')
            }
            
          }).catch(error => console.error('Error:', error));
    }

    
    const getLinkedInUserInfoFromAccessCode = async (accessCode) => {
        fetch(constgetLinkedInUserInfoFromAccessCode, {
            method: 'POST',
            body: JSON.stringify({accessCode: accessCode }),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(response =>{
            setLinkedInAuthToken(response.accessToken.access_token);
            setjobOwnerId(response.user.id);
          }).catch(error => console.error('Error:', error));
    }

    const handleAddTag = () => {
        const newTags = [...tags];
        newTags.push('');
        setTags(newTags);
    };

    const [isSuccessOpen, setIsSuccessOpen] = useState(false)
    const [isSuccessCustomMessage, setisSuccessCustomMessage] = useState(false)
    const [customMessage, setcustomMessage] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(""); setJobTitleError(""); setDepartmentError(""); setEmploymentTypeError(""); setEdcationReqError(""); setExperienceReqError(""); setDescriptionError(""); setRequirmentError("")
        
        if(jobTitle !== "" && department !== "" && employmentType !== "" && edcationReq !== "" && experienceReq !== "" && description !== "" && requirment !== "") {
            
            if(linkedInAuthToken != null){
                setLoading(true)
                const jid = `jid_${Date.now()}`
                const jobTags = JSON.stringify(tags)
                    const sheetData = JSON.stringify([dataSheetId, jid, jobTitle, department, positions, employmentType, category, edcationReq, experienceReq,minHours+" to "+maxHours ,minSalary+" to "+maxSalary,salaryPeriod,currency,description,requirment,jobTags,country,state,city,street,pin,remoteJob]);
                    // console.log(sheetData)
                const response = await axios.post(process.env.REACT_APP_POST_NEW_JOB,sheetData)
                if(response.data.status === 'added'){
                    setIsSuccessOpen(true)
                    linkedAutoPosttJob(linkedInAuthToken,jobOwnerId)
                }else{
                    setErrorMessage('Something went wrong')
                }
                setLoading(false)
            }else{
                setErrorMessage('Please Authenticate first to upload it on linked in')
            }
        }
        else{
            if(jobTitle === ""){setJobTitleError(true)}            
            if(department === ""){setDepartmentError(true)}
            if(employmentType === ""){setEmploymentTypeError(true)}
            if(edcationReq === ""){setEdcationReqError(true)}
            if(experienceReq === ""){setExperienceReqError(true)}
            if(description === ""){setDescriptionError(true)}
            if(requirment === ""){setRequirmentError(true)}
            setErrorMessage("Please fill out all the mandatory fields")
        }
    }

  return (
    <div className='relative card h-[86vh] w-[90vw] md:w-[85vw] lg:w-[90vw] mx-auto md:ml-20 mt-2 flex flex-col sm:px-4 lg-px-10 pt-6 pb-2 overflow-hidden'>
        <div className={`${isSuccessOpen ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
            <Success
                message="Successfully Added !"
                closeSuccessModule={() => setIsSuccessOpen(false)}
            />
        </div>
        <div className={`${isSuccessCustomMessage ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
            <Success
                message={customMessage}
                closeSuccessModule={() => setisSuccessCustomMessage(false)}
            />
        </div>
        <div className='w-full flex flex-col gap-6 mb-5 overflow-y-scroll scrollbar-none'>
            <div className='flex items-center w-full px-5'>
                <h1 className='heading2'> Create a new Job </h1>
            </div>
            <div>
                <form className='px-5 flex flex-col gap-5'>
                    <div className='relative border-2 w-full py-6 px-5 md:px-10 rounded-lg flex flex-col gap-4'>
                        <div className='text-xs absolute top-[-8px] left-5 bg-white px-2'>Details</div>
                        <div className='flex flex-col sm:flex-row sm:sjustify-center sm:items-center gap-4'>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Job Title*
                                </label>
                                <TextInput
                                    type="text"
                                    placeholderText="Job Title"
                                    inputName="jobTitle"
                                    inputValue={jobTitle}
                                    handleChange={(e) => setJobTitle(e.target.value)}
                                    error={jobTitleError && 'error'}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Department*
                                </label>
                                <TextInput
                                    type="select"
                                    placeholderText="Job Department"
                                    inputName="department"
                                    inputValue={department}
                                    handleChange={(e) => setDepartment(e.target.value)}
                                    selectOptions={["Sales","Human Resourses","Marketing","App Developement","Web Developement", "Software Development"]}
                                    error={departmentError && 'error'}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Number of Positions
                                </label>
                                <TextInput
                                    type="number"
                                    placeholderText="Number of positions open"
                                    inputName="positions"
                                    inputValue={positions}
                                    handleChange={(e) => setPositions(e.target.value)}
                                    selectOptions={["Sales","Human Resourses","Marketing","Android Developer","ios Developer"]}
                                />
                            </div>
                        </div>
                        <div className='flex items-center gap-4 h-[3rem]'>
                            <label className="font-medium">
                               Tags
                            </label>
                            <div className='flex items-center gap-2'>
                                {tags.map((tag, index) => (
                                    <TextInput
                                        key={index}
                                        type="text"
                                        inputValue={tag}
                                        handleChange={(e) => {
                                            const newTags = [...tags];
                                            newTags[index] = e.target.value;
                                            setTags(newTags);
                                        }}
                                    />
                                ))}
                                <p className={`cursor-pointer ${tags.includes('') && 'hidden'}`} onClick={handleAddTag}>
                                    ➕
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row sm:justify-center sm:items-center gap-4'>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Employment Type*
                                </label>
                                <TextInput
                                    type="select"
                                    placeholderText="Select Employment Type"
                                    inputName="employmentType"
                                    inputValue={employmentType}
                                    handleChange={(e) => setEmploymentType(e.target.value)}
                                    selectOptions={["Full Time","Part Time","Temperory","contract","Internship"]}
                                    error={employmentTypeError && 'error'}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Category
                                </label>
                                <TextInput
                                    type="select"
                                    placeholderText="Select Category"
                                    inputName="category"
                                    inputValue={category}
                                    handleChange={(e) => setCategory(e.target.value)}
                                    // selectOptions={[]}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Required Education*
                                </label>
                                <TextInput
                                    type="select"
                                    placeholderText="Select Education Required"
                                    inputName="edcationReq"
                                    inputValue={edcationReq}
                                    handleChange={(e) => setEdcationReq(e.target.value)}
                                    selectOptions={["High School","Certification","Vocational","Graduation","Post Graduation"]}
                                    error={edcationReqError && 'error'}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row md:justify-center sm:items-center gap-4'>
                            <div className="flex flex-col w-full md:w-1/3">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Required Experience*
                                </label>
                                <TextInput
                                    type="select"
                                    placeholderText="Select Experience Required"
                                    inputName="experienceReq"
                                    inputValue={experienceReq}
                                    handleChange={(e) => setExperienceReq(e.target.value)}
                                    selectOptions={["Student(High School)","Student(College)","Entry Level","Mid Level","Experienced"]}
                                    error={experienceReqError && 'error'}
                                />
                            </div>
                            <div className="flex flex-col w-full md:w-2/3">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Hours per week
                                </label>
                                <div className='flex items-center gap-2 text-sm'>
                                    <TextInput
                                        type="number"
                                        placeholderText="Minimum hours"
                                        inputName="minHours/week"
                                        inputValue={minHours}
                                        handleChange={(e) => setMinHours(e.target.value)}
                                    />
                                    <p className='mx-2'>to</p>
                                    <TextInput
                                        type="number"
                                        placeholderText="Maximum hours"
                                        inputName="maxHours/week"
                                        inputValue={maxHours}
                                        handleChange={(e) => setMaxHours(e.target.value)}
                                    />
                                    hours
                                </div>                                
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row lg:justify-center lg:items-center gap-4'> 
                            <div className="flex flex-col w-full lg:w-1/2">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                    Salary
                                </label>
                                <div className='flex items-center gap-2 text-sm'>
                                    <TextInput
                                        type="number"
                                        placeholderText="Minimum Salary"
                                        inputName="minSalary"
                                        inputValue={minSalary}
                                        handleChange={(e) => setMinSalary(e.target.value)}
                                    />
                                    <p className='mx-2'>to</p>
                                    <TextInput
                                        type="number"
                                        placeholderText="Maximum Salary"
                                        inputName="maxSalary"
                                        inputValue={maxSalary}
                                        handleChange={(e) => setMaxSalary(e.target.value)}
                                    />
                                </div>                                
                            </div>
                            <div className='flex flex-col sm:flex-row w-full lg :w-1/2 gap-4'>
                                <div className="flex flex-col w-full md:w-1/2">
                                    <label className="font-medium text-xs leading-[12px] mb-2">
                                        Period
                                    </label>
                                    <TextInput
                                        type="select"
                                        placeholderText="Select Salary Period"
                                        inputName="salaryPeriod"
                                        inputValue={salaryPeriod}
                                        handleChange={(e) => setSalaryPeriod(e.target.value)}
                                        selectOptions={["weekly","monthly","yearly"]}
                                    />
                                </div>
                                <div className="flex flex-col w-full md:w-1/2">
                                    <label className="font-medium text-xs leading-[12px] mb-2">
                                        Currency
                                    </label>
                                    <TextInput
                                        type="select"
                                        placeholderText="Select Currency"
                                        inputName="currency"
                                        inputValue={currency}
                                        handleChange={(e) => setCurrencye(e.target.value)}
                                        selectOptions={["Indian Rupee", "US Dollar","Euro"]}
                                    />
                                </div>
                            </div>                            
                        </div>                        
                        
                    </div>
                    <div className='relative border-2 w-full py-6 px-5 md:px-10 rounded-lg flex flex-col gap-4'>
                        <div className='text-xs absolute top-[-8px] left-5 bg-white px-2'>Description</div>
                        <div className="flex flex-col w-full">
                            <label className="font-medium text-xs leading-[12px] mb-2">
                                Job Description*
                            </label>
                            <TextInput
                                type="textbox"
                                placeholderText="Job Description"
                                inputName="description"
                                inputValue={description}
                                handleChange={(e) => setDescription(e.target.value)}
                                error={descriptionError && 'error'}
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label className="font-medium text-xs leading-[12px] mb-2">
                                Job Requirment
                            </label>
                            <TextInput
                                type="textbox"
                                placeholderText="Job Requirment"
                                inputName="requirment"
                                inputValue={requirment}
                                handleChange={(e) => setRequirment(e.target.value)}
                                error={requirmentError && 'error'}
                            />
                        </div>
                    </div>
                    <div className='relative border-2 w-full py-6 px-5 md:px-10 rounded-lg flex flex-col gap-4'>
                        <div className='text-xs absolute top-[-8px] left-5 bg-white px-2'>Loaction</div>
                        <div className='flex flex-col sm:flex-row sm:justify-center sm:items-center gap-4'>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                   Country
                                </label>
                                <TextInput
                                    type="select"
                                    placeholderText="Select Country"
                                    inputName="country"
                                    inputValue={country}
                                    handleChange={(e) => {
                                        setCountry(e.target.value)
                                    }}
                                    selectOptions={countries}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                   State / Region
                                </label>
                                <TextInput
                                    type="text"
                                    placeholderText="Select State / Region"
                                    inputName="state"
                                    inputValue={state}
                                    handleChange={(e) => setState(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                   City
                                </label>
                                <TextInput
                                    type="text"
                                    placeholderText="City"
                                    inputName="city"
                                    inputValue={city}
                                    handleChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row sm:justify-center sm:items-center gap-4'>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                   Street
                                </label>
                                <TextInput
                                    type="text"
                                    placeholderText="Street"
                                    inputName="street"
                                    inputValue={street}
                                    handleChange={(e) => setStreet(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                   Pin code
                                </label>
                                <TextInput
                                    type="text"
                                    placeholderText="Pin Code"
                                    inputName="pin"
                                    inputValue={pin}
                                    handleChange={(e) => setPin(e.target.value)}
                                />
                            </div>
                            <div className="w-full flex flex-col justify-center items-centers">
                                <label className="font-medium text-xs leading-[12px] mb-2">
                                   Remote Job
                                </label>
                                <div className='flex items-center gap-4'>
                                    <span>No</span>
                                    <div 
                                        className={`cursor-pointer w-16 h-8 flex items-center rounded-full px-1 transition ${remoteJob ? 'bg-blue-700' : 'bg-gray-300'}`}
                                        onClick={() => setRemoteJob(!remoteJob)}
                                    >
                                        <div className={`bg-white w-7 h-7 rounded-full shadow-lg transition transform ${remoteJob ? 'translate-x-7' : ''} `}></div>
                                    </div>
                                    <span>Yes</span>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className='relative border-2 w-full py-6 px-5 md:px-10 rounded-lg flex flex-col gap-4'>
                        <div className='text-xs absolute top-[-8px] left-5 bg-white px-2'>Share Post on</div>
                        <div className='flex gap-4'>                           
                            <div>
                                <LinkedIn
                                    clientId={LINKEDIN_CLIENT_ID}
                                    scope={SCOPE}
                                    redirectUri={REDIRECT_URI}
                                    onFailure={() => {

                                    }}
                                    onSuccess={(code) => {
                                        getLinkedInUserInfoFromAccessCode(code.code)
                                    }}
                                >   
                                    <div  className="flex items-center gap-5">
                                        <img src='img/linkedin.png' alt="Log in with Linked In" width="120"/>
                                    </div>
                                    
                                </LinkedIn>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>                        
                        {loading ? (
                            <p className='text-[#888] font-semibold'>Loading ... </p>
                        ): (
                            <Button
                                handleClick={handleSubmit}
                                text="Proceed"
                                width="12rem"
                            />
                        )}
                        {errorMessage && (
                            <div className='flex flex-col text-sm text-red-500'>
                                <p>{errorMessage}</p>                                
                            </div>                            
                        )}
                    </div>                    
                </form>
            </div>
        </div>
    </div>
  )
}

export default NewJob