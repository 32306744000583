import React,{useEffect, useRef, useState} from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import "@tensorflow/tfjs-core";
import '@tensorflow/tfjs-backend-cpu';
// import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import * as cocossd from "@tensorflow-models/coco-ssd";
import Webcam from "react-webcam";

// import SoundMeter from '../setup/SoundMeter';

const Proctoring = ({ setFaceCount, setVoice, listning, setProctorLoading, setcellPhoneDetection,setisCameraEnable, 
    // webcamRef, videoRef, webcam, recording
}) => {  

    // model loading
    const [speechLoading, setSpeechLoading] = useState()
    const [faceLoading, setFaceLoading] = useState()
    const [cocoLoading, setCocoloading] = useState()
    useEffect(() => {
        !speechLoading && !faceLoading && !cocoLoading && setProctorLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[faceLoading,cocoLoading])


    //voice detection
    const {transcript, resetTranscript} = useSpeechRecognition();
    useEffect(() => {
        setSpeechLoading(true)
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
          alert("Sorry, your browser doesn't support speech recognition. Try using Chrome instead.");
          return;
        }
        SpeechRecognition.startListening({ continuous: true });
        setSpeechLoading(false)
    },[])

    useEffect(() => {
        if (listning){
            // console.log('listening Start')
            SpeechRecognition.startListening({ continuous: true });
        }else{
            // console.log('listening stopped')
            SpeechRecognition.stopListening()
            resetTranscript()
        }
    },[listning, resetTranscript])

    useEffect(() => {
        setVoice(transcript)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[transcript])


    // running models
    const webcamRef = useRef();

    useEffect(() => {
        runFaceDetect();
        runCoco();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    // face model
    const runFaceDetect = async () => {
        setFaceLoading(true)
        const faceModel = await faceLandmarksDetection.load(
          faceLandmarksDetection.SupportedPackages.mediapipeFacemesh
        );
        setFaceLoading(false)
        detectFace(faceModel);
    };

    // cocossd model
    const runCoco = async () => {
        setCocoloading(true)
        const mobileModel = await cocossd.load();        
        setInterval(() => {
            detectMobile(mobileModel);
        }, 500);
        setCocoloading(false)
        console.log('coco model loaded')
    };


    //Multiple person detection
    const detectFace = async (faceModel) => {
        const webcamCurrent = webcamRef?.current;
        if (webcamCurrent?.video?.readyState === 4) {
            const video = webcamCurrent?.video;
            const predictions = await faceModel?.estimateFaces({ input: video });
            const faces = predictions.filter((prediction) => prediction.faceInViewConfidence > 0.80)
            setFaceCount(faces?.length)
            detectFace(faceModel);
        }
    };


    //Phone Detection
    const detectMobile = async (mobileModel) => {
        const webcamCurrent = webcamRef?.current;
        if ( webcamCurrent?.video?.readyState === 4 ) {           
            const video = webcamCurrent?.video;           
            const predictions = await mobileModel?.detect(video);
            if(predictions.length > 0){
                setisCameraEnable(true)
                predictions?.forEach(prediction => {
                    const text = prediction?.class;
                    console.log(text);
                    if(text === "cell phone" || text === "remote"){
                        setcellPhoneDetection(1)
                    }
                });
            } else{
                // console.log('camera disable')
                setisCameraEnable(false)
            }            
        }
    };

    return (
    <div className='bg-[#aaa]/30 backdrop-blur-sm shadow-lg p-2 rounded-2xl flex flex-col justify-center items-center gap-1'>
        <Webcam audio={false} className='rounded-2xl' ref={webcamRef} width={250}/>
        {/* <p className='text-sm font-medium'>WebCam Feed</p> */}
        <div className='w-full h-full'>
            {/* <SoundMeter/> */}
        </div>
        
    </div>
    )
}

export default Proctoring

