import React,{useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';

import './App.css';
import './assets/sass/style.scss';
import { Admin,Register,Verification,ForgotPass,ResetPass} from './admin';
import {
 	Login,
	AdminLogin,
	CountDown,
	Setup,
	ExamInstructions,
	ExamPage,
	CandidateApplyForm,
	CandidateRegistration,
	Test
} from './pages';
import {LogoLoading} from './components'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

const App = () => {
	const [adminLoginLoading, setAdminLoginLoading] = useState(true)
	const [isAdminLogedIn, setIsAdminLogedIn] = useState(false)
	const [isCandidateLogedIn, setIsCandidateLogedIn] = useState(false)
	const [examExpiry, setExamExpiry] = useState()

	useEffect(() => {
		const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
		if (adminLogin) {
			setIsAdminLogedIn(adminLogin.isAdminLogedIn);
		}
		setAdminLoginLoading(false)
		
		const candidate = JSON.parse(localStorage.getItem('candidateDetails'))
		if(candidate){
			setIsCandidateLogedIn(candidate.isCandidateLogin);
			setExamExpiry(candidate.expireDateTime)
		}
  },[]);

	return (
		<div className='flex flex-col h-[100vh]'>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login/>} />
					<Route path="/candidateRegistration" element={<CandidateRegistration/>} />
					<Route path="/auth/linkedin/callback" element={<LinkedInPopUp/>} />

					<Route path="/start" element={isCandidateLogedIn ? <CountDown/> : <Login/>} />
					<Route path="/examSetup" element={isCandidateLogedIn ? (examExpiry > new Date().getTime() ? <Setup /> : <CountDown/>) : <Login/>} />
					<Route path="/examInstructions" element={isCandidateLogedIn ? (examExpiry > new Date().getTime() ? <ExamInstructions /> : <CountDown/>) : <Login/>} />
					<Route path="/examPage" element={isCandidateLogedIn ? (examExpiry > new Date().getTime() ? <ExamPage /> : <CountDown/>) : <Login/>} />
					
					<Route path="/applyForm" element={<CandidateApplyForm/>} />
					<Route path="/test" element={<Test/>} />
					<Route path="/register" element={<Register/>} />

					<Route path="/forgotPass" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="dashboard" /> : <ForgotPass/>} />
					<Route path="/admin" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="dashboard" /> : <AdminLogin/>} />
					<Route path="/profile" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="admin profile" /> : <AdminLogin/>} />
					<Route path="/settings" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="settings" /> : <AdminLogin/>} />
					<Route path="/applications" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="applications" tab= 'applications' /> : <AdminLogin/>} />
					<Route path="/invitations" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="applications" tab = 'invitations' /> : <AdminLogin/>} />
					<Route path="/results" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="applications" tab = 'results' /> : <AdminLogin/>} />					
					<Route path="/candidateProfile" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="candidate profile" /> : <AdminLogin/>} />
					<Route path="/jobs" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="jobs" /> : <AdminLogin/>} />
					<Route path="/newJob" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="new job" /> : <AdminLogin/>} />
					<Route path="/quesBank" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="question bank" /> : <AdminLogin/>} />
					<Route path="/quesUpload" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="question upload" /> : <AdminLogin/>} />
					<Route path="/assessmentType" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="assessment type" /> : <AdminLogin/>} />
					<Route path="/archiveCandidates" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab='candidates' /> : <AdminLogin/>} />
					<Route path="/archiveQuestions" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab= 'questions'/> : <AdminLogin/>} />
					<Route path="/archiveAssessments" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab= 'assessments' /> : <AdminLogin/>} />
					<Route path="/notifications" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="notifications" /> : <AdminLogin/>} />
					<Route path="/verification" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="dashboard" /> : <Verification/>} />
					<Route path="/resetpass" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="dashboard" /> : <ResetPass/>} />					
				</Routes>
			</BrowserRouter>
		</div>
	);
};

export default App;
