import React from 'react'

const InstructionsTopPanel = ({exam}) => {
  return (
    <div className='w-[95%] mx-auto mt-[100px] mb-6 p-10 card'>
      <h1 className='heading2 mb-2'>
        technical skills assesment test
      </h1>

      <span className='font-medium'>
        Assessment Type :
      </span>

      <span className='text-[#919397] capitalize ml-1'>
        {exam}
      </span> 
    </div>
  )
}

export default InstructionsTopPanel
