import React from 'react'
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { motion } from 'framer-motion';
// import { marked } from 'marked';

const JobView = ({job,jobOpen,closeJobView}) => {
    // function markdown(text) {
    //     return { __html: marked.parse(String(text)) };
    // }
  return (
    <motion.div
        className='card w-full h-full lg:w-2/3 py-2 px-5 overflow-hidden '
        transition={{duration:0.4}}
        variants={{
            initial: { scale: 0},
            final:{scale: 1},
        }}
        animate= {jobOpen ? 'final' : 'initial'}
    >   
        <div onClick={closeJobView} className='fixed top-5 right-5 p-2 bg-slate-100 rounded-full cursor-pointer z-10'>
            ✖️
        </div>
        <div className='relative w-full h-full overflow-y-auto scrollbar-none'>        
            <div className='p-2 md:px-10'>
                <h1 className='heading2 py-2'>Job Post Preview</h1>   
                <div className='border-t-[2.5px] w-full'/>
                <div className='my-4 flex flex-col sm:flex-row gap-2'>
                    <div className='w-full'>
                        <p className='font-medium'>Job Title</p>
                        <p className='text-sm text-[#888]'>{job?.title}</p>
                    </div>
                    <div className='w-full'>
                        <p className='font-medium'>Department</p>
                        <p className='text-sm text-[#888]'>{job?.department}</p>
                    </div>
                    <div className='w-full'>
                        <p className='font-medium'>No. of Positions</p>
                        <p className='text-sm text-[#888]'>{job?.positions < 10 ? 0 : ""}{job?.positions}</p>
                    </div>
                </div>
                <div className='border-t-[2.5px] w-full'/>
                <div className='flex flex-col gap-3 my-4'>
                    <div className='flex w-full flex-col sm:flex-row gap-2'>
                        <div className='w-full'>
                            <p className='font-medium'>Employment Type</p>
                            <p className='text-sm text-[#888]'>{job?.employmentType}</p>
                        </div>
                        <div className='w-full'>
                            <p className='font-medium'>Required Education</p>
                            <p className='text-sm text-[#888]'>{job?.educationReq}</p>
                        </div>
                        <div className='w-full'>
                            <p className='font-medium'>Required Experience</p>
                            <p className='text-sm text-[#888]'>{job?.experianceReq}</p>
                        </div>
                    </div>
                    <div className='flex w-full flex-col sm:flex-row gap-2'>
                        <div className='w-full sm:w-1/3'>
                            <p className='font-medium'>Job Location</p>
                            <p className='text-sm text-[#888]'>
                                {job?.remote ? "Remote" : `${job?.city}, ${job?.country}`}
                            </p>
                        </div>
                        {job?.salary !== " to " && <div className='w-full sm:w-2/3'>
                            <p className='font-medium capitalize'>{job?.salaryPeriod} Salary</p>
                            <p className='text-sm text-[#888]'>
                                {job?.salary} ({job?.salaryCurrency})
                            </p>
                        </div>}
                        {/* <div className='w-full'></div> */}
                    </div>
                </div>            
                <div className='border-t-[2.5px] w-full'/>
                <div className='flex flex-col gap-3 my-4'>
                    <p className='font-medium'>Job Description</p>
                    {/* <p className='descriptionView' dangerouslySetInnerHTML={markdown(job?.description)}></p> */}
                    <p>{job?.description}</p>
                </div>
                <div className='flex flex-col gap-3 my-4'>
                    <p className='font-medium'>Job Requirements</p>
                    <p>
                        {job?.requirments}
                    </p>
                </div>
                <div className='border-t-[2.5px] w-full'/>
            </div>
            <div className='p-4 md:px-10 flex items-center gap-4'>
                <span className='text-[#888] font-medium'>Share : </span>
                <div className='flex gap-2'>
                    <LinkedinShareButton
                        url={`https://dev.to/designly/how-to-create-an-attractive-file-upload-widget-with-reactnextjs-and-tailwind-css-5c7o`}
                        title="title"
                        summary='summary'
                        source='source'
                    >
                        <img
                            className='w-10 cursor-pointer'
                            src="/img/linkedin-logo.png"
                            alt = "linkedin share"
                        />
                    </LinkedinShareButton>
                    <img
                        className='w-10 cursor-pointer'
                        src="/img/twitter-logo.png"
                        alt = "linkedin share"
                    />
                    </div>             
            </div>
        </div>
    </motion.div>
  )
}

export default JobView