import React, { useEffect } from 'react'   
import { Link } from 'react-router-dom';

import {Button} from '../../components'

const SetupRight = ({step,setStep,hasCameraPermission,sethasCameraPermission,hasMicPermission,sethasMicPermission,isMicWorking}) => {

    useEffect(() => {
        if(step === 'camera'){
            navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
                sethasCameraPermission(true)
            }).catch((err) =>{
                console.log(err);
                sethasCameraPermission(false)            
                // askForCameraPermission()
            });
        } else if(step === 'mic'){
            navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                sethasMicPermission(true)
            })
            .catch(err => console.log(err));
                sethasMicPermission(false)
                // askForCameraPermission();
        }
      },[step])

      useEffect(() =>{
        var stream;
        navigator.mediaDevices.getUserMedia({ audio:true })
        .then(s => (stream = s), e => console.log(e.message))
        .then(() => navigator.mediaDevices.enumerateDevices())
        // .then(devices => {
        // console.log("-----------------------------------")
        // console.log(JSON.stringify(devices))
        // console.log("-----------------------------------")

        // console.log(devices.length + " devices.");
        // devices.forEach(d => console.log(d.kind + ": " + d.label));
        // })
        .catch(e => console.log(e));
      },[])
      
  return (
    <>
        {step === "camera" &&
            <div className='flex flex-col items-start justify-between h-full w-[80%] mx-auto my-2'>            
                <div className="flex flex-col">
                    <h1 className="heading1 mb-8">
                        Webcam Mandatory while giving Assessment Test
                    </h1>                   
                        <ul className='gap-4 text-[#919397] listDisc'>
                            <li>
                                Always keep the Camera ON and working during the assessment.
                            </li>
                            <li>
                                Please do not leave your Laptop/PC while the test is going on.
                            </li>
                            <li>
                                Please sit in front of proper lighting so that the camera can detect your face and background.
                            </li>
                            <li>
                                The test will get terminated if the webcam is turned off at any time while giving the test.
                            </li>
                        </ul>
                </div>
                {hasCameraPermission && ( 
                    <Button
                        version="primary"
                        text="Proceed"
                        handleClick={() => setStep("mic")}
                        width="fit-content"
                    />
                )}               
            </div>
        }
        {/* {step === "internet" &&
             <div className='flex flex-col items-start justify-between h-full w-[80%] mx-auto'>
                 <div className="flex flex-col">
                    <h1 className="heading1 mb-8">
                        Stable Internet Connection
                    </h1>
                    <ul className='gap-4 text-[#919397] listDisc'>
                        <li>
                            Use a stable internet connection while giving the test.
                        </li>
                        <li>
                            It is preferred to use a wifi connection rather than using mobile hotspot.
                        </li>
                    </ul>
                </div>

                <Button
                    version="primary"
                    text="Proceed"
                    handleClick={() => setStep("mic")}
                    width="fit-content"
                />
            </div>
        } */}
        {step === "mic" &&
            <div className='flex flex-col items-start justify-between h-full w-[80%] mx-auto'>
                 <div className="flex flex-col">
                    <h1 className="heading1 mb-10">
                        Microphone Mandatory while giving assessment test
                    </h1>
                    <ul className='gap-4 text-[#919397] listDisc'>
                        <li>
                            Always keep your microphone ON.
                        </li>
                        <li>
                            Please do not mute or disconnect the microphone during the assessment
                        </li>
                        <li>
                            Please make sure that no background noise is detected
                        </li>
                    </ul>
                </div>
                {isMicWorking && hasMicPermission &&
                    <Button
                        version="primary"
                        text="Proceed"
                        handleClick={() => setStep("instruction")}
                        width="fit-content"
                    />
                }

            </div>
        }
        {step === "instruction" &&
            <div className='flex flex-col items-start justify-between h-full w-[80%] mx-auto'>
                 <div className="flex flex-col">
                    <h1 className="heading1 mb-10">
                        Don’t Refresh or Exit Full Screen
                    </h1>
                    <ul className='gap-4 text-[#919397] listDisc'>
                        <li>
                            Do not switch/refresh the tab or try to open any other window. 
                        </li>
                        <li>
                        Do not open the development or any other tool for help.
                        </li>
                        <li>
                            Do not move the cursor out of the assessment tab.
                        </li>
                        <li>
                            Do not Press 'Back Button' while taking the assessment.
                        </li>
                        <li>
                            Do not exit 'Full-Screen' mode while taking the assessment.
                        </li>
                    </ul>
                </div>
                <Link to= '/examInstructions'>
                    <Button
                        version="primary"
                        text="Proceed"
                        width="fit-content"
                    />
                </Link>
               
            </div>
        }
    </>
  )
}

export default SetupRight
