import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const NotificationPanel = ({isNotificationOpen,setIsNotificationOpen,displayNotifications,markRead,clearNotifications,notificationsLoading}) => {
    const [readHover, setReadHover] = useState(false)
    return (
        <motion.div
            className='absolute w-[90%] sm:w-1/2 md:w-[40%] lg:w-[25%] card flex-col'
            variants={{
              initial: { top: 0, display: 'none'},
              animate: { top : 70, display: 'flex'}
            }}
            initial="initial"
            transition={{ duration: 0.1 }}
            animate={isNotificationOpen ? "animate" : "initial"}
        >
            <div className='py-4 flex flex-col gap-2 overflow-hidden relative'>
                <div className=' absolute top-6 right-8 flex items-center text-xs gap-2'>
                    <p className={readHover ? 'flex' : 'hidden'}>Mark all as read</p>
                    <div
                        className='cursor-pointer'
                        onMouseEnter={() => setReadHover(true)}
                        onMouseLeave={() => setReadHover(false)}
                    >
                        <svg onClick={clearNotifications} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                        </svg>
                    </div>
                </div>
                
                
                <h1 className='font-semibold text px-8 my-2'>Notifications</h1>       
                <div className='flex flex-col'>
                    {notificationsLoading ? (
                        <div className='relative flex flex-col gap-2 px-6 py-3'>
                            <div className='bg-[#ccc]/50 h-[2.5rem] w-full rounded-2xl'/>
                            <div className='bg-[#ddd]/50 h-[2.5rem] w-full rounded-2xl'/>
                            <div className='bg-[#eee]/50 h-[2.5rem] w-full rounded-2xl'/>
                        </div>
                        
                    ) : displayNotifications?.filter(notification => notification?.read !== 1).length === 0 && (
                        <div className='px-8 py-4 flex justify-center text-[#aaa] text-sm font-medium'> No New Notifications</div>
                    )}
                    <div className='max-h-[25rem] overflow-y-auto scrollable mr-2'>
                        {displayNotifications
                            ?.filter(notification => notification?.read !== 1)
                            ?.sort((a,b) => new Date(`${b?.date} ${b?.time}`) - new Date(`${a?.date} ${a?.time}`))
                            ?.map((notification) => (
                                <motion.div
                                    key={notification?.id}
                                    className='relative flex gap-4 hover:bg-[#eee]/40 px-6 py-3'
                                    whileTap={{scale : 0.95}}
                                >
                                    <Link
                                        to = {`/candidateProfile?id=${notification?.uid}`}
                                        className='absolute top-0 left-0 h-full w-full'
                                        onClick={() => {
                                            markRead(notification?.id);
                                        }}
                                    ></Link>                        
                                    <div className='w-[10%]'>
                                        <div className='w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center'></div>
                                    </div>
                                    <div className='w-[72%]'>
                                        <p className='text-xs'> {notification?.notificationBody.split('and')[0]}</p>
                                    </div>
                                    <div className='w-[18%] text-xs text-[#888] flex justify-end'>
                                        {new Date(notification?.date).toDateString() === new Date().toDateString() ? (
                                            new Date(notification?.time)?.toLocaleTimeString("en-in",{hour:'numeric', minute:'numeric'})
                                        ) : (
                                            new Date(notification?.date)?.toLocaleDateString("en-in",{day: 'numeric' ,month : 'short'})
                                        )}
                                    </div>
                                </motion.div>
                            ))
                        }
                    </div>                    
                    <motion.div
                        whileTap={{scale : 1}} 
                        whileHover={{scale : 1.05, color : '#8A24F1'}}
                    >
                        <Link
                            to='/notifications'
                            onClick={() => {
                                setIsNotificationOpen(false)
                                clearNotifications()
                            }}
                            className='flex justify-center items-center pt-2 font-medium text-sm cursor-pointer border-t-[2px] border-[#f5f5f5]'
                        >
                            All Notifications
                        </Link>
                    </motion.div>              
                </div>
            </div>
        </motion.div>
    )
}

export default NotificationPanel
