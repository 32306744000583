import React from 'react'

import {Button, Topbar2} from '../../components'

const SubmitModule = () => {

  return (
	    <div className='w-full h-full'>
            <Topbar2 hideDetails={true}/>
            <div className='w-[95%] h-[85%] mx-auto py-8 flex card my-6 flex-col justify-center items-center'>
                <h1 className='font-semibold text-2xl capitalize'>
                  Thank you for taking the assessment !
                </h1>
                <p className='text-lg text-[#919397] capitalize mt-2.5 mb-12'>
                  Expect the feedback within a week.
                </p>
                <Button
                  text="Exit Window"
                  handleClick={() => window.location.href = "/"}
                />
            </div>
		</div>
  )
}

export default SubmitModule