import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

import {Logo,Button, TextInput} from '../../components'

const AdminLogin = () => {
    document.title = "Talentigo | Login"    
    const [userEmail, setUserEmail] = useState("")
    const [pass, setPass] = useState("")
    const [mailError, setMailError] = useState("");
    const [passError, setPassError] = useState("");
    const [otherError, setOtherError] = useState("");
    const [loading, setLoading] = useState(false)
    const [passwordType, setPasswordType] = useState('password')
    
    const [loginButton, setLoginButton] = useState()
    useEffect(() => setLoginButton(document.getElementById("loginButton")),[])

    document.addEventListener('keypress',(e)=> {
        if (e.keyCode === 13) {
            loginButton?.click()
          }
    })

    const handleSubmit = async () =>{

        //reset error messages
        setMailError("");
        setPassError("");
        setOtherError("")
        
        let adminData;
        const fetchAdmin = async () => {
            setLoading(true)
            const fetchData = userEmail
            const response = await axios.post(process.env.REACT_APP_ADMIN_LOGIN ,fetchData);
            if(response?.data?.status === 'success'){
                adminData = response?.data
            }else{
                setMailError("Invalid Email")
            }
            setLoading(false)
        }
        if(userEmail && pass){
            await fetchAdmin()
        }
        // Compare admin info
        if (adminData) {            
            if (adminData.admin.password != pass) {
                // Invalid Password
                setPassError("Invalid Password");
            }else if(!adminData.admin.Active) {
                setOtherError("This account has not been activated yet.")
            }else{
                localStorage.setItem('adminLogin', JSON.stringify({
                    isAdminLogedIn : true, 
                    name : adminData?.admin.companyName,
                    email : adminData?.admin.companyEmail,
                    dataSheetId : adminData?.admin.dataSheetId,
                    questionSheetId : adminData?.admin.questionsSheetId,
                    loginTime :  Date.now()
                }));
                window.location.href = '/admin';
            }
        }
        
        if(userEmail && pass){
            await fetchAdmin()
        }

        if (userEmail === ""){
            setMailError("Please fill your email Id")
        }
        if (pass === ""){
        setPassError("Please fill your password")
        }
    }

    return (
        <div className='admin-main'>
            <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
                <div className='h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-4'>
                    <div className='ribbon-box'>Beta Version</div>
                    <Logo/>
                    <form className="flex flex-col gap-4 w-full">  
                        <h1>Login to your account</h1>
                        <div className="flex flex-col mb-[14px]">
                            <label htmlFor="email" className="font-medium text-sm leading-[12px] mb-2">
                                Email id
                            </label>
                            <TextInput
                                type="email"
                                placeholderText="Enter your Email id"
                                inputName="email"
                                inputValue={userEmail}
                                handleChange={(e) => setUserEmail(e.target.value)}
                            />
                            { mailError &&
                                <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                                    {mailError}
                                </div>
                            }

                        </div>
                        <div className="flex flex-col forgot-field password-field">
                            <label htmlFor="password" className="font-medium text-sm leading-[12px] mb-2">
                                Password
                            </label>
                            
                            {/* <input
                                className='h-[40px] w-full bg-[#F5F5F5] rounded-lg px-3 text-sm border-0 focus:ring-0'
                                ref={passwordRef}
                                type="password"
                                placeholder="Enter your Password"
                                name="password"
                                required
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}                                    
                            /> */}
                            <TextInput
                                type={passwordType}
                                placeholderText="Enter your Password"
                                inputName="password"
                                isRequired={true}
                                inputValue={pass}
                                handleChange={(e) => setPass(e.target.value)}                                    
                            />
                            
                            {passError &&
                                <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                                    {passError}
                                </div>
                            }
                            <Link
                                to='/forgotPass'
                                className='forgot-txt text-sm w-full font-medium text-right hover:underline mt-1'
                            >
                                Forgot Password
                            </Link>
                            <img
                                src={`./img/eye-${passwordType === 'text' ?  'open' : 'close' }.png`}
                                alt="eye-icon"
                                onClick={() => 
                                    passwordType === 'password'
                                    ? setPasswordType('text')
                                    : setPasswordType('password')
                                }
                            />
                        </div>
                            
                        <div className='flex flex-col items-center justify-center gap-2 mt-4'>

                            {
                                <div className="font-medium text-xs leading-3 h-3 text-[#f00]">
                                    {otherError}
                                </div>
                            }

                            {loading ? (
                                <div className='text-[#ccc] font-semibold'>
                                    Loading ...
                                </div>
                                
                            ) : (
                                <Button
                                    version="primary"
                                    text="Login"
                                    type="button"
                                    handleClick={handleSubmit}
                                    width="15rem"
                                    inputId= 'loginButton'
                                />                        
                            )}
                            
                        </div>
                    </form>
                    <div className='mt-4 flex flex-col justify-center items-center gap-4 w-full'>
                        <p className='text-sm'>OR</p>
                        <div className='flex justify-center gap-1 w-full'>
                            Don’t have any account?  
                            <Link
                                to='/register'
                                className='font-medium hover:underline'
                            >
                                Register
                            </Link>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default AdminLogin
