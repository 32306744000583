import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

import {Logo,Button, TextInput} from '../components'

const Verification = () => {
    const [ error, setError] = useState()
    const [ npass, setNpass] = useState()
    const [ cpass, setCpass] = useState()
    const [loading, setLoading] = useState()
    const [successModule, setSuccessModule] = useState()

    document.addEventListener('keypress',(e)=> {
        if (e.keyCode === 13) {
            handleSubmit()
          }
    })
    
    useEffect(() => {
        setSuccessModule(document.getElementById('sucessModule'))
    },[])

    const params = new URLSearchParams(window.location.search);
    const id = params.get('key')

    const handleSubmit = async () => {
        setError("")
        if(!cpass || !npass ){
            setError("Please enter both the password fields")
        }else if(cpass === npass){
            setLoading(true)
            const data = `${id}|${npass}`
            const response = await axios.post(process.env.REACT_APP_RESET_PASSWORD,data)
            if(response.data.status === 'success'){
                successModule?.classList.remove('hidden')
                successModule?.classList.add('flex')
            }else{
                setError("something went wrong")
            }
            
            // console.log(response.data)
        }else{
            setError("Passwords not matched")
        }  
        setLoading(false)
    }

    const closeModule = () => {
        successModule?.classList.remove('flex')
        successModule?.classList.add('hidden')
    }

    
  return (
    <div className="flex justify-center items-center h-full sm:py-5 w-full gradient2">
            <div className='relative bg-white h-full sm:rounded-[25px] w-full sm:w-1/2 lg:w-2/5 flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-4'>
                <div id='sucessModule' className='hidden backdrop-blur-sm h-full w-full absolute items-center justify-center'>
                    <div className='card h-2/3 w-[90%] flex flex-col items-center justify-center gap-8 p-5'>
                        {
                                <>
                                    <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                                        <p className='text-white text-4xl'>✓</p> 
                                    </div>
                                    <h1 className='text-xl font-medium'>Password Changed !</h1>
                                    <p className='text-sm px-12 text-center'>
                                        You have successfully changed your password.
                                    </p>
                                    <Link to= '/admin'>
                                        <Button
                                            text='Login'
                                            width='10rem'
                                            handleClick={closeModule}
                                        />
                                    </Link>
                                </>
                        }
                        

                    </div>
                </div>
                <Logo/>
                <form className="flex flex-col gap-5 w-full mt-12">  
                    <span className='sm:text-sm font-medium'>
                        Reset your Password
                    </span>
                    
                    <div className="flex flex-col">
                        <label htmlFor="npass" className="font-medium text-xs leading-[12px] mb-2">
                            New Password
                        </label>
                        
                        <TextInput
                            type="password"
                            placeholderText="Enter your Password"
                            inputName="npass"
                            isRequired = {true}
                            inputValue={npass}
                            handleChange={(e) => setNpass(e.target.value)}                                    
                        />
                        
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="cpass" className="font-medium text-xs leading-[12px] mb-2">
                            Confirm Password
                        </label>
                        
                        <TextInput
                            type="password"
                            placeholderText="Enter your Password"
                            inputName="cpass"
                            isRequired = {true}
                            inputValue={cpass}
                            handleChange={(e) => setCpass(e.target.value)}                                    
                        />
                        
                    </div>
                        
                    <div className='flex flex-col items-center justify-center gap-2 mt-4'>

                        {
                            <div className="font-medium text-xs leading-3 h-3 text-[#f00]">
                                {error}
                            </div>
                        }

                        {loading ? (
                            <div className='text-[#ccc] font-semibold'>
                                Loading ...
                            </div>
                            
                        ) : (
                            <Button
                                version="primary"
                                text="Reset"
                                type="button"
                                handleClick={handleSubmit}
                                width="15rem"
                            />
                        )}
                        
                    </div>
                </form>
                
            </div>
        </div>
  )
}

export default Verification
