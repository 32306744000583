import React,{useEffect, useState} from 'react'
import axios from 'axios';

import { TextInput,Button } from '../../components'

const AdminProfile = ({adminName,adminEmail}) => {
    
// const [name, setName] = useState(adminName);
// const [email, setEmail] = useState(adminEmail);

const [cpass, setCpass] = useState()
const [npass, setNpass] = useState()
const [rpass, setRpass] = useState()

const [error, setError] =  useState()
const [success, setSuccess] = useState()
const [loading, setLoading] = useState(false)

const updatePassword = async() => {
    setError(""); setSuccess("");
    if(cpass  && npass && rpass ){
        // console.log(cpass);
        if( npass === rpass){
            setLoading(true)
            const data = `${adminEmail}|${cpass}|${npass}`
            const response =  await axios.post(process.env.REACT_APP_UPDATE_PASSWORD,data);
            if(response.data.status === "success"){
                setSuccess("Your Password has been updated")
            }else if(response.data.status === "passError"){
                setError('Corrent Password is Incorrect')
            }
            setLoading(false)
        }else{ 
            setError('Confirm Password not matched')
        }
    }else{
        setError('Please fill all the password fields.')
    }
}
  return (
    <div className='main-container'>
        <div className='card w-full h-full md:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col md:flex-row sm:px-4 lg-px-10 gap-5 py-4'>
        <div className='w-full md:w-2/5 h-full py-5 px-10 flex flex-col gap-6'>
                <h1 className='heading2'>Profile</h1>
                <div className='h-40 w-40 rounded-xl overflow-hidden mb-6 profile-icons'>
                    <img src='/img/adminProfilebig.png' alt='admin img'/>
                </div>
                <div className='w-full text-sm text-[#666] flex flex-col gap-6'>
                    <div className="flex flex-col gap-2 disabled-input">
                        <label className='font-semibold text-base'>
                            Company Name
                        </label>
                        <TextInput
                            type="text"
                            inputValue={adminName}
                        />
                    </div>
                    <div className="flex flex-col gap-2 disabled-input">
                        <label className='font-semibold text-base'>
                            Company Email
                        </label>
                        <TextInput
                            type="text" 
                            inputValue={adminEmail}
                        />
                    </div>
                </div>
        </div>
        <div className='w-full md:w-3/5 h-full py-5 px-10 flex flex-col gap-6'>
                <div className=' flex flex-col gap-2'>
                    <h1 className='heading2'>Change Password</h1>
                    <div className='w-full lg:w-2/3 text-sm text-[#666] flex flex-col gap-4'>
                        <div>
                            <label className="mb-2">
                                Current password
                            </label>
                            <TextInput
                                type='password'
                                InputName="currentPassword"
                                inputValue={cpass}
                                handleChange={(e) => setCpass(e.target.value)}
                                placeholderText="Your current password"
                            />                    
                        </div>
                        <div>
                            <label className="mb-2">
                                New password
                            </label>
                            <TextInput
                                type='password'
                                InputName="currentPassword"
                                inputValue={npass}
                                handleChange={(e) => setNpass(e.target.value)}
                                placeholderText="Your new password"
                            />                    
                        </div>
                        <div>
                            <label className="mb-2">
                                Confirm password
                            </label>
                            <TextInput
                                type='password'
                                InputName="currentPassword"
                                inputValue={rpass}
                                handleChange={(e) => setRpass(e.target.value)}
                                placeholderText="Re-enter your password"
                            />                    
                        </div>
                    </div>
                    <div className='mt-3'>
                        {error &&(
                            <div className='text-xs mb-4 text-red-600'>
                                {error}
                            </div>
                        )}
                        {success &&(
                            <div className='text-xs mb-4 text-green-600'>
                                {success}
                            </div>
                        )}
                        {loading ? (
                            <div className='text-[#ccc] font-semibold'>
                                Loading ...
                            </div>                        
                            ) : (
                                <Button
                                    text = 'Update Password'
                                    width='12rem'
                                    handleClick={updatePassword}
                                />
                            )
                        }
                    </div>   
                </div>
        </div>
        </div>
    </div>
  )
}

export default AdminProfile
