import React,{useState, useEffect,useRef} from 'react'
import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

const Results = ({
  dataSheetId,
  resultsLoading,
  loading,
  page,
  setPage,
  totalResults,
  results,
  resultFilter,
  screen
}) => {

  const [candidates, setCandidates] = useState(results)

  useEffect(()=> {
    results && setCandidates(results)
},[results])

  const fetchNextResults = async () => {
    const resultData = JSON.stringify([dataSheetId, 'schedules',page,50,resultFilter])
    const resultResponse =  await axios.post(process.env.REACT_APP_FETCH_API,resultData);
    setCandidates((prev) => [...prev, ...resultResponse.data.records])
    setPage(prev => prev + 1)
  }

  const topButtonRef = useRef()
  const scrollableComponent = useRef()
  useEffect(() => {
    const handleScroll = () => 
      scrollableComponent.current.scrollTop > 200 
        ? topButtonRef.current.classList.remove('hidden')
        :  topButtonRef.current.classList.add('hidden')
    scrollableComponent.current.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col">
          <div className='flex py-5 md:px-8 bg-[#E9EAEB]/60 backdrop-blur-lg font-medium text-sm capitalize text-black md:rounded-t-xl'>
            <div className="w-[5%] hidden lg:flex ml-2">S.No.</div>
            <div className="w-[30%] md:w-[28%] lg:w-[15%]">Name</div>
            <div className="w-[25%] hidden lg:flex">Email Id</div>
            <div className="w-[25%] md:w-[15%] lg:w-[15%]">Assessment</div>
            <div className="w-[25%] md:w-[22%] lg:w-[12%]">Exam Date</div>
            <div className="md:w-[15%] lg:w-[8%] hidden md:flex">Score</div>
            <div className='w-[20%] lg:w-[10%]'>Status</div>
            <div className="w-[6%] hidden lg:flex">Action</div>
          </div>
          <div className='h-full overflow-y-scroll scrollable' ref={scrollableComponent}>
            <InfiniteScroll
              dataLength={candidates.length}
              next={fetchNextResults}
              hasMore={candidates?.length !== totalResults}
              scrollableTarget="scrollableComponent"
              // endMessage={<p className='w-full flex justify-center p-5 font-semibold text-[#888]'>Thats all for this List !</p>}
              loader={
                <>
                  <div className='bg-[#aaa]/10 w-full h-[2rem] mt-4 rounded-2xl'/>
                  <div className='bg-[#bbb]/10 w-full h-[2rem] mt-4 rounded-2xl font-medium text-[#888] flex items-center justify-center'>
                    Loading...
                  </div>                            
                  <div className='bg-[#ccc]/10 w-full h-[2rem] mt-4 rounded-2xl'/>
                </>
              }
            >
              {loading ? ("") : !resultsLoading && candidates?.length === 0 ? (
                <div className='mt-10 w-full h-full flex justify-center items-center text-center text-xl font-bold text-[#888]'>
                  No results yet. <br/>
                  The results will appear upon completion of the assessment process.
                </div>
              ) : (
                candidates
                ?.map((result,index) => (
                  <div
                    key = {result?.UID}
                    className='flex items-center py-5 lg:py-3 text-xs pl-4 md:pl-8 md:pr-7 bg-[#fff] even:bg-[#E9EAEB]/40 backdrop-blur-lg sticky top-0 font-medium text-[#414143]'
                    // whileTap={{scale : screen ==='small' ? 0.97 : 0.99 }}
                    // transition={{duration : 0.2}}
                  >
                    <Link
                      to={`/candidateProfile?id=${result?.UID}`}
                      className='absolute top-0 left-0 w-full h-full'
                    ></Link>
                    <span className="w-[5%] hidden lg:flex ml-2">{index+1}</span>
                    <span className="w-[30%] md:w-[28%] lg:w-[15%] capitalize">{result?.Name.toLowerCase()}</span>
                    <span className="w-[25%] hidden lg:flex">{result?.Email}</span>
                    <span className="w-[25%] md:w-[15%] lg:w-[15%] capitalize">{result?.Exam}</span>
                    <span className="w-[25%] md:w-[22%] lg:w-[12%]">{new Date(result?.Date)?.toLocaleDateString("en-in",{day: 'numeric' ,month : 'short', year: 'numeric'})}</span>
                    <span className="md:w-[15%] lg:w-[8%] hidden md:flex"> {result?.Score === 'NaN' ? 0 : result?.Score} %</span>
                    <span className={`w-[20%] lg:w-[10%] capitalize py-1 rounded 
                      ${result?.Status === "shortlisted" ? 'shortlisted-txt' : 
                      result.Status === 'hold' ? 'hold-txt' : 
                      result.Status === 'rejected' ? 'rejected-txt' : 
                      result.violations?.includes('terminate') ? 'unconcluded-txt' :
                      'invited-txt'}`
                      }>
                        {result?.Status === "shortlisted" ? 'Shortlisted' : 
                          result.Status === 'hold' ? 'Hold' : 
                          result.Status === 'rejected' ? 'Rejected' : 
                          result.violations?.includes('terminate') ? 'Terminated' :
                          'Pending'}
                    </span>
                    <Link
                      to={`/candidateProfile?id=${result?.UID}`}
                      className='w-[6%] cursor-pointer hidden lg:flex py-1 table-view-btn'
                    > View </Link>
                  </div> 
                ))
              )
            }
          </InfiniteScroll>
        </div>
      </div>
      <div 
            className='hidden fixed rounded-full text-2xl secondary-bg text-[#fff] p-3 bottom-6 right-12 z-50'
            ref={topButtonRef}
        >
            <div 
                onClick={() => scrollableComponent.current.scrollTo({ top: 0, behavior: 'smooth'})}
                className='absolute top-0 left-0 w-full h-full cursor-pointer rounded-full'
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
            </svg>
        </div>
    </>
  )
}

export default Results
