import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

import {Button, TextInput} from '../../components'

const EditAssessmentType = ({questionSheetId,newType,closeEditAssessmentModule,updateRecords,setUpdateAssessmentTypes}) => {

  const [successModule,setSuccessModule ] = useState()
    useEffect(() => setSuccessModule(document.getElementById('successModule')),[])
  const [newAssessment, setNewAssessment] = useState()
  useEffect(() => setNewAssessment(newType),[newType])
  
  const handleEdit = async (e) => {
    e.preventDefault()
    const assessmentName = newAssessment.toLowerCase();
    const sheetData = JSON.stringify([questionSheetId, newType, assessmentName]);
    // console.log(sheetData)
    const response = await axios.post(process.env.REACT_APP_RENAMNE_ASSESSMENT_TYPE,sheetData)
    // console.log(response?.data)

    setUpdateAssessmentTypes(prev =>prev+1)
    successModule.classList.remove('hidden')
    successModule.classList.add('flex')
  }
  return (
    <div className='flex flex-col w-[95%] common-popup gap-8 card p-10 relative'>
          {/*success module */}
          <div id='successModule' className='hidden z-10 absolute flex-col gap-5 p-6 justify-center items-center card top-0 left-0 w-full h-full'>
                  <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                      <p className='text-white text-4xl'>✓</p> 
                  </div>
                  <h1 className='text-lg w-[20rem] text-center'>Assessment type was successfully updated.</h1>
                  <Link to='/assessmentType'>
                      <Button
                          text=" OK "
                          width='6rem'
                          handleClick={() => {
                              closeEditAssessmentModule()    
                              successModule.classList.remove('flex')
                              successModule.classList.add('hidden') 
                          }}
                      />
                  </Link> 
              
          </div>
        {closeEditAssessmentModule && (
          <div
            onClick={closeEditAssessmentModule}
            className='flex justify-center items-center absolute top-4 right-4 rounded-full w-10 h-10 bg-[#F9FAFB] cursor-pointer'
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-7 h-7">
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </div>
        )}
        

        <h1 className='heading2 w-[100%]'>
            Edit Assessment Type
        </h1>
        <form className='flex flex-col w-full gap-6'>
            <div>
              <label className="text-xs mb-1 text-[#888]">
                Rename Assessment Type
              </label>
              <TextInput
                type="text"
                idName="assessmentType"
                inputName = "assessmentType"
                isRequired={true}
                placeholderText="Assessment Type"
                inputValue={newAssessment}
                handleChange={e => setNewAssessment(e.target.value)}
              />
            </div>
            <Button
              text="Update Assessment"
              handleClick={handleEdit}
            />
        </form>
    </div>
  )
}

export default EditAssessmentType