import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

import { Logo } from '../../components'

const SideBar = ({page, sideBarOpen, setSideBarOpen,screen}) => {

  const logo = "url('img/logo_full.png')"
  const smlogo = "url('img/logo.png')"

  const animationVariants = {
    initial: { display : 'none'},
    animate: { display : 'flex'}
  }

  return (
    <motion.div
      variants={{
        none: { width: 0},
        small:{width: 65},
        open: { width : 250 }
      }}
      onMouseEnter = {() => setSideBarOpen('open')}
      onMouseLeave = {() => screen === 'large' && setSideBarOpen('small')}
      onClick = {() => setSideBarOpen('open')}
      initial="small"
      transition={{ duration: 0.3, delay: 0.2 }}
      animate={sideBarOpen === 'open' ? "open" : sideBarOpen === "small" ? "small" : "none"}
      className='fixed h-full flex-col bg-white shadow-[0_0_20px_rgba(0,0,0,0.05)] z-50 rounded-r-[15px]'
    >

        {screen === 'large' ? (
          <div className='h-[50px] mx-3'>
            <Link to='/admin'>
              <motion.div
                className='mt-10 flex items-center justify-center cursor-pointer'
                variants={{
                  initial: { backgroundImage: smlogo, backgroundRepeat: 'no-repeat', backgroundSize : '130px', backgroundPositionX : '0px',width: '50px', height: '55px'},
                  final: { backgroundImage: logo, backgroundRepeat: 'no-repeat', backgroundSize : '140px', backgroundPositionX : '40px', width: '180px', height: '55px'}
                }}
                transition={{ duration: 0.3, delay: 0.2 }}
                initial = 'initial'
                animate={sideBarOpen === 'open' ? 'final' : 'initial'}
              />
            </Link>
          </div>
        ) : (
          <motion.div
            variants={{
              none: { x: -200},
              open: { x : 0 }
            }}
            initial="none"
            transition={{ duration: 0.2, delay: 0.2 }}
            animate={sideBarOpen === 'open' ? "open" : "none"}
          >
            <Link to='/admin'  className='mt-10 flex items-center justify-center h-10'>
              <Logo/>
            </Link>
          </motion.div>
        )}
      
        <div className='h-full flex flex-col gap-10 pt-12'>

            <Link to = "/admin">
              <motion.div
                initial={animationVariants.animate}
                transition={{ duration: 0.2, delay:0.25}}
                animate={sideBarOpen === 'none' ? animationVariants.initial : animationVariants.animate}
                className={`flex gap-5 items-center cursor-pointer px-5 ${page === 'dashboard' && 'secondary-text'}`}
              >
                <div className={`absolute w-16 h-[3rem] rounded-xl left-[-3.6rem] ${page === 'dashboard' && 'secondary-bg'}`}></div>
                <div className='z-10 bg-white'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" strokeWidth="1.5" stroke="currentColor" className="w-5 h-6" >
                    {/* <path d="M62.2222 82.8889H8.88889C7.31371 82.8889 6 81.5752 6 80V8.88889C6 7.31371 7.31371 6 8.88889 6H62.2222C63.7974 6 65.1111 7.31371 65.1111 8.88889V80C65.1111 81.5752 63.7974 82.8889 62.2222 82.8889ZM62.2222 154H8.88889C7.31371 154 6 152.686 6 151.111V115.556C6 113.98 7.31371 112.667 8.88889 112.667H62.2222C63.7974 112.667 65.1111 113.98 65.1111 115.556V151.111C65.1111 152.686 63.7974 154 62.2222 154ZM151.111 154H97.7778C96.2026 154 94.8889 152.686 94.8889 151.111V80C94.8889 78.4248 96.2026 77.1111 97.7778 77.1111H151.111C152.686 77.1111 154 78.4248 154 80V151.111C154 152.686 152.686 154 151.111 154ZM94.8889 44.4444V8.88889C94.8889 7.31371 96.2026 6 97.7778 6H151.111C152.686 6 154 7.31371 154 8.88889V44.4444C154 46.0196 152.686 47.3333 151.111 47.3333H97.7778C96.2026 47.3333 94.8889 46.0196 94.8889 44.4444Z" stroke="black" stroke-width="12"/> */}
                    <path d="M62.2222 82.8889H8.88889C7.31371 82.8889 6 81.5752 6 80V8.88889C6 7.31371 7.31371 6 8.88889 6H62.2222C63.7974 6 65.1111 7.31371 65.1111 8.88889V80C65.1111 81.5752 63.7974 82.8889 62.2222 82.8889ZM62.2222 154H8.88889C7.31371 154 6 152.686 6 151.111V115.556C6 113.98 7.31371 112.667 8.88889 112.667H62.2222C63.7974 112.667 65.1111 113.98 65.1111 115.556V151.111C65.1111 152.686 63.7974 154 62.2222 154ZM151.111 154H97.7778C96.2026 154 94.8889 152.686 94.8889 151.111V80C94.8889 78.4248 96.2026 77.1111 97.7778 77.1111H151.111C152.686 77.1111 154 78.4248 154 80V151.111C154 152.686 152.686 154 151.111 154ZM94.8889 44.4444V8.88889C94.8889 7.31371 96.2026 6 97.7778 6H151.111C152.686 6 154 7.31371 154 8.88889V44.4444C154 46.0196 152.686 47.3333 151.111 47.3333H97.7778C96.2026 47.3333 94.8889 46.0196 94.8889 44.4444Z" strokeWidth="12"/>
                  </svg>
                </div>
                <motion.div
                    variants={{
                      initial: { opacity: 0, display: 'none'},
                      final: { opacity : 1, display: 'flex'}
                      }}
                    transition={{ duration: 0.3 , delay: 0.2}}
                    initial={'initial'}
                    animate={sideBarOpen === 'open' ? "final" : "initial"}
                    className='font-medium'
                >
                    <span>Dashboard</span>
                </motion.div>
              </motion.div>
            </Link>
            
            <Link to="/invitations">
              <motion.div
                initial={animationVariants.animate}
                transition={{ duration: 0.2, delay:0.25}}
                animate={sideBarOpen === 'none' ? animationVariants.initial : animationVariants.animate}
                className={`flex gap-5 items-center cursor-pointer px-5 ${page === 'applications' || page === 'candidate profile' ? 'secondary-text' : ''}`}
              >
                  <div className={`absolute w-16 h-[3rem] rounded-xl left-[-3.6rem] ${page === 'applications' || page === 'candidate profile' ? 'secondary-bg' : ''}`}></div>
                  <div className='z-10 bg-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 159" fill="none" strokeWidth="1.5" stroke="currentColor" className="w-5 h-6">
                      {/* <path d="M39.3334 6H132.667M25.9999 26.0003H146M18.0292 46.0038H153.971C160.614 46.0038 166 51.3894 166 58.0329V140.641C166 147.285 160.614 152.67 153.971 152.67H18.0292C11.3856 152.67 6 147.285 6 140.641V58.0329C6 51.3894 11.3856 46.0038 18.0292 46.0038Z" stroke="black" stroke-width="12" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> */}
                      <path d="M39.3334 6H132.667M25.9999 26.0003H146M18.0292 46.0038H153.971C160.614 46.0038 166 51.3894 166 58.0329V140.641C166 147.285 160.614 152.67 153.971 152.67H18.0292C11.3856 152.67 6 147.285 6 140.641V58.0329C6 51.3894 11.3856 46.0038 18.0292 46.0038Z" strokeWidth="12" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                  <motion.div
                    variants={{
                      initial: { opacity: 0, display: 'none'},
                      final: { opacity : 1, display: 'flex'}
                    }}
                    transition={{ duration: 0.3 , delay: 0.2}}
                    initial={'initial'}
                    animate={sideBarOpen === 'open' ? "final" : "initial"}
                    className='font-medium'
                  >
                    <span>Applications</span>
                  </motion.div>
                </motion.div>
            </Link>
            {/* <Link to="/jobs">
              <motion.div
                initial={animationVariants.animate}
                transition={{ duration: 0.2, delay:0.25}}
                animate={sideBarOpen === 'none' ? animationVariants.initial : animationVariants.animate}
                className={`flex gap-5 items-center cursor-pointer px-5 ${page === 'jobs' || page === 'new job' ? 'secondary-text' : ''}`}
              >
                  <div className={`absolute w-16 h-[3rem] rounded-xl left-[-3.6rem] ${page === 'jobs' || page === 'new job' ? 'secondary-bg' : ''}`}></div>
                  <div className='z-10 bg-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                    </svg>
                  </div>
                  <motion.div
                    variants={{
                      initial: { opacity: 0, x: -100 , display: 'none'},
                      final: { opacity : 1, x: 0, display: 'flex'}
                    }}
                    transition={{ duration: 0.3 , delay: 0.2}}
                    initial={'initial'}
                    animate={sideBarOpen === 'open' ? "final" : "initial"}
                    className='font-medium'
                  >
                    Jobs
                  </motion.div>
                </motion.div>
            </Link> */}
            <Link to="/quesBank">
              <motion.div
                initial={animationVariants.animate}
                transition={{ duration: 0.2, delay:0.25}}
                animate={sideBarOpen === 'none' ? animationVariants.initial : animationVariants.animate}
                className={`flex gap-5 items-center cursor-pointer px-5 ${page === 'question bank' || page === "question upload" ? 'secondary-text' : ''}`}
              >
                  <div className={`absolute w-16 h-[3rem] rounded-xl left-[-3.6rem] ${page === 'question bank' || page === "question upload" ? 'secondary-bg' : ''}`}></div>
                  <div className='z-10 bg-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" fill="none" strokeWidth="1.5" stroke="currentColor" className="w-5 h-6" >
                      {/* <path d="M63 63.4568H128.218M63 87.9135H95.609M63 39H128.218" stroke="black" stroke-width="12" stroke-miterlimit="10" stroke-linecap="round"/> */}
                      {/* <path d="M6 40C6 38.9137 6.91371 38 8 38C9.08629 38 10 38.9137 10 40V136C10 143.714 16.2863 150 24 150H120C121.086 150 122 150.914 122 152C122 153.086 121.086 154 120 154H16C10.5137 154 6 149.486 6 144V40ZM48 6H144C149.486 6 154 10.5137 154 16V112C154 117.486 149.486 122 144 122H48C42.5137 122 38 117.486 38 112V16C38 10.5137 42.5137 6 48 6Z" stroke="black" stroke-width="12"/> */}
                      <path d="M63 63.4568H128.218M63 87.9135H95.609M63 39H128.218" strokeWidth="12" strokeMiterlimit="10" strokeLinecap="round"/>
                      <path d="M6 40C6 38.9137 6.91371 38 8 38C9.08629 38 10 38.9137 10 40V136C10 143.714 16.2863 150 24 150H120C121.086 150 122 150.914 122 152C122 153.086 121.086 154 120 154H16C10.5137 154 6 149.486 6 144V40ZM48 6H144C149.486 6 154 10.5137 154 16V112C154 117.486 149.486 122 144 122H48C42.5137 122 38 117.486 38 112V16C38 10.5137 42.5137 6 48 6Z" strokeWidth="12"/>
                    </svg>

                  </div>
                  <motion.div
                    variants={{
                      initial: { opacity: 0, display: 'none'},
                      final: { opacity : 1, display: 'flex'}
                    }}
                    transition={{ duration: 0.3 , delay: 0.2}}
                    initial={'initial'}
                    animate={sideBarOpen === 'open' ? "final" : "initial"}
                    className='font-medium flex gap-1'
                  >
                    <span>Question</span> <span>Bank</span>
                  </motion.div>
                </motion.div>
            </Link>          

            <Link to="/assessmentType">
              <motion.div
                initial={animationVariants.animate}
                transition={{ duration: 0.2, delay:0.25}}
                animate={sideBarOpen === 'none' ? animationVariants.initial : animationVariants.animate}
                className={`flex gap-5 items-center cursor-pointer px-5 ${page === 'assessment type' ? 'secondary-text' : ''}`}
              >
                  <div className={`absolute w-16 h-[3rem] rounded-xl left-[-3.6rem] ${page === 'assessment type' ? 'secondary-bg' : ''}`}></div>
                  <div className='z-10 bg-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172" fill="none" strokeWidth="1.5" stroke="currentColor" className="w-5 h-6">
                        {/* <path d="M129.07 147.476V15.2302C129.063 12.7844 128.088 10.4408 126.359 8.71132C124.629 6.98185 122.285 6.0071 119.84 6H15.2302C12.7844 6.0071 10.4408 6.98185 8.71132 8.71132C6.98185 10.4408 6.0071 12.7844 6 15.2302V150.607C6.01217 154.683 7.63686 158.589 10.5192 161.472C13.4016 164.354 17.3075 165.979 21.3837 165.991H147.53" stroke="black" stroke-width="12" stroke-linejoin="round"/>
                        <path d="M147.539 165.984C142.643 165.984 137.947 164.039 134.485 160.577C131.023 157.115 129.078 152.42 129.078 147.524V36.7607H156.769C159.217 36.7607 161.565 37.7332 163.296 39.4642C165.027 41.1952 165.999 43.543 165.999 45.991V147.524C165.999 152.42 164.054 157.115 160.592 160.577C157.13 164.039 152.435 165.984 147.539 165.984Z" stroke="black" stroke-width="12" stroke-linejoin="round"/> */}
                        {/* <path d="M79.8379 61.3694H104.47" stroke="black" stroke-width="12" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30.6211 85.99H104.474" stroke="black" stroke-width="12" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30.6211 110.611H104.474" stroke="black" stroke-width="12" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30.6211 135.207H104.474" stroke="black" stroke-width="12" stroke-linecap="round" stroke-linejoin="round"/> */}
                        <path d="M55.2471 67.5447H30.615C28.9818 67.5447 27.4155 66.8959 26.2607 65.741C25.1058 64.5862 24.457 63.0198 24.457 61.3866V36.7546C24.457 35.1214 25.1058 33.5551 26.2607 32.4002C27.4155 31.2453 28.9818 30.5966 30.615 30.5966H55.2471C56.8803 30.5966 58.4466 31.2453 59.6015 32.4002C60.7563 33.5551 61.4051 35.1214 61.4051 36.7546V61.3866C61.4051 63.0198 60.7563 64.5862 59.6015 65.741C58.4466 66.8959 56.8803 67.5447 55.2471 67.5447Z" fill="black"/>
                        <path d="M129.07 147.476V15.2302C129.063 12.7844 128.088 10.4408 126.359 8.71132C124.629 6.98185 122.285 6.0071 119.84 6H15.2302C12.7844 6.0071 10.4408 6.98185 8.71132 8.71132C6.98185 10.4408 6.0071 12.7844 6 15.2302V150.607C6.01217 154.683 7.63686 158.589 10.5192 161.472C13.4016 164.354 17.3075 165.979 21.3837 165.991H147.53" strokeWidth="12" strokeLinejoin="round"/>
                        <path d="M147.539 165.984C142.643 165.984 137.947 164.039 134.485 160.577C131.023 157.115 129.078 152.42 129.078 147.524V36.7607H156.769C159.217 36.7607 161.565 37.7332 163.296 39.4642C165.027 41.1952 165.999 43.543 165.999 45.991V147.524C165.999 152.42 164.054 157.115 160.592 160.577C157.13 164.039 152.435 165.984 147.539 165.984Z" strokeWidth="12" strokeLinejoin="round"/>
                        <path d="M79.8379 61.3694H104.47" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M30.6211 85.99H104.474" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M30.6211 110.611H104.474" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M30.6211 135.207H104.474" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M55.2471 67.5447H30.615C28.9818 67.5447 27.4155 66.8959 26.2607 65.741C25.1058 64.5862 24.457 63.0198 24.457 61.3866V36.7546C24.457 35.1214 25.1058 33.5551 26.2607 32.4002C27.4155 31.2453 28.9818 30.5966 30.615 30.5966H55.2471C56.8803 30.5966 58.4466 31.2453 59.6015 32.4002C60.7563 33.5551 61.4051 35.1214 61.4051 36.7546V61.3866C61.4051 63.0198 60.7563 64.5862 59.6015 65.741C58.4466 66.8959 56.8803 67.5447 55.2471 67.5447Z" fill="secondary-text"/>
                    </svg>
                  </div>
                  <motion.div
                    variants={{
                      initial: { opacity: 0, display: 'none'},
                      final: { opacity : 1, display: 'flex'}
                    }}
                    transition={{ duration: 0.3 , delay: 0.2}}
                    initial={'initial'}
                    animate={sideBarOpen === 'open' ? "final" : "initial"}
                    className='font-medium flex gap-1'
                  >
                  <span>Assessment</span> <span>Types</span>
                  </motion.div>
                </motion.div>
            </Link>

            {/* <Link to="/archiveCandidates">
              <motion.div
                initial={animationVariants.animate}
                transition={{ duration: 0.2, delay:0.25}}
                animate={sideBarOpen === 'none' ? animationVariants.initial : animationVariants.animate}
                className={`flex gap-5 items-center cursor-pointer px-5 ${page === 'archive' ? 'secondary-text' : ''}`}
              >
                  <div className={`absolute w-16 h-[3rem] rounded-xl left-[-3.6rem] ${page === 'archive' ? 'secondary-bg' : ''}`}></div>
                  <div className='z-10 bg-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                  </div>
                  <motion.div
                    variants={{
                      initial: { opacity: 0, display: 'none'},
                      final: { opacity : 1, display: 'flex'}
                    }}
                    transition={{ duration: 0.3 , delay: 0.2}}
                    initial={'initial'}
                    animate={sideBarOpen === 'open' ? "final" : "initial"}
                    className='font-medium flex gap-1'
                  >
                  <span>Archive</span>
                  </motion.div>
                </motion.div>
            </Link> */}

        </div>
    </motion.div>
  )
}

export default SideBar
