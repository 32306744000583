import React, { useEffect, useState } from 'react'

const PostedJob = ({JID,title, department,positions,employmentType,city,country,remote,setJobOpen,setDeleteModuleOpen,setJid}) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [timeAgo, setTimeAgo] = useState()
    useEffect(()=> {
        setInterval(() => {
            setTimeAgo(new Date() -  Number(JID?.slice(4)))
        },100)        
    },[JID])

    const handleOpenJob = (e) => {
        setJobOpen(true)
        setIsMenuOpen(false)
        setJid(JID)
    }
    return (
        <div className='border-2 rounded-[20px] shadow-md w-full px-4 md:px-10'>
            <div className='my-4 flex items-start justify-between'>
                <div className='flex flex-col gap-2'>
                    <div className='flex flex-col sm:flex-row sm:items-center'>
                        <h1 className='font-semibold text-lg capitalize mr-2'>{title}</h1>
                        <p className="text-xs">({employmentType})</p>
                    </div>
                    <div className='flex gap-1 items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                        </svg>
                        <p className='text-[#888] text-sm'>{department}</p>
                    </div>
                    
                    <div className='flex gap-1 items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                        {remote === true ? (
                            <p className='text-sm text-[#888]'> Remote</p>
                        ) : (
                            <p className='text-sm text-[#888]'>{city}, {country}</p>
                        )}                 
                    </div>  
                </div>
                <div className='relative flex gap-2'>
                    <div
                        onClick={handleOpenJob}
                        className='bg-[#5200FF]/10 text-[#5200FF] px-6 py-2 text-center rounded-md flex items-center cursor-pointer'
                    >
                        View
                    </div>
                    <div className='relative py-1 px-4 bg-[#e5383b]/10 rounded-md flex items-center text-[#E5383B]'>
                        <div 
                            className='absolute top-0 left-0 h-full w-full cursor-pointer'
                            onClick={() => {
                                setDeleteModuleOpen(true)
                                setJid(JID)
                            }}               
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </div>
                    {/* <div className='bg-[#558BE4]/10 py-2 px-3 rounded-md cursor-pointer' onClick={ () => setIsMenuOpen(!isMenuOpen)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>                        
                    </div> */}
                    <div className= {`${isMenuOpen ? 'flex' : 'hidden'} absolute top-11 left-12 w-[8rem] bg-[#ddf]/30 backdrop-blur-[2px] shadow-md py-2 flex-col items-start rounded-md`}>
                        <div className='relative w-full py-1 px-5 hover:bg-[#aaa]/10 flex items-center gap-2 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                            </svg>
                            <p>Share</p>
                        </div>
                        <div className='w-full py-1 px-5 hover:bg-[#aaa]/10 flex items-center gap-2 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.7} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                            <p>Edit</p>
                        </div>
                        <div className='w-full py-1 px-5 hover:bg-[#aaa]/10 flex items-center gap-2 text-[#E5383B] cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <p>Delete</p>
                        </div>
                    </div>
                </div>                                              
            </div>
            <hr/>
            <div className='my-4 flex flex-col sm:flex-row sm:justify-between gap-2'>
                <div className='flex items-center gap-2'>
                    <p>{positions} </p>
                    <p>Positions open</p>
                </div>
                <div className='flex items-center gap-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className='text-sm text-[#888]'>
                        { Math.floor(timeAgo/1000) < 60 ? 
                            `${Math.floor(timeAgo/1000)} seconds ago` 
                            : Math.floor(timeAgo/(1000*60)) < 60 ? 
                            `About ${Math.floor(timeAgo/(1000*60)) === 1 ? 'a minute' : `${Math.floor(timeAgo/(1000*60))} minutes`} ago`
                            : Math.floor(timeAgo/(1000*60*60)) < 24 ? 
                            `About ${Math.floor(timeAgo/(1000*60*60)) === 1 ? 'an hour' : `${Math.floor(timeAgo/(1000*60*60))} hours`} ago`
                            : `About ${Math.floor(timeAgo/(1000*60*60*24)) === 1 ? 'a day' : `${Math.floor(timeAgo/(1000*60*60*24))} days`} ago`
                        }
                    </p>
                </div>
            </div>                    
        </div>
  )
}

export default PostedJob