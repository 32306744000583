import React, { useState,useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

const Test = () => {
  const [items, setItems] = useState(Array.from({ length: 50 }))
  const [page, setPage] = useState(1)
  const [total,setTotal] = useState(0)

  // useEffect(() => {
      const fetchMoreData = async () => {
        const scheduleData = JSON.stringify(['1Z6WQ4bDxFWNLrVNDYFaDH3gKewaujgtYjvUUnsLYzHQ', 'schedules',page,50])
        const scheduleResponse =  await axios.post(process.env.REACT_APP_FETCH_API,scheduleData);
        setItems(items.concat(scheduleResponse.data.records));
        setTotal(scheduleResponse.data.totalRecords)
        setPage(prev => prev + 1)
      }
      // fetchSchedules()
  // },[page])

  // const fetchMoreData = () => {
  //   if (items.length >= 500) {
  //     setHasMore(false);
  //     return;
    // }
    // a fake async api call like which sends
    // 20 more records in .5 secs
  //   setTimeout(() => {
  //     setItems(items.concat(Array.from({ length: 50 })));
  //   }, 500);
  // };

  return (
    <div>
      <h1>demo: react-infinite-scroll-component</h1>
      <hr />
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          fetchMoreData()
        }}
        hasMore={items.length !== total}
        loader={<h4>Loading...</h4>}
        height={600}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {items.map((item, index) => {
          return (
            <div style={style} key={index}>
              uid - #{index}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default Test;
