import React from "react";
import ExamLoginLeft from './ExamLoginLeft'
import ExamLogin from './ExamLogin'
import ExamLoginMobile from "./ExamLoginMobile";
import ExamLoginInUnsupportedBrowser from "./ExamLoginInUnsupportedBrowser"
// import {Topbar1} from '../../components'
import { BROWSER_CHROME, BROWSER_EDGE, BROWSER_SAFARI, BROWSER_FIREFOX, BROWSER_OTHER } from "../../constant/Constants";


const Login = () => {
    getBrowserName()
    function getBrowserName(){    
        let userAgent = navigator.userAgent;
        let browserName;
        // console.log(JSON.stringify(userAgent))
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = BROWSER_CHROME;
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = BROWSER_FIREFOX;
          }  else if(userAgent.match(/safari/i)){
            browserName = BROWSER_SAFARI;
          } else if(userAgent.match(/edg/i)){
            browserName = BROWSER_EDGE;
          }else{
            browserName=BROWSER_OTHER;
          }
          
          return browserName;
    }
    function renderView(){
        if(window.innerWidth <=1000){
            return(<ExamLoginMobile/>)
        }else{
            if(getBrowserName() === BROWSER_CHROME || getBrowserName() === BROWSER_EDGE || getBrowserName() === BROWSER_SAFARI || getBrowserName() === BROWSER_FIREFOX){
                // renderExamLoginView()
                return(
                <div className="login-main">
                    
                    <div className='login-card flex items-center justify-center w-[95%] h-[90%] mx-auto my-5 bg-white rounded-[25px] backdrop-blur-[50px] shadow-[0_0_20px_rgba(0,0,0,0.05)]'>
                        <div className='login-left-item w-[50%] h-full rounded-l-[25px] flex flex-col justify-center items-center gradientPrimary'>
                            
                            <ExamLoginLeft/>
                        
                        </div>
                        
                        <div className='login-right-item w-[50%] h-[70vh] flex flex-col'>
                            
                            <ExamLogin />
            
                        </div>
                    </div>
                </div>)
            }else{
                return(<ExamLoginInUnsupportedBrowser/>)
            }
        }
    }

    return (
        <>
        {renderView()}
            {/* {window.innerWidth <=1000 ?
                (
                    <ExamLoginMobile/>
                ) 
                :
                (
                    <div className="">
                        <div className="mb-10">
                            <Topbar1/>
                        </div>
                        <div className='flex items-center justify-center w-[95%] h-[90%] mx-auto my-5 bg-white rounded-[25px] backdrop-blur-[50px] shadow-[0_0_20px_rgba(0,0,0,0.05)]'>
                            <div className='w-[50%] h-full rounded-l-[25px] flex flex-col justify-center items-center gradientPrimary'>
                                
                                <ExamLoginLeft/>
                            
                            </div>
                            
                            <div className='w-[50%] h-[70vh] flex flex-col'>
                                
                                <ExamLogin />
                
                            </div>
                        </div>
                    </div>
                )
            } */}
        </>
        
    )
}

export default Login
