import React, { useEffect,useState } from 'react'
import { motion } from 'framer-motion'

const QuestionModule = ({closeQuestionModule, questions, answerArray,questionsArr, quesNo,setQuesNo,violationQuestionArray}) => {

    const index = Number(questionsArr.indexOf(`${quesNo+1}`))
    const userAnswer = answerArray[index]

    const [showCodeId, setShowCodeId] = useState()
    const expandCode = (e) => {
        e.target.id === showCodeId ? setShowCodeId("") : setShowCodeId(e.target.id)
    }

  return (
    <div className='card px-6 py-4 w-[90%] sm:w-4/5 lg:w-2/3 flex items-center gap-4 h-[95%] overflow-hidden relative'>        
        <div className='absolute top-4 right-4 bg-[#F9FAFB] p-1 rounded-full cursor-pointer shadow-md'>
            <svg onClick={closeQuestionModule} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
        </div>
        
        <div className='relative h-[5rem] w-[5%] flex justify-center items-center text-[#888] cursor-pointer'>
            {index === 0 ? "" : (
                <>
                    <div
                        className='absolute w-full h-full left-0 top-0'
                        onClick={() => {
                            setShowCodeId()
                            setQuesNo(questionsArr[index-1]-1)
                        }}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </>
                
            )}
        </div>
        <motion.div 
            className='w-[90%] h-full'
        >
            <h1 className='capitalize font-semibold mb-2'>question no. {index+1}</h1>
            {questions && (
                <div className='w-full h-full scrollbar-none overflow-y-auto'>
                    <div className='bg-[#FCFCFC] py-2 px-5 rounded-lg'>
                        <p className='text-[#444] text-sm'>
                            {questions[quesNo]?.questionStatment.split('/n').map(element => (
                                <>
                                    {element.split('<code>')[0]}
                                        {element.split('<code>')[1] && 
                                            <div className='w-full border-2 p-2 my-2 px-4 rounded-lg relative'>
                                                <div className='flex items-center'>
                                                    <p className={`font-semibold ${showCodeId ? 'hidden' : ''}`}>Code - </p>
                                                    <p
                                                        id={questions[quesNo].num}
                                                        className={`absolute right-5 cursor-pointer text-blue-400 ${showCodeId ? 'top-2' : ''}`}
                                                        onClick={(e) => expandCode(e)}
                                                    >
                                                        {showCodeId ? 'hide' : 'show'}
                                                    </p>
                                                </div>
                                                <pre className={`${Number(showCodeId) === Number(questions[quesNo]?.num) ? '' : 'hidden'}`}>{element.split('<code>')[1]}</pre>                                                                    
                                            </div>
                                    }
                                </>                                                        
                            ))}
                        </p>
                        {questions[quesNo]?.questionStatmentImage && 
                            <img src={questions[quesNo]?.questionStatmentImage} alt='' className='h-3/4 w-3/4 mx-auto'/>
                        }
                    </div> 

                    <div className='flex flex-col gap-3 mt-2 text-xs text-[#555] mb-6'>
                        <div className={`py-2 px-5 rounded-lg flex items-center justify-between ${questions[quesNo]?.answer === "A" ? 'bg-[#E0FFE0]' : userAnswer === "A" ? 'bg-[#FFEFEF]' : 'bg-[#F9FAFB]'}`}>
                            <div className='flex items-center gap-4'>
                                <span className='font-medium'>A.</span><pre>{questions[quesNo]?.optionA}</pre>
                            </div>   
                            {userAnswer === "A" && ((questions && questions[quesNo]?.answer) === "A" ? (
                                <div className='text-[#57AD55] font-medium'><span className='text-sm mx-1'>✓</span> Correct</div>
                            ) : (
                                <div className='text-[#EA4335] font-medium flex items-center'>
                                    <p className='text-2xl mr-2'>×</p> Incorrect
                                </div>
                            ))}
                        </div>
                        <div className={`py-2 px-5 rounded-lg flex items-center justify-between ${questions[quesNo]?.answer === "B" ? 'bg-[#E0FFE0]' : userAnswer === "B" ? 'bg-[#FFEFEF]' : 'bg-[#F9FAFB]'}`}>
                            <div className='flex items-center gap-4'>
                                <span className='font-medium'>B.</span><pre>{questions[quesNo]?.optionB}</pre>
                            </div>
                            {userAnswer === "B" && ((questions && questions[quesNo]?.answer) === "B" ? (
                                <div className='text-[#57AD55] font-medium'><span className='mx-1'>✓</span> Correct</div>
                            ) : (
                                <div className='text-[#EA4335] font-medium flex items-center'>
                                    <p className='mr-2'>×</p> Incorrect
                                </div>
                            ))}              
                            
                        </div>
                        <div className={`py-2 px-5 rounded-lg flex items-center justify-between ${questions[quesNo]?.answer === "C" ? 'bg-[#E0FFE0]' : userAnswer === "C" ? 'bg-[#FFEFEF]' : 'bg-[#F9FAFB]'}`}>
                            <div className='flex items-center gap-4'>
                                <span className='font-medium'>C.</span><pre>{ questions && questions[quesNo]?.optionC}</pre>
                            </div>    
                            {userAnswer === "C" && ((questions && questions[quesNo]?.answer) === "C" ? (
                                <div className='text-[#57AD55] font-medium'><span className='mx-1'>✓</span> Correct</div>
                            ) : (
                                <div className='text-[#EA4335] font-medium flex items-center'>
                                    <p className='mr-2'>×</p> Incorrect
                                </div>
                            ))}                   
                        </div>
                        <div className={`py-2 px-5 rounded-lg flex items-center justify-between ${questions[quesNo]?.answer === "D" ? 'bg-[#E0FFE0]' : userAnswer === "D" ? 'bg-[#FFEFEF]' : 'bg-[#F9FAFB]'}`}>
                            <div className='flex items-center gap-4'>
                                <span className='font-medium'>D.</span><pre>{ questions && questions[quesNo]?.optionD}</pre>
                            </div>
                            {userAnswer === "D" && ((questions && questions[quesNo]?.answer) === "D" ? (
                                <div className='text-[#57AD55] font-medium'><span className='mx-1'>✓</span> Correct</div>
                            ) : (
                                <div className='text-[#EA4335] font-medium flex items-center'>
                                    <p className='mr-2'>×</p> Incorrect
                                </div>
                            ))}  
                        </div>
                    </div>
                    {
                        violationQuestionArray[quesNo] && (
                             <div className='mt-4 py-3 border-t-2 flex items-center gap-3 text-sm'>
                                <div className='flex gap-1 font-medium text-[#FFB800] text-base items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                    </svg>
                                    {violationQuestionArray[quesNo].split(',').length > 1 ? 'Violations' : 'Violation'}</div>-
                                <span>{violationQuestionArray[quesNo]}</span>     
                            </div>
                        )
                    }
                   
                </div>     
            )}
        </motion.div>
        <div className='relative     h-[5rem] w-[5%] flex justify-center items-center text-[#888] cursor-pointer'>
            {index+1 === questionsArr.length ? "" : (
                <>
                    <div
                        className='absolute w-full h-full left-0 top-0'
                        onClick={() => {
                            setShowCodeId();
                            setQuesNo(questionsArr[index+1]-1)
                        }}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </>
            )}
        </div>
    </div>
  )
}

export default QuestionModule
