import React from 'react'
import { Link } from 'react-router-dom'

const QuestionAnalyse = ({quesLoading,questions, answerArray,violationQuestionArray, openQuestionModule,questionArry}) => {

   return (
    <div className='py-5 px-4 md:p-6 lg:h-[70vh] w-full overflow-y-auto scrollbar-none'>
        <h1 className='font-bold capitalize mb-2'> 
            Question wise analyse
        </h1>
        <div className='bg-[#E9EAEB]/60 py-4 font-medium backdrop-blur-lg text-sm rounded-t-lg'>
            <div className='flex'>
                <div className='w-1/5 md:w-[10%] flex justify-center items-center'>
                    No.
                </div>
                <div className='w-1/5 md:w-[20%] flex justify-center items-center'>
                    Type
                </div>
                <div className='w-1/5 md:w-[20%] flex justify-center items-center'>
                    Difficulty
                </div>
                <div className='w-1/5 md:w-[20%] flex justify-center items-center'>
                    Status
                </div>
                <div className='w-1/5 md:w-[15%] flex justify-center items-center'>
                    Violation Count
                </div>
                <div className='hidden md:flex w-[15%] justify-center items-center'>
                    Action
                </div>
            </div>
        </div>
        {quesLoading ? 
            (
                <div className='flex flex-col gap-2'>
                    <div className='bg-[#aaa]/10 w-full h-[1.5rem] mt-4 rounded-2xl'/>
                    <div className='bg-[#bbb]/10 w-full h-[1.5rem] mt-4 rounded-2xl'/>
                    <div className='bg-[#ccc]/10 w-full h-[1.5rem] mt-4 rounded-2xl'/>
                    <div className='bg-[#ddd]/10 w-full h-[1.5rem] mt-4 rounded-2xl'/>
                    <div className='bg-[#eee]/10 w-full h-[1.5rem] mt-4 rounded-2xl'/>
                </div>
            ) : questions && (
                questionArry?.map((randomQusNum,index) => {
                    const number = Number(randomQusNum-1)
                return(
                    <div
                        key={questions[number]?.num}
                        className='relative bg-[#fff] even:bg-[#F9FAFB] text-[#777] text-xs py-4 md:py-[10px] font-medium'
                    >
                        <div
                            id= {number+1}
                            onClick={openQuestionModule}
                            // to = "/candidateProfile"
                            className='lg:hidden absolute top-0 left-0 w-full h-full cursor-pointer'
                        ></div>

                        <div className='flex'>
                            <div className='w-1/5 md:w-[10%] flex justify-center items-center'>
                                {index+1}
                            </div>
                            <div className='w-1/5 md:w-[20%] flex justify-center items-center'>
                                MCQ
                            </div>
                            <div className='w-1/5 md:w-[20%] flex justify-center items-center'>
                                {questions[number]?.difficulty  ? questions[number]?.difficulty : '-'}
                            </div>
                            <div className='w-1/5 md:w-[20%] flex justify-center items-center'>
                                {(
                                    answerArray[index] === "" ? (
                                        <div className=''> Unattempted </div>
                                    ) : answerArray[index] === "N" ? (
                                        <div>  NA </div>
                                    ) : answerArray[index] === questions[number].answer ? (
                                        <div className='correct-txt'> Correct </div>
                                    ) : (
                                        <div className='rejected-txt'> Incorrect </div>
                                    )
                                )}                                 
                            </div>
                            <div className='w-1/5 md:w-[15%] flex justify-center items-center'>
                                {violationQuestionArray[number] ? (
                                    <div className='flex items-center gap-2  text-[#FFB800] text-sm'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                        <span>{violationQuestionArray[number].split(",").length}</span>
                                    </div>
                                ) : "-"}
                            </div>
                            <div className='hidden md:flex w-[15%] justify-center items-center' >
                                <span
                                    className='table-view-btn'
                                    id= {number+1}
                                    onClick={openQuestionModule}
                                >View</span>
                            </div>
                        </div>
                    </div>
                )
            }))
        }
    </div>
  )
}

export default QuestionAnalyse
