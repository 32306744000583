import React,{useEffect, useState} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import {Button, TextInput} from '../../components'

const EditQuestionModule = ({question,questionSheetId,assessmentType,closeEditQuestionModule,setUpdateQuestions}) => {

    const [questionDifficulty, setQuestionDifficulty] = useState()
    const [questionStatment, setQuestionStatment] = useState();
    const [optionA, setOptionA] = useState();
    const [optionB, setOptionB] = useState();
    const [optionC, setOptionC] = useState();
    const [optionD, setOptionD] = useState();
    const [time, setTime] = useState();
    const [answer, setAnswer] = useState();

    const [successModuleOpen, setSuccessModuleOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setQuestionDifficulty(question?.difficulty)
        setQuestionStatment(question?.questionStatment)
        setOptionA(question?.optionA)
        setOptionB(question?.optionB)
        setOptionC(question?.optionC)
        setOptionD(question?.optionD)
        setTime(question?.time)
        setAnswer(question?.answer)
    },[question])

    const optionInputs = [
        { placeholder : "Option A", value : optionA, onChange : e => setOptionA(e.target.value) },
        { placeholder : "Option B", value : optionB, onChange : e => setOptionB(e.target.value) },
        { placeholder : "Option C", value : optionC, onChange : e => setOptionC(e.target.value) },
        { placeholder : "Option D", value : optionD, onChange : e => setOptionD(e.target.value) }
    ] 

    const handleUpdate = async () => {
        setLoading(true)
        setSuccessModuleOpen(true)
        const editData = JSON.stringify([questionSheetId, assessmentType, question?.num, questionStatment, optionA, optionB, optionC, optionD, time, answer, questionDifficulty]);
        const response = await axios.post(process.env.REACT_APP_EDIT_QUESTION,editData)
        if(response.data.message !== 'success'){
            setSuccessModuleOpen(false)
        }
        setUpdateQuestions(prev => prev+1)
        setLoading(false)
    }

  return (
    <div className='relative flex flex-col card w-[90%] md:w-fit p-6'>
        <div
            onClick={() => {
                closeEditQuestionModule()
                setSuccessModuleOpen(false)
            }}
            className='flex justify-center items-center absolute top-4 right-4 rounded-full w-10 h-10 bg-[#F9FAFB] cursor-pointer'
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-7 h-7">
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
        </div>
        {/*success module */}
        {successModuleOpen ? 
            <div className={`flex-col gap-5 p-6 justify-center items-center`}>
                {
                    loading ? (
                        <div className='font-bold text-[#888] text-2xl p-10'>
                            Loading ...
                        </div>
                        
                    ) : (
                        <div className='flex flex-col items-center justify-center gap-4'>
                            <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                                <p className='text-white text-4xl'>✓</p> 
                            </div>
                            <h1 className='text-lg text-center'>Question was updated successfully.</h1>
                            <Link to='/quesBank'>
                                <Button
                                    text=" OK "
                                    width='6rem'
                                    handleClick={() => {
                                        closeEditQuestionModule()   
                                        setSuccessModuleOpen(false)
                                    }}
                                />
                            </Link>
                        </div>
                    )
                } 
            </div> : 
            <form className="flex flex-col justify-center items-center gap-2"> 
                    <h1 className="heading2">Edit Question</h1>
                    <div className="flex flex-col gap-2 w-full px-4">
                        <div className='flex gap-2'>
                            <div className='w-1/2'>
                                <label className="text-xs mb-1 text-[#888]">
                                    Question Type
                                </label>
                                <TextInput
                                    type="text"
                                    inputValue="MCQ"
                                />
                            </div>
                            <div className='w-1/2'>
                                <label className="text-xs mb-1 text-[#888]">
                                    Question Difficulty
                                </label>
                                <TextInput
                                    type="select"
                                    idName="type"
                                    inputName = "type"
                                    placeholderText="Select Question Difficulty"
                                    inputValue={questionDifficulty}
                                    handleChange={e => setQuestionDifficulty(e.target.value)}
                                    selectOptions= {['Easy','Medium','Hard']}
                                />
                            </div>
                        </div>
                        <div>
                            <label className="text-xs mb-1 text-[#888]">
                                Question Statement
                            </label>
                            <TextInput
                                type="textbox"
                                textRows="4" 
                                placeholderText='Question Statement'
                                inputValue={questionStatment}
                                handleChange={(e) => setQuestionStatment(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="text-xs mb-1 text-[#888]">
                                Options
                            </label>
                            <div className='flex flex-col gap-2'>
                                {[0,2].map((i) => {
                                    return(
                                        <div key={i} className='flex gap-2'>
                                            {optionInputs.slice(i,i+2).map((optionInput) =>{
                                                return(
                                                    <TextInput 
                                                        key={optionInput.placeholder}
                                                        placeholderText={optionInput.placeholder}
                                                        inputValue={optionInput.value}
                                                        handleChange={optionInput.onChange}
                                                        innerLabel={optionInput.placeholder.slice(-1)+'.'}
                                                    />
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div> 
                        </div>
                                    
                                
                        <div className='flex gap-2 mb-4 w-full'>
                            <div className='w-1/2'>
                                <label className="text-xs mb-1 text-[#888]">
                                    Correct Option
                                </label>
                                <TextInput
                                    type="select"
                                    idName="answer"
                                    inputName="answer"
                                    placeholderText="Select Correct Answer"
                                    inputValue={answer}
                                    handleChange={e => setAnswer(e.target.value)}
                                    selectOptions = {["A", "B", "C", "D"]}
                                />
                            </div>
                            <div className='w-1/2'>
                                <label className="text-xs mb-1 text-[#888]">
                                    Duration
                                </label>
                                <TextInput
                                    type="number"
                                    placeholderText="Question Duration (in minutes)"
                                    inputValue={time}
                                    handleChange={e => setTime(e.target.value)}
                                />
                            </div> 
                        </div>
                        {/* {error && (
                            <div className='text-red-500 text-center text-sm'>
                                {error}
                            </div>
                        )} */}
                        <div className='flex justify-center w-full'>
                            <Button
                                text="Update Question"
                                handleClick={handleUpdate}
                                width='100%'
                            />                   
                        </div>
                    </div>                               
                </form>
        }
    </div>
  )
}

export default EditQuestionModule