import React,{useRef, useEffect} from 'react'

const SoundMeter = () => {

  const refs = useRef([]);
  const volume = useRef(0);
  const volumeRefs = useRef(new Array(1));

  const getMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        // stream.getTracks()[0].enabled = false
        // console.log("ismute::::::"+stream.getTracks()[0].enabled)
        // console.log("length"+stream.getTracks().length)
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        analyser.smoothingTimeConstant = 0.4;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = () => {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;
          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          volume.current = values / length;
        };
      })
      .catch(function(err) {
        /* handle the error */
      });
  };

  useEffect(getMedia, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      volumeRefs.current.unshift(volume.current);
      volumeRefs.current.pop();
      for (let i = 0; i < refs.current.length; i++) {
        if(refs.current[i] != null){
          refs.current[i].style.transform = `scaleX(${volumeRefs.current[i]*2 / 100})`;
        }       
      }
    }, 20);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const createElements = () => {
    let elements = [];
    // for (let i = 0; i < settings.bars; i++) {
      elements.push(
        <div
          ref={ref => refs.current.push(ref)}
          style={{
            background: "#8A24F1",
            width: '100%',
            height:'8px',
            borderRadius:'8px',
            // transitionDuration:'1'
          }}
        />
      );
    // }
    return elements;
  };

  return (
    <div className='relative flex gap-2 bg-[#aaa]/20 rounded-full w-full h-[8px] overflow-hidden'>
      <div className='absolute left-[-50%] w-full h-[8px]'>
        {createElements()}
      </div>
      
    </div>
  )
}

export default SoundMeter
