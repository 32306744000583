import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components';
import {Results,AllSchedules, Applications,ScheduleTest} from "./"

const Main = ({tab,dataSheetId,adminName,adminEmail,screen,assessmentNames,
    schedulesLoading,scheduleFilter,setScheduleFilter,schedules,totalShedules,setUpdateSchedules,
    resultsLoading,results,resultFilter,setResultFilter,totalResults,
    // applicationsLoading,applications,totalApplications
}) => {

    const [newScheduleModuleOpen, setNewScheduleModule] = useState(false)
    const [openfilterMenu, setOpenfilterMenu] = useState(false)
    useEffect(() => {
        setOpenfilterMenu(false)
    },[tab])

    const [sloading, setSLoading] = useState(false)
    const [schedulePage, setSchedulePage] = useState(2)
    const [rloading, setRLoading] = useState(false)
    const [resultPage, setResultPage] = useState(2)

    useEffect(() => {
        !schedulesLoading && setSLoading(false) 
    },[schedulesLoading])
    useEffect(() => {
        !resultsLoading && setRLoading(false) 
    },[resultsLoading])

    const handleFilterSchedule = (filter) => {
        setSchedulePage(1)
        setScheduleFilter(filter)
        setSLoading(true)
    }
    const handleFilterResults = (filter) => {
        setResultPage(1)
        setResultFilter(filter)
        setRLoading(true)    
    }

    const tabs = ['invitations','results']
    const invitationFilters = ['all','upcoming','attempted','missed']
    const resultFilters = ['all','pending','shortlisted','hold','rejected']
    
    return (
        <div className='main-container mx-auto md:ml-20 mt-1 flex flex-col lg-px-10 py-2'>
            <div className="card relative lg:h-[90vh] overflow-hidden">
                <div className={`${newScheduleModuleOpen ? 'flex' : 'hidden'} absolute p-4 justify-center top-0 left-0 w-full h-full bg-[#000]/10 backdrop-blur-[2px] z-10`}>
                    <ScheduleTest
                        closeModule={() => setNewScheduleModule(false)}
                        newScheduleModuleOpen={newScheduleModuleOpen}
                        adminName={adminName}
                        adminEmail={adminEmail}
                        dataSheetId={dataSheetId}
                        assessmentNames={assessmentNames}
                        setUpdateSchedules={setUpdateSchedules}
                    />
                </div>
                <div className='application-filter-bar flex gap-2 h-[10%] pr-4'>
                    <div className='left-item w-full flex items-center gap-3 py-2 px-4 overflow-x-auto scrollbar-none'>
                        {tabs?.map((tabName)=>{
                            return (
                                <Link
                                    to ={`/${tabName}`}
                                    key={tabName}
                                    id={`tab-${tabName}`}
                                    className={`tab-btn capitalize text-sm font-medium rounded-full px-8 py-2 cursor-pointer ${tab === tabName ? 'bg-[#558BE4] text-[#fff]' : 'not-active'}`}
                                >
                                    {tabName}
                                </Link>
                            )
                        })}
                    </div>
                    {tab === 'invitations' ? 
                        (scheduleFilter !== 'all' && 
                            <div className='flex items-center justify-center mr-2'>
                                <div className='bg-[#eee] h-fit pl-4 pr-2 py-2 text-sm capitalize rounded-full flex items-center gap-2'>
                                    <>
                                        {scheduleFilter}
                                        <svg onClick={() => handleFilterSchedule('all')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="bg-[#ddd] rounded-full w-5 h-5 cursor-pointer">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                        </svg>
                                </>
                                </div>
                            </div>
                        ) : (resultFilter !== 'all' &&
                            <div className='flex items-center justify-center mr-2'>
                                <div className='bg-[#eee] h-fit pl-4 pr-2 py-2 text-sm capitalize rounded-full flex items-center gap-2'>
                                    <>
                                        {resultFilter}
                                        <svg onClick={() => handleFilterResults('all')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="bg-[#ddd] rounded-full w-5 h-5 cursor-pointer">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                        </svg>
                                </>
                                </div>
                            </div>
                        )
                    }
                    <div className='right-item'>
                        <div 
                            className='relative flex items-center gap-4 py-2'
                            // onBlur={() => setOpenfilterMenu(false)}
                            onClick={() => setOpenfilterMenu(!openfilterMenu)}
                        >
                            <Button
                                text=''
                                version='outline-white'
                                className='filter-btn w-32 flex items-center gap-2'
                            >
                                <span className='text-base'>Filter</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                                </svg>
                            </Button>
                            <div className={`z-10 absolute top-14 min-w-full left-0 card overflow-hidden shadow-md flex items-center justify-center ${openfilterMenu ? 'flex' : 'hidden'}`}>
                                <div className='flex flex-col w-full'>
                                    {tab === 'invitations' ? 
                                        invitationFilters.map((filter) => (
                                            <span
                                                key = {filter}
                                                className={`py-2 hover:bg-[#eee] px-5 cursor-pointer capitalize ${scheduleFilter === filter && 'bg-[#8A24F1] text-white hover:bg-[#8A24F1]'} `}
                                                onClick={() => scheduleFilter !== filter && handleFilterSchedule(filter)}
                                            >
                                                {filter}
                                            </span>
                                        ))
                                    : tab === 'results' ? 
                                        resultFilters.map((filter) => (
                                            <span
                                                key = {filter}
                                                className={`py-2 hover:bg-[#eee] px-5 cursor-pointer capitalize ${resultFilter === filter && 'bg-[#8A24F1] text-white hover:bg-[#8A24F1]'} `}
                                                onClick={() => resultFilter !== filter && handleFilterResults(filter)}
                                            >
                                                {filter}
                                            </span>
                                        ))
                                        : ''
                                    }
                                </div>                    
                            </div>
                        </div>
                        <div className='invite-candidate flex items-center justify-center py-2'>
                            <Button
                                className='h-full w-[10rem]'
                                text=""
                                handleClick={() => setNewScheduleModule(true)}
                            ><div className='flex gap-2'><span>Invite</span><span>Candiate</span></div></Button>
                        </div>
                    </div>  
                </div>
                
                <div className='w-ful h-[90%] px-4 py-2'>
                    {tab === 'invitations' && (
                        <AllSchedules
                            schedulesLoading={schedulesLoading}
                            loading = {sloading}
                            dataSheetId={dataSheetId}
                            page={schedulePage}
                            setPage={setSchedulePage}
                            scheduleFilter={scheduleFilter}
                            schedules={schedules}
                            totalShedules={totalShedules}
                            screen={screen}
                        />
                    )}
                    {tab === 'results' && (
                        <Results
                            dataSheetId={dataSheetId}
                            resultsLoading={resultsLoading}
                            loading={rloading}
                            page = {resultPage}
                            setPage = {setResultPage}
                            results={results}
                            resultFilter={resultFilter}
                            totalResults={totalResults}
                            screen={screen}
                        />
                    )}
                    {/* {tab === 'applications' && (
                        <Applications
                            dataSheetId={dataSheetId}
                            applicationsLoading={applicationsLoading}
                            applications= {applications}
                            totalApplications={totalApplications}
                            screen= {screen}
                        />
                    )} */}
                </div>
            </div>
        </div>
  )
}

export default Main