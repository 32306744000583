import React,{useEffect, useState} from 'react';
import axios from 'axios';

import emailBody from '../../emailBody';
import {Button,TextInput } from '../../components'

const ScheduleTestCandidate = ({closeModule,adminName,adminEmail,dataSheetId,assessmentNames, uid,candidateEmail, candidateName,jobTitle, isFromScheduleNow, companyName }) => { 

    const [date, setDate] = useState("");
    const [localDate, setLocalDate] = useState("");
    const [localTime, setLocalTime] = useState("");
    const [profile, setProfile] = useState("")
    const [maxQuestions, setMaxQuestions] = useState()
    const [maxViolations, setMaxViolations] = useState()
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const code = (Math.random() + 1).toString(36).substring(6)

    useEffect(() => {
        const fetchAdmin = async () => {
            if(adminEmail){
                setLoading(true)
                const response = await axios.post(process.env.REACT_APP_ADMIN_LOGIN ,adminEmail);
                const adminData = response?.data.admin
                setMaxQuestions(adminData.maxQuestions);
                setMaxViolations(adminData.maxViolations);
                setLoading(false)
            }
        }
        fetchAdmin()
    },[adminEmail])

    const sendEmail = () => {
        const candidateEmailSubject = `Thank you for applying at ${adminName} - Assessment Round`
        const hrEmailSubject = `Assessment Scheduled - ${candidateName}`
        const candidateEmailMessage = emailBody.candidateEmail(adminName,adminEmail,candidateName,candidateEmail,localDate,localTime,code)
        const hrEmailMessage = emailBody.hrEmail(adminName,adminEmail,localDate,localTime,profile)
        const candidateEmailData = JSON.stringify([candidateEmail,candidateEmailSubject,candidateEmailMessage])
        const hrEmailData = JSON.stringify([adminEmail,hrEmailSubject,hrEmailMessage])
        const mailApi = process.env.REACT_APP_EMAIL_API
        axios.post(mailApi,candidateEmailData)
        axios.post(mailApi,hrEmailData)
    }

    const handleSchedule = async () => {
        setErrorMessage("")
        if(date !== "" && profile !== "") {            
            if(new Date(date) < new Date()){
                return setErrorMessage("The exam date cannot be of past.")
            }

            setLoading(true)
            const sheetData = JSON.stringify([dataSheetId,uid,adminEmail,candidateName,candidateEmail,profile,localDate,localTime,code,"",maxViolations,maxQuestions]);
            const postScheduleApi = process.env.REACT_APP_POST_SCHEDULE_API
            const response = await axios.post(postScheduleApi,sheetData)
            setLoading(false)
            if(response.data.status === 'added'){
                const nid = `nid_${Date.now()}`
                const notificationDate = new Date().toDateString()
                const notificationTime = new Date().toLocaleString("en-in",{hour: 'numeric' ,minute : 'numeric'})
                const notificationBody = `${candidateName} had Applied for ${jobTitle} profile and scheduled the assessment for ${profile} on ${localDate} at ${localTime}`
                let notificationData = `${dataSheetId}|${nid}|${adminEmail}|${uid}|${notificationDate}|${notificationTime}|${notificationBody}`
                axios.post(process.env.REACT_APP_ADD_NOTIFICATION,notificationData)
                openSuccessModule(); 
                sendEmail();                
            }else if(response.data.status === 'emailExists'){
                setErrorMessage("Email already Exists")
            }else{
                setErrorMessage('Something went wrong')
            }            
        }
        else{
            setErrorMessage("Please fill out all the fields")
        }
    }

    const openSuccessModule = () => {
        let successModule = document.getElementById("success-module");
        successModule.classList.remove("hidden");
        successModule.classList.add("flex");
    }

    const handleScheduleNow = async (dateNow, timeNow) => {
        if(profile !== "") { 
            setLoading(true)
            const sheetData = JSON.stringify([dataSheetId,uid,adminEmail,candidateName,candidateEmail,profile,dateNow,timeNow,code,"",maxViolations,maxQuestions]);
            const postScheduleApi = process.env.REACT_APP_POST_SCHEDULE_API
            const response = await axios.post(postScheduleApi,sheetData)
            setLoading(false)
            if(response.data.status === 'added'){
                const nid = `nid_${Date.now()}`
                const notificationDate = new Date().toDateString()
                const notificationTime = new Date().toLocaleString("en-in",{hour: 'numeric' ,minute : 'numeric'})
                const notificationBody = `${candidateName} had Applied for ${jobTitle} profile and scheduled assessment for ${profile} for now.`
                let notificationData = `${dataSheetId}|${nid}|${adminEmail}|${uid}|${notificationDate}|${notificationTime}|${notificationBody}`
                axios.post(process.env.REACT_APP_ADD_NOTIFICATION,notificationData)          
                examLogin();                
            }else if(response.data.status === 'emailExists'){
                setErrorMessage("Email already Exists")
            }else{
                setErrorMessage('Something went wrong')
            }            
        }
        else{
            setErrorMessage("Please fill out all the fields")
        }    
    }

    const examLogin = async () => {
        setLoading(true)
        let candidateData;         
        const fetchData = `${companyName}|${candidateEmail}`
        const candidateResponse = await axios.post(process.env.REACT_APP_EXAM_LOGIN ,fetchData);
        if(candidateResponse?.data?.status === 'success'){
            candidateData = candidateResponse?.data
            if (candidateData) {            
                if(window.innerWidth <= 820){
                    setErrorMessage("Please join via a Laptop/PC.");
                } else {
                    let candidateDetails = {
                        dataSheetId : candidateData.dataSheetID,
                        questionSheetId : candidateData.questionSheetId,
                        uid :candidateData.candidate?.UID,
                        emailId : candidateEmail,
                        name : candidateData.candidate?.Name,
                        exam : candidateData.candidate?.ApplyFor,
                        hrEmail : candidateData.candidate?.HrEmail,
                        maxViolations : candidateData.candidate?.maxViolations,
                        maxQuestions : candidateData.candidate?.maxQuestions,
                        examDate : new Date(candidateData.candidate?.Date).toDateString(),
                        examTime : new Date(candidateData.candidate?.Time).toLocaleTimeString(),
                        isCandidateLogin : true,
                        companyName:companyName
                    }
                    localStorage.removeItem('refresh')
                    localStorage.setItem('candidateDetails', JSON.stringify(candidateDetails));
                    window.location.href = "/examSetup"
                }  
            }
        }
        setLoading(false)
    }

    return (
        <div className='card lg:w-2/5 flex justify-center py-6 px-8 relative  overflow-hidden'>            
            <div className='absolute top-4 right-4 bg-[#F9FAFB] p-1 rounded-full cursor-pointer'>
                <svg onClick={() => closeModule("newSchedule")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
            </div>
            <div id='success-module' className='absolute w-full h-full top-0 left-0 hidden justify-center items-center backdrop-blur-[2px]  bg-[#0000]/10'>
                <div className='card p-10 flex flex-col justify-center items-center gap-5'>
                    <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                        <p className='text-white text-4xl'>✓</p> 
                    </div>
                    <h1 className='heading1'>
                        Successfully Scheduled
                    </h1>
                    <Button
                        text=" OK "
                        width='50%'
                        handleClick={() => window.location.href = `https://staginginterview.naskay.in/?key=${companyName}`}
                    />
                </div>
            </div>
            <form id="scheduleForm" className="w-[90%] flex flex-col gap-4 items-center">
                <h1 className='heading2'>
                    Schedule an Assessment for you
                </h1>
                <div className="flex flex-col gap-4 w-full">
                    
                    <div className='flex gap-2'>
                        <div className='w-full'>
                            <label className="text-xs mb-1 text-[#888]">
                                Assessment Type
                            </label>
                            <TextInput
                                type="select"
                                idName="jobProfile"
                                inputName="applyFor"
                                isRequired={true}
                                inputValue={profile}
                                handleChange={(e) => setProfile(e.target.value)}
                                selectOptions= {assessmentNames}
                                placeholderText="Assessment Type"
                            />
                        </div>                                                 
                    </div>
                    {isFromScheduleNow.toString() === 'false' &&
                        <div>
                            <label className="text-xs mb-1 text-[#888]">
                                Date and Time for Assessment
                            </label>
                            <TextInput
                                type="datetime-local"
                                InputName="Date"
                                isRequired = {true}
                                inputValue={date}
                                handleChange={(e) => {
                                    setDate(e.target.value)
                                    setLocalDate(new Date(e.target.value).toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'}))
                                    setLocalTime(new Date(e.target.value).toLocaleString("en-in", { hour:"numeric", minute: "numeric"}))
                                }}
                            />
                        </div>
                    }    
                                              
                </div>
                <div className='self-center'>
                    {errorMessage &&
                        <div className='text-red-500 text-center text-sm mb-2'>{errorMessage}</div>
                    } 
                    {loading ? (
                        <div className='text-[#ccc] font-semibold text-center'>
                            Loading ...
                        </div>
                    ) : (
                        <Button
                            text={isFromScheduleNow ? "Start your test" : "Schedule your Assessment"}
                            handleClick={(e) => {
                                if(isFromScheduleNow){
                                    // var date = new Date()
                                    var dateNow = new Date().toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
                                    var timeNow = new Date().toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
                                    // setDate(new Date());
                                    // setLocalDate(new Date().toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'}));
                                    // setLocalTime(new Date().toLocaleString("en-in", { hour:"numeric", minute: "numeric"}))
                                    handleScheduleNow(dateNow,timeNow)
                                }else{
                                    handleSchedule();
                                }                               
                            }}
                        /> 
                    )}                      
                </div>
            </form>
        </div>
  )
}

export default ScheduleTestCandidate


