import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import emailBody from '../emailBody';
import {Logo,Button, TextInput} from '../components'

const Register = () => {
    document.title = "Talentigo | Register"

    const [companyName, setCompanyName] = useState()
    const [employeeSize, setEmployeeSize] = useState()
    const [contactPersonFirstName, setContactPersonFirstName] = useState()
    const [contactPersonLastName, setContactPersonLastName] = useState()
    const [role, setRole] = useState()
    const [email, setEmail] = useState()
    const [pass, setPass] = useState()
    const [repass, setRepass] = useState()

    const [loading, setLoading] = useState(false)
    const [mailLoading, setMailLoading] = useState()
    const [successModule, setSuccessModule] = useState()

    const [error, setError] = useState()
    const [nameError, setNameError] = useState("");
    const [employeeSizeError, setEmployeeSizeError] = useState("")
    const [contactPersonFirstNameError, setContactPersonFirstNameError] = useState("")
    const [contactPersonLastNameError, setContactPersonLastNameError] = useState("")
    const [roleError, setRoleError] = useState('')
    const [emailError, setEmailError] = useState("")
    const [passError, setPassError] = useState("");
    const [repassError, setRepassError] = useState("");

    const [passType, setPassType] = useState('password')
    const [repassType, setRepassType] = useState('password')
    const navigate = useNavigate();

    useEffect(() => {
        setSuccessModule(document.getElementById('sucessModule'))
    }, [])

    document.addEventListener('keypress',(e)=> {
        if (e.keyCode === 13) {
            handleSubmit()
          }
    })

    const handleSubmit = () =>{
        //reset error messages
        setError();setNameError("");setEmployeeSizeError("");
        setContactPersonFirstNameError("");setContactPersonLastNameError("");
        setRoleError("");setEmailError(""); setPassError(""); setRepassError('');
        setLoading(true)
        // Validations
        const validateEmail = (email) => {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        if(companyName && employeeSize && contactPersonFirstName && contactPersonLastName && role && email && pass && repass){
            setMailLoading(true)
            let companyId = Date.now()
            
            const sendEmail = () => {
                const emailSubject = `Verification Mail`
                const emailMessage = emailBody.verificationEmail(companyName,companyId)
                const hrEmailData = JSON.stringify([email,emailSubject,emailMessage])
                const mailApi = process.env.REACT_APP_EMAIL_API
                const response = axios.post(mailApi,hrEmailData)
                if(response?.data?.message === 'success'){}
                setMailLoading(false)
            }
            const postData = async () =>{
                const sheetData = JSON.stringify([companyId,companyName,email,pass,employeeSize,contactPersonFirstName,contactPersonLastName,role])
                console.log(sheetData)
                const postRegisterApi = process.env.REACT_APP_POST_REGISTER_API
                const response = await axios.post(postRegisterApi,sheetData)
                if(response?.data?.message === 'success'){
                    sendEmail();
                    successModule?.classList.remove('hidden')
                    successModule?.classList.add('flex')
                }else if(response?.data?.message === 'nameExists'){
                    setNameError("Company Name Already Registered")
                }else if(response?.data?.message === 'emailExists'){
                    setEmailError("User Already Registered with this Email Address")
                }
                setLoading(false)
            }

            if(!validateEmail(email)){
                setEmailError("Email Address is invalid")
                setLoading(false)
            }else{
                postData()
            }
        }else {
            if(!companyName){
                setNameError('Please fill the company name')
            } if( !employeeSize ){
                setEmployeeSizeError('Please select company employee size')
            } if( !contactPersonFirstName) {
                setContactPersonFirstNameError('Please fill the contact person details')
            } if( !contactPersonLastName) {
                setContactPersonLastNameError('Please fill the contact person details')
            } if (!role){
                setRoleError('Please fill the role')
            } if( !email ){
                setEmailError('Please fill the company Official email id')
            } else if(!validateEmail(email)){
                setEmailError("Email Address is invalid")
            } if (!pass){
                setPassError('Please fill the Password')
            } if (!repass) {
                setRepassError('Please reenter the password')
            } if(pass !== repass){
                setRepassError('Password do not match')
            }
            setLoading(false)
        }
    }

    const registerOk = () => {
        successModule?.classList.remove('flex')
        successModule?.classList.add('hidden')
        navigate('/admin')
    }

  return (
    <div className='admin-main'>
        <div className="register-card flex justify-center items-center h-full sm:py-5 w-full gradient2"> 
            <div className='relative overflow-hidden bg-white sm:rounded-[25px] w-full flex flex-col justify-center items-center p-7 sm:px-14 md:px-20 gap-2'>
            <div className='ribbon-box'>Beta Version</div>       
                <div id='sucessModule' className='hidden bg-[#000]/10 backdrop-blur-[2px] z-10 h-full w-full absolute items-center justify-center'>
                    <div className='card flex flex-col items-center justify-center gap-5 p-10'>
                        {
                            (mailLoading) ? (
                                <p className='font-extrabold text-xl text-[#888]'>Loading . . .</p>
                            ) : (
                                <>
                                    <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                                        <p className='text-white text-4xl'>✓</p> 
                                    </div>
                                    <h1 className='text-xl font-medium'>You are successfully registered !</h1>
                                    <div className='flex flex-col items-center justify-center gap-2'>
                                         <p className='text-center'>
                                            We have send you verification email. 
                                        </p>
                                        <p>
                                            Please check and follow the link in the mail.
                                        </p>
                                    </div>
                                   
                                    <Button
                                        text='OK'
                                        width='10rem'
                                        handleClick={registerOk}
                                    />                                    
                                </>
                            )
                        }
                    </div>
                </div>
                <Logo/>
                <form className="flex flex-col gap-2 w-full">  
                    <h1>Get started Free.<span>Account creation is specifically intended to organizations such as companies, recruiters, colleges, and schools or institutes.</span></h1>
                    
                    <div className='flex form-row gap-6'>
                        <div className="item-left flex flex-col">
                            <label htmlFor="companyName" className="font-medium text-sm leading-[12px] mb-1">
                                Company Name<sup>*</sup>
                            </label>
                            <TextInput
                                type="text"
                                placeholderText="Enter your Company Name"
                                inputName="companyName"
                                isRequired = {true}
                                inputValue={companyName}
                                handleChange={(e) => setCompanyName(e.target.value)}
                            />
                            {
                                <div className="font-medium text-[11px] leading-3 h-3 text-[#f00] mt-1">
                                    {nameError}
                                </div>
                            }
                        </div>
                        <div className="item-right flex flex-col">
                            <label htmlFor="employeeSize" className="font-medium text-sm leading-[12px] mb-1">
                                Company Employee Size<sup>*</sup>
                            </label>
                            <TextInput
                                type="select"
                                placeholderText="Select Employee Size"
                                inputName="employeeSize"
                                isRequired = {true}
                                inputValue={employeeSize}
                                selectOptions={
                                    ['0-1 Employees',
                                        '2-10 Employees',
                                        '11-50 Employees',
                                        '51-200 Employees',
                                        '201-500 Employees',
                                        '501-1000 Employees',
                                        '1000+ Employees'
                                    ]}
                                handleChange={(e) => setEmployeeSize(e.target.value)}
                            />
                            {
                                <div className="font-medium text-[11px] leading-3 h-3 text-[#f00] mt-1">
                                    {employeeSizeError}
                                </div>
                            }
                        </div>
                    </div>

                    <div className='flex form-row gap-6'>
                        <div className="item-left flex flex-col">
                            <label htmlFor="contactPersonFirstName" className="font-medium text-sm leading-[12px] mb-1">
                                Contact Person First Name<sup>*</sup>
                            </label>
                            <TextInput
                                type="text"
                                placeholderText="eg.John"
                                inputName="contactPersonFirstName"
                                isRequired = {true}
                                inputValue={contactPersonFirstName}
                                handleChange={(e) => setContactPersonFirstName(e.target.value)}
                            />
                            {
                                <div className="font-medium text-[11px] leading-3 h-3 text-[#f00] mt-1">
                                    {contactPersonFirstNameError}
                                </div>
                            }
                        </div>
                        <div className="item-right flex flex-col">
                            <label htmlFor="contactPersonLastName" className="font-medium text-sm leading-[12px] mb-1">
                                Contact Person Last Name<sup>*</sup>
                            </label>
                            <TextInput
                                type="text"
                                placeholderText="eg.Matthew"
                                inputName="contactPersonLastName"
                                isRequired = {true}
                                inputValue={contactPersonLastName}
                                handleChange={(e) => setContactPersonLastName(e.target.value)}
                            />
                            {
                                <div className="font-medium text-[10px] leading-3 h-3 text-[#f00] mt-1">
                                    {contactPersonLastNameError}
                                </div>
                            }
                        </div>
                    </div>                    

                    <div className='flex form-row gap-6'>
                        <div className="item-left flex flex-col">
                            <label htmlFor="role" className="font-medium text-sm leading-[12px] mb-1">
                                Role<sup>*</sup>
                            </label>
                            <TextInput
                                type="text"
                                placeholderText="eg.HR Executive"
                                inputName="role"
                                isRequired = {true}
                                inputValue={role}
                                handleChange={(e) => setRole(e.target.value)}
                            />
                            {
                                <div className="font-medium text-[10px] leading-3 h-3 text-[#f00] mt-1">
                                    {roleError}
                                </div>
                            }
                        </div>
                        <div className="item-left flex flex-col">
                            <label htmlFor="companyEmail" className="font-medium text-sm leading-[12px] mb-1">
                                Official Email ID<sup>*</sup>
                            </label>
                            <TextInput
                                type="email"
                                placeholderText="eg.johnmatthew@gmail.com"
                                inputName="companyEmail"
                                isRequired = {true}
                                inputValue={email}
                                handleChange={(e) => setEmail(e.target.value)}
                            />
                            {
                                <div className="font-medium text-[10px] leading-3 h-3 text-[#f00] mt-1">
                                    {emailError}
                                </div>
                            }
                        </div>
                    </div>

                    <div className='flex form-row gap-6'>
                        <div className="item-left flex flex-col password-field">
                            <label htmlFor="password" className="font-medium text-sm leading-[12px] mb-1">
                                Enter Password<sup>*</sup>
                            </label>
                            
                            <TextInput
                                type={passType}
                                placeholderText="Enter your Password"
                                inputName="password"
                                idName='password'
                                isRequired={true}
                                inputValue={pass}
                                handleChange={(e) => setPass(e.target.value)}
                            />                            
                            {
                                <div className="font-medium text-[10px] leading-3 h-3 text-[#f00] mt-1">
                                    {passError}
                                </div>
                            }
                            <img
                                src={`./img/eye-${passType === 'text' ?  'open' : 'close' }.png`}
                                alt = 'eye icon'
                                onClick={() => 
                                    passType === 'password' 
                                    ? setPassType('text')
                                    : setPassType('password')
                                }
                            />
                        </div>
                        <div className="item-right flex flex-col password-field">
                            <label htmlFor="password" className="font-medium text-sm leading-[12px] mb-1">
                                Re-Enter Password
                            </label>                            
                            <TextInput
                                type={repassType}
                                placeholderText="Re-enter your Password"
                                inputName="repassword"
                                idName='repassword'
                                isRequired={true}
                                inputValue={repass}
                                handleChange={(e) => setRepass(e.target.value)}
                            />                               
                            {
                                <div className="font-medium text-[10px] leading-3 h-3 text-[#f00] mt-1">
                                    {repassError}
                                </div>
                            }
                            <img
                                src={`./img/eye-${repassType === 'text' ?  'open' : 'close' }.png`}
                                alt = 'eye icon'
                                onClick={() => 
                                    repassType === 'password' 
                                    ? setRepassType('text')
                                    : setRepassType('password')
                                }
                            />
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center mt-4'>
                        {error && 
                        <div className=" mb-2 w-full flex items-center justify-center font-medium text-sm leading-3 h-3 text-[#f00] mt-1">
                            {error}
                        </div>}
                        {loading ? (
                            <div className='text-[#ccc] font-semibold text-center'>
                                Please Wait ...
                            </div>
                        ) : (
                            <Button
                                version="primary"
                                text="Register"
                                type="button"
                                handleClick={handleSubmit}
                                width="15rem"
                            />
                        )}
                    </div>
                </form>
                <div className='mt-3 flex flex-col justify-center items-center gap-2'>
                    <p className='text-sm'>OR</p>
                    <div className='flex justify-center gap-1 w-full'>
                        Already have an account?
                        <Link
                            to='/admin'
                            className='font-medium hover:underline'
                        >
                            Login
                        </Link>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Register
