import React from 'react';

import {Button, RadioInput} from '../../components';

const ExamOptionsPanel = ({handleNext, questions, qusNum, alertSkip, loading}) => {

    const submitAnswer = (e) => {
        e.preventDefault();

        const form = document.getElementById("examForm")
        let userAnswer = form[`q${qusNum+1}`]?.value

        if (userAnswer === ""){
            alertSkip();
		}else {
            if(qusNum + 1 >= questions?.length){
                e.preventDefault();
                document.getElementById("last").classList.add("block");
                document.getElementById("last").classList.remove("hidden");
                document.getElementById("next").style.display = "none";
            }
            handleNext();
        }
    }

    return (
        <div className='w-[50%] px-14 h-full flex flex-col justify-between'>
            { loading ? (
                <div className='w-full h-full flex justify-center items-center'>
                    <p className='font-bold text-2xl text-[#bbb]'>
                        Loading...
                    </p>
                </div>          
            ) : (
                <>  <p className='font-medium text-[#333] mb-3'>
                        Select any one -
                    </p>
                    <div className='w-full overflow-y-auto scrollbar-none mb-3'>
                        {questions[qusNum] && 
                            <div className='flex flex-col gap-5'>
                                <form method="post" id="examForm" className='flex flex-col gap-4'>
                                    <RadioInput
                                        inputName={`q${qusNum+1}`}
                                        inputValue='A'
                                        iniputId={`q${qusNum+1}A`}
                                        assessmentInput={true}
                                        text={
                                            <pre>
                                                {questions[qusNum]?.optionA}
                                            </pre>
                                        }
                                    />
                                    <RadioInput
                                        inputName={`q${qusNum+1}`}
                                        inputValue='B'
                                        iniputId={`q${qusNum+1}B`}
                                        assessmentInput={true}
                                        text={
                                            <pre>
                                                {questions[qusNum]?.optionB}
                                            </pre>
                                        }
                                    />
                                    <RadioInput
                                        inputName={`q${qusNum+1}`}
                                        inputValue='C'
                                        iniputId={`q${qusNum+1}C`}
                                        assessmentInput={true}
                                        text={
                                            <pre>
                                                {questions[qusNum]?.optionC}
                                            </pre>
                                        }
                                    />
                                    <RadioInput
                                        inputName={`q${qusNum+1}`}
                                        inputValue='D'
                                        iniputId={`q${qusNum+1}D`}
                                        assessmentInput={true}
                                        text={
                                            <pre>
                                                {questions[qusNum]?.optionD}
                                            </pre>
                                        }
                                    />
                                </form>                        
                            </div>                        
                        }
                    </div>
                
                    <div>
                        <Button
                            text="Submit & Next"
                            inputId="next"
                            handleClick={submitAnswer}
                        />
                        
                        <div id="last" className='text-[#f60] font-medium text-center mx-auto hidden'> 
                            This is the last Question <br/>
                            Please press "Finish Test" Button at the top,
                            To submit your Assessment.
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ExamOptionsPanel
