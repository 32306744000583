import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import ProfileActions from './ProfileActions'

const ProfileLeftPanel = ({loading,dataSheetId,adminName,adminEmail, candidateSchedule, candidateResult,candidateApplication, openUpdateModule,openDeleteConfirmationModule,openResume,setResumeUrl,setUpdateResults}) => {
    
    useEffect(() => {
        if (candidateApplication?.resumeUrl){
            let url = candidateApplication.resumeUrl.split('view?')
            url[1] = 'preview'
            setResumeUrl(url.join(''))
        }
    },[candidateApplication])

    const [resultCounter, setResultCounter] = useState(0) ;
    const resultColor = candidateResult?.Score < 40 ? "#E5383B" : candidateResult?.Score < 70 ? "#FFB800" : "#57AD55"
    useEffect(() => {      
        let count = 0;  
        candidateResult && setInterval(()=> {            
            if( count < candidateResult.Score){
                count = count+ 1
                setResultCounter(count)
            }else{
                count = candidateResult.Score
            }      
        },[20])
    },[candidateResult])

    return (
    <div className='card h-full w-full lg:w-[30%] py-6 px-8 flex flex-col justify-between gap-4 overflow-y-scroll scrollbar-none'>
        {loading ? 
            (
                <div className='flex flex-col gap-4 justify-center'>
                    <div className='flex  bg-[#888]/10 w-1/2 h-[1.7rem] rounded-xl self-center'/>
                    <div className='flex  bg-[#999]/10 w-3/4 h-[1.5rem] rounded-xl'/>
                    <div className='flex  bg-[#aaa]/10 w-3/4 h-[1.5rem] rounded-xl'/>
                    <div className='flex  bg-[#bbb]/10 w-3/4 h-[1.5rem] rounded-xl'/>
                    <div className='flex  bg-[#ccc]/10 w-3/4 h-[1.5rem] rounded-xl'/>
                    <div className='flex  bg-[#ddd]/10 w-3/4 h-[1.5rem] rounded-xl'/>
                    <div className='flex  bg-[#eee]/10 w-3/4 h-[1.5rem] rounded-xl'/>  
                    <div className='flex  bg-[#bbb]/10 w-3/4 h-[4rem] rounded-xl mt-10'/>  

                </div>
            ) : (
                <div className='flex flex-col gap-2'>                
                    <h1 className='flex sm:hidden lg:flex justify-center font-bold uppercase text-xl lg:text-lg mb-2'>
                        {candidateApplication ? candidateApplication.name : candidateSchedule?.Name}
                    </h1>                
                
                    <div className='flex w-full items-center gap-3 lg:gap-3 sm:gap-5 sm:justify-center lg:justify-start'>
                        {/* <div className='rounded-xl overflow-hidden' >
                            <img src="img/adminProfile.png" className='w-40' alt="" />
                        </div> */}

                        <div className='w-full flex flex-col justify-evenly sm:items-center lg:items-start gap-3 md:gap-4 h-full text-sm lg:text-sm'>
                            <h1 className='hidden sm:flex lg:hidden justify-center font-bold uppercase text-xl lg:text-lg'>
                                {candidateApplication ? candidateApplication.name : candidateSchedule?.Name}
                            </h1>
                        
                            <div className='flex items-center gap-2'>
                                <div className='w-6 h-6 bg-[#9160D1]/10 text-[#9160D1]" rounded-md flex justify-center items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='flex flex-col sm:flex-row lg:flex-col'>
                                    <span>{candidateApplication ? candidateApplication.email : candidateSchedule?.Email}</span>
                                        {/* <span>{candidateSchedule?.Email.split('@')[0]}</span>
                                        <span>@{candidateSchedule?.Email.split('@')[1]}</span> */}
                                    </p>
                                </div>
                            </div>
                            <div className='flex items-center capitalize gap-3'>
                                <div className='w-6 h-6 bg-[#FFB800]/10 text-[#FFB800] rounded-md flex justify-center items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                                    </svg>
                                </div>
                                {candidateApplication ? candidateApplication.profile : candidateSchedule?.ApplyFor}
                            </div>
                            {
                                candidateApplication && (
                                    <div className='flex items-center capitalize gap-3'>
                                        <div className='w-6 h-6 bg-[#0066FF]/10 text-[#0066FF] rounded-md flex justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                            </svg>
                                        </div>
                                        {candidateApplication.phone}
                                    </div>
                                )
                            }                 
                        </div>
                    </div>
                    {candidateApplication?.resumeUrl &&
                        <div className='flex sm:justify-center lg:justify-start gap-4 mt-3'>
                            {/* <div className='bg-[#9160D1]/10 text-[#9160D1] text-sm rounded-md flex gap-2 cursor-not-allowed justify-center items-center px-4 py-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                View Full Profile
                            </div> */}
                            
                            <div
                                className='relative bg-[#FE823D]/10 text-[#FE823D] cursor-pointer text-sm rounded-md flex gap-2 justify-center items-center px-3 py-1'
                                onClick={openResume}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                View Resume
                            </div>
                        </div>
                    }          
                
                    
                    <div className='flex flex-col items-start lg:items-start sm:items-center gap-2 font-medium mt-6 lg:mt-4 text-base lg:text-sm'>
                        {candidateSchedule?.inviteSchedule === 'invite' ?
                            <h1 className=' font-semibold'>Invite Sent :</h1>
                            :
                            <h1 className=' font-semibold'>Assessment Scheduled :</h1>
                        }
                        
                        {candidateSchedule ? (
                            <div className='flex items-center gap-6 mb-2 text-sm lg:text-xs'>
                                <div className='flex items-center gap-3'>
                                    <div className='w-6 h-6 bg-[#4BD682]/10 rounded-md flex justify-center items-center text-[#4BD682]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                        </svg>
                                    </div>
                                    <span>{new Date(candidateSchedule?.Date)?.toLocaleDateString("en-in",{day: 'numeric' ,month : 'short', year: 'numeric'})}</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <div className='w-6 h-6 bg-[#0066FF]/10 rounded-md flex justify-center items-center text-[#0066FF]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <span>{new Date(candidateSchedule?.Time)?.toLocaleTimeString("en-in",{hour:'numeric', minute:'numeric'})}</span> 
                                </div>  
                            </div>
                        ) : (
                            <div className='flex items-center gap-3 text-sm lg:text-xs'>
                                <div className='w-6 h-6 bg-[#EA4335]/10 rounded-md flex justify-center items-center text-[#EA4335]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <span>Not Scheduled</span>
                            </div>
                        )}                    
                        
                        {candidateResult || candidateSchedule?.Attempt === 1 ? 
                            <h1 className='font-semibold mt-3'>Assessment Attempted :</h1>
                            :
                            <h1 className='font-semibold mt-3'>Assessment Expiry :</h1>
                        }
                        {candidateResult ? (
                            <div className='flex items-center gap-6 mb-2 text-sm lg:text-xs'>
                                <div className='flex items-center gap-3'>
                                    <div className='w-6 h-6 bg-[#4BD682]/10 rounded-md flex justify-center items-center text-[#4BD682]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                        </svg>
                                    </div>
                                    <span>{new Date(candidateResult?.Date)?.toLocaleDateString("en-in",{day: 'numeric' ,month : 'short', year: 'numeric'})}</span>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <div className='w-6 h-6 bg-[#0066FF]/10 rounded-md flex justify-center items-center text-[#0066FF]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <span>
                                        {
                                            candidateResult?.Time ?
                                            new Date(candidateResult?.Time)?.toLocaleTimeString("en-in",{hour:'numeric', minute:'numeric'}) :
                                            new Date(candidateResult?.StartTime)?.toLocaleTimeString("en-in",{hour:'numeric', minute:'numeric'})
                                        }
                                    </span> 
                                </div>  
                            </div>
                        ) : (
                            candidateSchedule?.Attempt === 1 ? (
                                <div className='flex items-center gap-3 text-sm lg:text-xs'>
                                    <div className='w-6 h-6 bg-[#FFB800]/10 rounded-md flex justify-center items-center text-[#FFB800]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <span>Attempted but not finished or submitted the assessment.</span>
                                </div>
                            ) : (
                                <div className='flex items-center gap-6 mb-2 text-sm lg:text-xs'>
                                    <div className='flex items-center gap-3'>
                                        <div className='w-6 h-6 bg-[#4BD682]/10 rounded-md flex justify-center items-center text-[#4BD682]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                            </svg>
                                        </div>
                                        <span>{new Date(candidateSchedule?.expireDateTime)?.toLocaleDateString("en-in",{day: 'numeric' ,month : 'short', year: 'numeric'})}</span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-6 h-6 bg-[#0066FF]/10 rounded-md flex justify-center items-center text-[#0066FF]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                        <span>{new Date(candidateSchedule?.expireDateTime)?.toLocaleTimeString("en-in",{hour:'numeric', minute:'numeric'})}</span> 
                                    </div>  
                                </div>
                            )                            
                        )}                   
                    </div>
                    
                    {candidateResult && (
                        <div className='my-4 mt-10 flex flex-col gap-4 items-center font-semibold'>
                            <motion.div
                                className={`resultProgressBar relative text-[${resultColor}]`}
                                data-progress={candidateResult?.Score === 'NaN' ? 0 : resultCounter}
                                style={{'--progress' : `${resultCounter*3.6}deg`, '--progressColor' : resultColor}}
                            >
                                {/* <div className={`absolute top-0 right-14 w-3 h-3 rounded-full bg-[${resultColor}]`} />
                                <div className={`absolute w-3 left-[50%] translate-x-[-50%] h-[100%] rounded-full rotate-[${Math.floor(resultCounter*3.6)}deg]`}>
                                    <div className={`w-3 h-3 rounded-full bg-[${resultColor}]`}/>
                                </div> */}
                            </motion.div>
                            <p className=''>Overall performance</p>
                        </div>                    
                    )}
                </div> 
            )
        }    
        {loading ? 
            (   <div className='flex flex-col gap-4 justify-center items-center'>
                    <div className='flex  bg-[#aaa]/10 w-3/4 h-[2rem] rounded-xl'/>  
                    <div className='flex  bg-[#aaa]/10 w-3/4 h-[2rem] rounded-xl'/>  
                </div>
            ) : 
            (
            <ProfileActions
                dataSheetId={dataSheetId}
                adminName={adminName}
                adminEmail={adminEmail}
                candidateSchedule={candidateSchedule}
                candidateResult={candidateResult}
                openUpdateModule={openUpdateModule}
                openDeleteConfirmationModule={openDeleteConfirmationModule}
                setUpdateResults={setUpdateResults}
            />    
            )}
              
    </div>
  )
}

export default ProfileLeftPanel
