import React,{useState,useEffect} from 'react'
import { motion } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { Sidebar,Topbar3 } from '../../components'
import {Dashboard, Main, CandidateProfile, Notifications, AdminProfile,Settings,QuestionUpload,QuestionBank,AssessmentType,Archive,Jobs,NewJob} from ".."

const Admin = ({page,tab}) => {

  useEffect(()=>{
    document.title = `Talentigo | ${page.slice(0,1).toUpperCase()+page.slice(1)}`
  },[page])
  
  
  // states
  const [adminName, setSetAdminName] = useState()
  const [adminEmail, setSetAdminEmail] = useState()
  const [loginTime, setLoginTime] = useState()
  const [dataSheetId, setDataSheetId] = useState()
  const [questionSheetId, setQuestionSheetId] = useState()
  const [sideBarOpen, setSideBarOpen] = useState('none')
  const [screen , setScreen] = useState()

  const [isprofileMenuOpen, setIsprofileMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const navigate = useNavigate();

  const closePopupMenu = () => {
    setIsprofileMenuOpen(false)
    setIsNotificationOpen(false)
    setSearchOpen(false)
  }
  
  //find screen size
  useEffect(() => {
    window.innerWidth > 767 && setScreen('large')
    window.innerWidth > 767 && setSideBarOpen('small')    
    window.addEventListener('resize', () => {
      window.innerWidth > 767 ? setSideBarOpen('small') : setSideBarOpen('none');
      window.innerWidth > 767 ? setScreen('large') : setScreen('small');
    })    
  },[])

  const [schedules, setSchedules] = useState([])
  const [allSchedules, setAllSchedules] = useState([])
  const [totalShedules, setTotalSchedules] = useState()
  const [scheduleFilter, setScheduleFilter] = useState('all')
  const [updateSchedules, setUpdateSchedules] = useState(0)
  const [schedulesLoading, setSchedulesLoading] = useState(true)

  const [results, setResults] = useState([]); 
  const [allResults, setAllResults] = useState([])
  const [totalResults, setTotalResults] = useState()
  const [resultFilter, setResultFilter] = useState('all')
  const [updateResults, setUpdateResults] = useState(0)
  const [resultsLoading, setResultsLoading] = useState(true)

  const [applications, setApplications] = useState([]);
  const [allApplications, setAllApplications] = useState();
  const [totalApplications, setTotalApplications] = useState()
  const [applicationsLoading, setApplicationsLoading] = useState(true)
  
  const [jobs, setJobs] = useState();
  const [updateJobs, setUpdateJobs] = useState(0)
  const [ jobsLoading, setJobsLoading] = useState(true) 
  
  const [notifications, setNotifications] = useState();  
  const [updateNotifications, setUpdateNotifications] = useState(0)
  const [notificationsLoading, setNotificationsLoading] = useState(true)

  const [assessmentNames, setAssessmentNames] = useState([])
  const [updateAssessmentTypes, setUpdateAssessmentTypes] = useState(0)
  const [assessmentNamesLoading, setAssessmentNamesLoading] = useState(true)

  //fetching data
  useEffect(() => { 
    const loginData = JSON.parse(localStorage.getItem('adminLogin'));
    if (loginData) {
      setSetAdminName(loginData?.name);
      setSetAdminEmail(loginData?.email)
      setDataSheetId(loginData?.dataSheetId)
      setQuestionSheetId(loginData?.questionSheetId)
      setLoginTime(loginData?.loginTime)
    }    
  },[])
  
  useEffect(() => {
    let loginWindow = 48*60*60*1000
    if (Date.now() - loginTime > loginWindow){
      localStorage.removeItem("adminLogin");
      window.location.href = '/admin';
    }else{
      const updateTime = () => {
        const loginData = JSON.parse(localStorage.getItem('adminLogin'));
        loginData.loginTime = Date.now();
        localStorage.setItem('adminLogin', JSON.stringify(loginData));
      }
      updateTime()
    }
  },[loginTime,adminEmail,page])

const fetchapi = process.env.REACT_APP_FETCH_API

//fetching schedules
useEffect(() => {
  if(dataSheetId){
    const fetchSchedules = async () => {
      setSchedulesLoading(true)
      const scheduleData = JSON.stringify([dataSheetId, 'schedules',1,50,scheduleFilter])
      const scheduleResponse =  await axios.post(fetchapi,scheduleData);
      setSchedules(scheduleResponse.data.records)
      setTotalSchedules(scheduleResponse.data.totalRecords)
      setAllSchedules(scheduleResponse.data.allData)
      setTimeout(() => setSchedulesLoading(false),100)
    }
    fetchSchedules()
  }
},[updateSchedules,scheduleFilter,dataSheetId])

// fetching results
useEffect(() => {
  if(dataSheetId){
    const fetchResults = async () => {
     setResultsLoading(true)
      const resultData = JSON.stringify([dataSheetId, 'results',1,50,resultFilter])
      const resultResponse =  await axios.post(fetchapi,resultData);
      setResults(resultResponse.data.records)
      setAllResults(resultResponse.data.allData)
      setTotalResults(resultResponse.data.totalRecords)
      setTimeout(() => setResultsLoading(false),100)
    }
    fetchResults()
  }
},[updateResults,resultFilter,dataSheetId])

// fetching applications
// useEffect(() => {
//   if(dataSheetId){
//     const fetchApplications = async () => {
//       setApplicationsLoading(true)
//       const applicationsData = JSON.stringify([dataSheetId, 'applications',1,20])
//       const applicationResponse =  await axios.post(fetchapi,applicationsData);
//       setApplications(applicationResponse.data.records);
//       setAllApplications(applicationResponse.data.allData)
//       setTotalApplications(applicationResponse.data.totalRecords)
//       setApplicationsLoading(false)
//     }
//     fetchApplications()
//   }
// },[dataSheetId])

useEffect(() => {
  if(dataSheetId){
    const fetchNotifications = async () => {
      setNotificationsLoading(true)
      const notificationData = JSON.stringify([dataSheetId,'notifications'])
      const notificationtResponse =  await axios.post(fetchapi,notificationData);
      setNotifications(notificationtResponse.data.records)
      setNotificationsLoading(false)
    }
    fetchNotifications()
  }
},[updateNotifications,dataSheetId])

useEffect(() => {
  if(questionSheetId){
    const fetchQuestionSheets = async () => {
      setAssessmentNamesLoading(true)
      const sheets = await axios.post(process.env.REACT_APP_FETCH_SHEETS,questionSheetId)
      const data = sheets?.data.flat(1)
      const sheetNames = data.filter(element => !element.startsWith('inactive_') && !element.startsWith('Sheet'))
      setAssessmentNames(sheetNames); 
      setAssessmentNamesLoading(false)
    }
    fetchQuestionSheets()
  }
},[questionSheetId,updateAssessmentTypes])

  // useEffect(() => {
  //   if(dataSheetId){
  //     const fetchJobs = async () => {
  //       setJobsLoading(true)
  //       const jobsData = JSON.stringify([dataSheetId,'jobs'])
  //       const jobsResponse =  await axios.post(fetchapi,jobsData);
  //       setJobs(jobsResponse.data.records)
  //       setJobsLoading(false) 
  //     }
  //     fetchJobs()
  //   }
  // },[dataSheetId,updateJobs])  

  return (
    <div className="w-full pb-5">
  
      {/* Topbar  */}
      <div className='header-bar sticky top-0 left-0 z-20 bg-gray-200/30 lg:bg-transparent backdrop-blur-md lg:backdrop-blur-0 drop-shadow-md lg:drop-shadow-none'>
        <Topbar3
          name={adminName}
          adminEmail={adminEmail}
          page={page}
          candidates={allSchedules}
          applications={allApplications}
          notifications={notifications}
          notificationsLoading={notificationsLoading}
          sideBarOpen={sideBarOpen}
          setSideBarOpen={setSideBarOpen}
          isprofileMenuOpen={isprofileMenuOpen}
          setIsprofileMenuOpen={setIsprofileMenuOpen}
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
          dataSheetId={dataSheetId}
          isSearchOpen={isSearchOpen}
          setSearchOpen={setSearchOpen}
          setUpdateNotifications = {setUpdateNotifications}
        />
      </div>

      {/* Sidebar  */}
      <div className='absolute top-0 left-0 w-full h-full'>
        <div className='h-full w-full relative'>
          <motion.div
            className={`absolute z-20 top-0 left-0 w-full h-full bg-[#000]/10 transition-all ${sideBarOpen === 'open' ? 'flex' : 'hidden'}`}
            onClick = {() => setSideBarOpen(screen === 'large' ? ( sideBarOpen === 'small' ? 'open' : 'small') : (sideBarOpen === 'none' ? 'open' : 'none'))}
            variants={{
              initial: { opacity: 0},
              final: { opacity : 1 }
            }}
            initial="small"
            transition={{ duration: 0.3}}
            animate={sideBarOpen === 'open' ? "final" : "initial"}
          />
          <Sidebar
            page={page}
            sideBarOpen={sideBarOpen}
            setSideBarOpen={setSideBarOpen}
            screen={screen}
          />
        </div>
      </div>
     
      <div
        onClick={closePopupMenu}
      >
        <div className='mt-4 flex sm:hidden items-center w-[90vw] mx-auto'>
          { page !== 'dashboard' && page !=='schedules' && page !=='results' && (
            <div onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor"
                className="w-8 h-8 cursor-pointer"
              >
                  <path fillRule="evenodd" d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z" clipRule="evenodd" />
              </svg>
            </div>
          )}
          <h1 className="heading1">{page}</h1>
        </div>

        {/* Dashboard  */}
        {page === "dashboard" && (
            <Dashboard 
              candidates={allSchedules}
              results={allResults}
              loading={resultsLoading || schedulesLoading}
            />
        )}

        {/* applictions Page  */}
        {page === "applications" && (
          <div>
            <Main
              tab={tab}
              adminName={adminName}
              adminEmail={adminEmail}
              dataSheetId={dataSheetId}
              screen={screen}
              assessmentNames={assessmentNames}
              
              schedulesLoading={schedulesLoading}
              scheduleFilter={scheduleFilter}
              setScheduleFilter ={setScheduleFilter}
              schedules={schedules}
              totalShedules={totalShedules}              
              setUpdateSchedules={setUpdateSchedules}

              resultsLoading={resultsLoading}
              results={results}
              resultFilter={resultFilter}
              setResultFilter={setResultFilter}
              totalResults={totalResults}

              applicationsLoading={applicationsLoading}
              applications= {applications}
              totalApplications={totalApplications}
            />
          </div>
        )}

        {/* Candidate Profile Page  */}
        {page === "candidate profile" && (
          <div>
            <CandidateProfile
              loading={resultsLoading || schedulesLoading}
              adminName={adminName}
              adminEmail={adminEmail}
              dataSheetId={dataSheetId}
              questionSheetId={questionSheetId}
              schedules = {allSchedules}
              results = {allResults}
              applications={allApplications}
              assessmentNames={assessmentNames}
              setUpdateSchedules={setUpdateSchedules}
              setUpdateResults={setUpdateResults}
            />
          </div>
        )}

        {/* jobs page  */}
        {page === "jobs" && (
          <div>
            <Jobs
              jobs={jobs}
              jobsLoading={jobsLoading}
              dataSheetId={dataSheetId}
              setUpdateJobs={setUpdateJobs}
            />
          </div>
        )}

        {page === "new job" && (
          <div>
            <NewJob
              dataSheetId={dataSheetId}
            />
          </div>
        )}

        {/* question bank Page  */}
        {page === "question bank" && (
          <div>
            <QuestionBank
              assessmentNames={assessmentNames}
              questionSheetId={questionSheetId}
              assessmentNamesLoading={assessmentNamesLoading}
            />
          </div>
        )}  

        {/* question upload Page  */}
        {page === "question upload" && (
          <div>
            <QuestionUpload
              assessmentNames={assessmentNames}
              questionSheetId={questionSheetId}
              assessmentNamesLoading={assessmentNamesLoading}
            />
          </div>
        )}   

        {/* assessment type Page  */}
        {page === "assessment type" && (
          <div>
            <AssessmentType
              assessmentNames={assessmentNames}
              questionSheetId={questionSheetId}
              assessmentNamesLoading={assessmentNamesLoading}
              setUpdateAssessmentTypes={setUpdateAssessmentTypes}
            />
          </div>
        )} 

        {/* archive Page  */}
        {page === "archive" && (
          <div>
            <Archive
              tab={tab}
              candidates={allSchedules.filter(candidate => candidate.Attempt === -1 || candidate.Attempt === -2)}
              schedulesLoading={schedulesLoading}
            />
          </div>
        )} 

        {/* Admin Profile  */}
        {page === "admin profile" && (
          <div>
            <AdminProfile
              adminName={adminName}
              adminEmail={adminEmail}
            />
          </div>
        )} 

        {/* Admin Settings  */}
        {page === "settings" && (
          <div>
            <Settings
              adminEmail={adminEmail}
            />
          </div>
        )} 

        {/* Notification Page  */}
        {page === "notifications" && (
          <div>
            <Notifications
              notificationsLoading={notificationsLoading}
              notifications={notifications}
              schedules={allSchedules}
              schedulesLoading={schedulesLoading}
            />
          </div>
        )}   
      </div>
    </div>
  )
}

export default Admin