import React, { useState, useEffect } from 'react'
import axios from 'axios'

import UploadCV from './UploadCV'
import Topbar2 from '../../components/Bars/Topbar2'
import { TextInput, Button } from '../../components'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { LINKEDIN_CLIENT_ID,REDIRECT_URI,SCOPE,constgetLinkedInUserInfoFromAccessCode } from '../../constant/Constants'
import ScheduleTestCandidate from './ScheduleTestCandidate'
import InputMask from 'react-input-mask';
const CandidateApplyForm = () => {

    const [step, setStep] = useState(1)
    const [uid, setUid] = useState()
    const [fName, setFName] = useState("")
    const [fNameError, setFNameError] = useState("")
    const [lName, setLName] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [phoneNoError, setPhoneNoError] = useState()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    const [location, setLocation] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [jobTitleError, setJobTitleError] = useState("")
    const [expSalary, setExpSalary] = useState()
    const [eduQulification, setEduQulification] = useState()
    const [institution, setInstitution] = useState()
    const [passout, setPassout] = useState()
    const [marks, setMarks] = useState()
    const [experiance, setExperiance] = useState("")
    const [experianceError, setExperianceError] = useState("")
    const [pastCompany, setPastCompany] = useState()
    const [lastCTC, setLastCTC] = useState()
    const [fromDate, setFromDate] = useState()
    const [formatedFromDate, setFormatedFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [formatedtoDate, setFormatedToDate] = useState()
    const [currentlyWorking,setCurrentlyWorking] = useState(false)
    const [resumeUrl, setResumeUrl] = useState ()
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [resumeUploading, setResumeUploading] = useState(false);

    const [newScheduleModuleOpen, setNewScheduleModule] = useState(false)
    const [scheduleNowLaterDialog, setscheduleNowLaterDialog] = useState(false)
    const [adminName, setadminName] = useState()
    const [adminEmail, setadminEmail] = useState()
    const [dataSheetId, setdataSheetId] = useState()
    const [assessmentNames, setassessmentNames] = useState([])
    const [isFromScheduleNow, setisFromScheduleNow] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const companyName = params.get('key');

    const getLinkedInUserInfoFromAccessCode = async (accessCode) => {
        fetch(constgetLinkedInUserInfoFromAccessCode, {
            method: 'POST',
            body: JSON.stringify({
                accessCode: accessCode }),
            headers:{
              'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then(response =>{
            var fname = response.user.userInfo.localizedFirstName;
            var lname = response.user.userInfo.localizedLastName;
            var email = response.user.email.emailAddress;
            setFName(fname);
            setLName(lname);
            setEmail(email);
        }).catch(error => console.error('Error:', error));
    }

    const [skills, setSkills] = useState([])
    const addSkill = () => {
        const newSkills = [...skills]
        newSkills.push("")
        setSkills(newSkills)
    }

    const [projects, setProjects] = useState([])
    const addProject = () => {
        const newProjects = [...projects];
        newProjects.push({});
        setProjects(newProjects);
    }

    const handleNext = () => {
        if (step !== 3){
            setStep(step + 1)
        }
    }

    const [linkedinProfile, setLinkedInProfile] = useState(false)
    useEffect(() => {
        const firstName = params.get('firstName');
        const lastName = params.get('lastName');
        const email = params.get('email');
        
        setFName(firstName);
        lastName && setLName(lastName);
        setEmail(email);
        setLinkedInProfile(params.get('isLinkedInProfile'));       
    },[]);

    const getAdminDetails = async () => {
        setLoading(true)
        const api = 'https://script.google.com/macros/s/AKfycbyFD1ZZIu0wp_jd6YDzFOGmph42Msgtgn7cIZarbHHQ5biPVkeTjmFX-HokX9bnDVTj/exec'
        const response = await axios.post(api, companyName)
        const data = response.data.admin
        const sheets = await axios.post(process.env.REACT_APP_FETCH_SHEETS,data.questionsSheetId)
        const dataQuestionSheet = sheets?.data.flat(1)
        const sheetNames = dataQuestionSheet.filter(element => !element.startsWith('inactive_') && !element.startsWith('Sheet'))
        setadminName(data.companyName);
        setadminEmail(data.companyEmail);
        setdataSheetId(data.dataSheetId);
        setassessmentNames(sheetNames);
        setNewScheduleModule(true)
        setLoading(false)
    } 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(""); setFNameError(""); setPhoneNoError(""); setEmailError(""); setJobTitleError(""); setExperianceError("")
        
        if(email !== "" && fName !== "" && phoneNo !== "" && jobTitle !== "" && experiance !== "") {
            const validateEmail = (email) => {
                const re = /\S+@\S+\.\S+/;
                return re.test(email);
            }
            if(!validateEmail(email)){
                setEmailError("Email Address is invalid"); 
                setErrorMessage("Email Address is invalid");
                return
            }    
            setLoading(true)
            setscheduleNowLaterDialog(true)
            const UID = `uid_${Date.now()}`
            setUid(UID)
            const candidateSkills = JSON.stringify(skills)
            const candidateProjects = JSON.stringify(projects)
            const phone = phoneNo.slice(4)
            const sheetData = JSON.stringify([companyName, UID, fName+" "+lName, email, phone, location, jobTitle, experiance, pastCompany,formatedFromDate,formatedtoDate, lastCTC,expSalary,eduQulification,institution,passout,marks,candidateSkills,candidateProjects,resumeUrl]);
            const response = await axios.post(process.env.REACT_APP_POST_CANDIDATE_APPLICATION,sheetData)
            if(response.data.status === 'added'){
                                
            }else if(response.data.status === 'emailExists'){
                setscheduleNowLaterDialog(false)
                setEmailError(true); 
                setStep(1)
                setErrorMessage("Email already Exists")
            }else{
                setscheduleNowLaterDialog(false)
                setErrorMessage('Something went wrong')
            }
            setLoading(false)
            
        }
        else{
            if(experiance === ""){setExperianceError(true); setStep(2)}            
            if(jobTitle === ""){setJobTitleError(true); setStep(1)}
            if(email === ""){setEmailError(true); setStep(1)}
            if(phoneNo === ""){setPhoneNoError(true); setStep(1)}
            if(fName === ""){setFNameError(true); setStep(1)}
            setErrorMessage("Please fill out all the mandatory fields")
        }
    }

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <div className="w-full mb-5">
                <Topbar2
                    hideDetails={true}
                />
            </div>
            <div className='w-[95%] flex flex-col lg:flex-row items-center gap-4'>
                <div className='flex card w-full lg:w-1/4 lg:h-full p-10'>
                    <div className='lg:my-10 flex w-full items-center justify-between lg:justify-start lg:items-start lg:flex-col lg:gap-24'>
                        <div
                            className='flex items-center gap-4 cursor-pointer' 
                            onClick={() => {setStep(1)}}
                        >
                            <div className={`w-10 h-10 rounded-full flex items-center justify-center text-xl text-white font-bold ${step === 1 ? 'bg-[#558BE4]' : 'bg-[#999]'} `}>1</div>
                            <div className={`font-semibold ${step === 1 ? 'text-[#558BE4]' : ''} hidden sm:flex`}>Candidate's Details</div>
                        </div>
                        {/* <div className='w-20 h-0 lg:h-20 lg:w-0 border-b-4 lg:border-l-4 border-dashed mx-2 lg:ml-[19px]'/> */}
                        <div
                            className='flex items-center gap-4 cursor-pointer' 
                            onClick={() => {setStep(2)}}
                        >
                            <div className={`w-10 h-10 rounded-full flex items-center justify-center text-xl text-white font-bold ${step === 2 ? 'bg-[#558BE4]' : 'bg-[#999]'} `}>2</div>
                            <div className={`font-semibold ${step === 2 ? 'text-[#558BE4]' : ''} hidden sm:flex`}>Education & Experience</div>
                        </div>
                        {/* <div className='w-20 h-0 lg:h-20 lg:w-0 border-b-4 lg:border-l-4 border-dashed mx-2 lg:ml-[19px]'/> */}
                        <div
                            className='flex items-center gap-4 cursor-pointer' 
                            onClick={() => {setStep(3)}}
                        >
                            <div className={`w-10 h-10 rounded-full flex items-center justify-center text-xl text-white font-bold ${step === 3 ? 'bg-[#558BE4]' : 'bg-[#999]'} `}>3</div>
                            <div className={`font-semibold ${step === 3 ? 'text-[#558BE4]' : ''} hidden sm:flex`}>Skills & Projects</div>
                        </div>
                    </div>
                </div>
                <div className='card w-full lg:w-3/4 h-full lg:h-[85vh] py-5 px-4 md:px-10 flex flex-col justify-between'>
                    <div className='flex flex-col gap-4 overflow-y-auto'>
                        <h1 className='heading1'>
                            Application Form
                        </h1>
                        <h2 className='heading2 sm:hidden'>
                            {step === 1 ? "Candidate's Details" : 
                            step === 2 ? "Education & Experience" :
                            step === 3 ? "Skills & Projects" : ""}
                        </h2>
                        {
                            step === 1 && (
                                <div className='flex flex-col gap-8'>
                                    <div className='relative flex flex-col-reverse md:flex-row md:items-center gap-4 md:gap-10'>
                                        <div className='border-4 border-dashed w-full h-[10rem] md:h-[7rem] rounded-2xl'>
                                            <UploadCV
                                                setResumeUrl={setResumeUrl}
                                                setResumeUploading={setResumeUploading}
                                                resumeUploading={resumeUploading}
                                                companyName={companyName}
                                            />
                                        </div>
                                        {
                                            linkedinProfile !== 'true' && (
                                                <>
                                                    <div className='text-center'>OR</div>
                                                    <div className='border-4 border-dashed w-full h-[7rem] rounded-2xl'>                                            
                                                        <LinkedIn
                                                            className='w-full h-ful flex justify-center items-center gap-4'
                                                            clientId={LINKEDIN_CLIENT_ID}
                                                            scope={SCOPE}
                                                            redirectUri={REDIRECT_URI}
                                                            onFailure={() => {
                                                            }}
                                                            onSuccess={(code) => {
                                                                getLinkedInUserInfoFromAccessCode(code.code)
                                                            }}
                                                        >
                                                            <p> Apply with  </p>
                                                            <div className='w-40'>
                                                                <img src="/img/linkedin.png" alt='linkedin'/>
                                                            </div>
                                                        </LinkedIn>                                
                                                    </div>
                                                </>
                                            ) 
                                        }
                                        
                                    </div>
                                    <div className='relative border-2 rounded-lg py-5 px-10 flex flex-col gap-4'>
                                        <div className='absolute top-[-10px] left-5 text-sm bg-white px-2'>Details</div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    First Name*
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="First Name"
                                                    inputName="fName"
                                                    inputValue={fName}
                                                    handleChange={(e) => setFName(e.target.value)}
                                                    error={fNameError && 'error'}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Last Name
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="Last Name"
                                                    inputName="lName"
                                                    inputValue={lName}
                                                    handleChange={(e) => setLName(e.target.value)}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Contact Number*
                                                </label>
                                                <InputMask
                                                    className={`h-[40px] w-full bg-[#F5F5F5] rounded-lg px-3 text-xs border-0 outline-none ${phoneNoError ? 'outline-red-500' : ''}`}
                                                    mask='+\91 9999999999'
                                                    maskChar=" "
                                                    value={phoneNo}
                                                    placeholder='Contact Number'
                                                    onChange={(e) => setPhoneNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Email Id*
                                                </label>
                                                <TextInput
                                                    type="email"
                                                    placeholderText="Enter your email"
                                                    inputName="email"
                                                    inputValue={email}
                                                    handleChange={(e) => setEmail(e.target.value)}
                                                    error={emailError && 'error'}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Location
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="City, State, Country"
                                                    inputName="location"
                                                    inputValue={location}
                                                    handleChange={(e) => setLocation(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='relative border-2 rounded-lg py-5 px-10 flex flex-col gap-4'>
                                        <div className='absolute top-[-10px] left-5 text-sm bg-white px-2'>Appling for</div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Job Title*
                                                </label>
                                                <TextInput
                                                    type="select"
                                                    placeholderText="Job Title"
                                                    inputName="jobTitle"
                                                    inputValue={jobTitle}
                                                    handleChange={(e) => setJobTitle(e.target.value)}
                                                    selectOptions={['React Developer', 'Python Developer', 'Java Developer','ios Developer']}
                                                    error={jobTitleError && 'error'}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Expected Salary
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="Expected salary per month"
                                                    inputName="expSalary"
                                                    inputValue={expSalary}
                                                    handleChange={(e) => setExpSalary(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>                          
                                </div>
                            )
                        }
                        {
                            step === 2 &&(
                                <div className='flex flex-col gap-8'>
                                    <div className='relative border-2 rounded-lg py-5 px-5 md:px-10 flex flex-col gap-4'>
                                        <div className='absolute top-[-10px] left-5 text-sm bg-white px-2'>Education</div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Highest Qulification
                                                </label>
                                                <TextInput
                                                    type="select"
                                                    placeholderText="Highest Educational Qulification"
                                                    inputName="eduQulification"
                                                    inputValue={eduQulification}
                                                    handleChange={(e) => setEduQulification(e.target.value)}
                                                    selectOptions={["High School","Certification","Vocational","Diploma","Graduation","Post Graduation"]}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Institution Name
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="School / College / University Name"
                                                    inputName="institution"
                                                    inputValue={institution}
                                                    handleChange={(e) => setInstitution(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Passout month & year
                                                </label>
                                                <TextInput
                                                    type="month"
                                                    inputName="passout"
                                                    inputValue={passout}
                                                    handleChange={(e) => setPassout(e.target.value)}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Marks
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="Marks in percentage"
                                                    inputName="marks"
                                                    inputValue={marks}
                                                    handleChange={(e) => setMarks(e.target.value)}
                                                />
                                            </div>
                                        </div> 
                                    </div>
                                    <div className='relative border-2 rounded-lg py-5 px-5 md:px-10 flex flex-col gap-4'>
                                        <div className='absolute top-[-10px] left-5 text-sm bg-white px-2'>Experience</div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Experience*
                                                </label>
                                                <TextInput
                                                    type="select"
                                                    placeholderText="Select your experience"
                                                    inputName="experiance"
                                                    inputValue={experiance}
                                                    handleChange={(e) => setExperiance(e.target.value)}
                                                    selectOptions= {['Fresher', '< one year ', '1 year', '2 years', '3 years', '4 years', '5 years','> 5 years']}
                                                    error={experianceError && 'error'}
                                                />
                                            </div> 
                                            <div className="flex flex-col w-full">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Last Compant Name
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="Past Company Name"
                                                    inputName="pastCompany"
                                                    inputValue={pastCompany}
                                                    handleChange={(e) => setPastCompany(e.target.value)}
                                                />
                                            </div>                                        
                                        </div>
                                        <div className='flex flex-col sm:flex-row sm:items-center gap-5'>
                                            <div className="flex flex-col w-full sm:w-1/3">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                    Last CTC
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholderText="Last CTC"
                                                    inputName="lastCTC"
                                                    inputValue={lastCTC}
                                                    handleChange={(e) => setLastCTC(e.target.value)}
                                                />
                                            </div> 
                                            <div className="flex flex-col w-full sm:w-2/3">
                                                <label className="font-medium text-xs leading-[12px] mb-2">
                                                   Worked in last company
                                                </label>
                                                <div className='flex gap-4 items-center'>
                                                    <div className='flex gap-3 items-center w-full'>
                                                        <p className='text-sm'>From</p>
                                                        <TextInput
                                                            type="date"
                                                            inputName="fromDate"
                                                            inputValue={fromDate}
                                                            handleChange={(e) => {
                                                                setFromDate(e.target.value)
                                                                setFormatedFromDate(new Date(e.target.value).toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'}))
                                                            }}
                                                        />
                                                    </div>
                                                   
                                                    {!currentlyWorking && (
                                                        <div className='flex gap-3 items-center w-full'>
                                                            <p className='text-sm'>To</p>
                                                            <TextInput
                                                                type="date"
                                                                inputName="toDate"
                                                                inputValue={toDate}
                                                                handleChange={(e) => {
                                                                    setToDate(e.target.value)
                                                                    setFormatedToDate(new Date(e.target.value).toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'}))
                                                                }}
                                                                
                                                            />
                                                        </div>
                                                    )}
                                                    <div className='flex gap-3 items-center w-full'>
                                                        <div 
                                                            className={`cursor-pointer w-14 h-8 flex items-center rounded-full px-1 transition ${currentlyWorking ? 'bg-blue-700' : 'bg-gray-300'}`}
                                                            onClick={() => {
                                                                setCurrentlyWorking(!currentlyWorking)
                                                                setFormatedToDate(new Date().toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'}))
                                                            }}
                                                        >
                                                            <div className={`bg-white w-7 h-7 rounded-full shadow-lg transition transform ${currentlyWorking ? 'translate-x-5' : ''} `}></div>
                                                        </div>
                                                        <span className='text-sm'>Currently Working</span>
                                                    </div>
                                                </div>                                                
                                            </div>                                        
                                        </div>
                                    </div>                          
                                </div>
                            )
                        }

                        {
                            step === 3 &&(
                                <div className='flex flex-col gap-8'> 
                                    <div className='relative border-2 rounded-lg py-5 px-5 md:px-10 flex flex-col gap-4'>
                                        <div className='absolute top-[-10px] left-5 text-sm bg-white px-2'>Skills</div>
                                        <p className='font-semibold'>Add your skills</p>
                                        <div className='flex flex-col sm:flex-row gap-4'>
                                            {skills.map((skill,index) => (                                                
                                                <TextInput
                                                    type="text"
                                                    width='8rem'
                                                    placeholderText="skill"
                                                    inputValue={skill}
                                                    handleChange={(e) => {
                                                        const newSkills = [...skills];
                                                        newSkills[index] = e.target.value;
                                                        setSkills(newSkills);
                                                    }}
                                                />      
                                            ))}
                                            <div className={skills.includes('') ? 'hidden' : ''}>
                                                <Button
                                                    text=""
                                                    width='10rem'
                                                    version='small-outline-primary'
                                                    handleClick={addSkill}
                                                >   
                                                    <div className='flex items-center'>
                                                        <span className="text-xl font-semibold mr-2">+</span>
                                                        <span className="text-sm">Add Skill</span>
                                                    </div>
                                                    
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='relative border-2 rounded-lg py-5 px-5 md:px-10 flex flex-col gap-4'>
                                        <div className='absolute top-[-10px] left-5 text-sm bg-white px-2'>Projects</div>
                                        <p className='font-semibold'>Add your projects</p>
                                        
                                        {projects.map((project,index) => (                                    
                                            <div className='flex flex-col gap-4'>
                                                <div className='font-medium'>Project {index+1}</div>
                                                <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Project Name*
                                                        </label>
                                                        <TextInput
                                                            type="text"
                                                            placeholderText="Name of your project"
                                                            inputName="projectName"
                                                            inputValue={project.name}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].name = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div> 
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Project Tenure
                                                        </label>
                                                        <TextInput
                                                            type="text"
                                                            placeholderText="Project Tenure"
                                                            inputName="projectTenure"
                                                            inputValue={project.tenure}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].tenure = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div>   
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Role in Project
                                                        </label>
                                                        <TextInput
                                                            type="text"
                                                            placeholderText="Your role in project"
                                                            inputName="roleInProject"
                                                            inputValue={project.role}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].role = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div>                                        
                                                </div>
                                                <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Total people involved in project
                                                        </label>
                                                        <TextInput
                                                            type="number"
                                                            placeholderText="Total people involved in project"
                                                            inputName="peopleInProject"
                                                            inputValue={projects.peopleInvited}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].peopleInvited = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div> 
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Your contribution
                                                        </label>
                                                        <TextInput
                                                            type="text"
                                                            placeholderText="Your contribution"
                                                            inputName="contribution"
                                                            inputValue={project.contribution}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].contribution = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div>   
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Recognition / Rewards / Accolade
                                                        </label>
                                                        <TextInput
                                                            type="text"
                                                            placeholderText="Recognition/rewards/accolades for this project"
                                                            inputName="recognition"
                                                            inputValue={project.recognition}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].recognition = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div>                                        
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <div className="flex flex-col w-full">
                                                        <label className="font-medium text-xs leading-[12px] mb-2">
                                                            Project Description
                                                        </label>
                                                        <TextInput
                                                            type="textbox"
                                                            placeholderText="Description on this project"
                                                            inputName="projectDescription"
                                                            inputValue={project.description}
                                                            handleChange={(e) => {
                                                                const newProjects = [...projects];
                                                                newProjects[index].description = e.target.value;
                                                                setProjects(newProjects);
                                                            }}
                                                        />
                                                    </div>   
                                                </div>
                                                <div className='my-2 border-t-4 border-dashed'/>
                                            </div>
                                        ))}
                                        <div>
                                            <Button
                                                text=""
                                                width='10rem'
                                                version='small-outline-primary'
                                                handleClick={addProject}
                                            >
                                                <div className='flex items-center'>
                                                    <span className="text-xl font-semibold mr-2">+</span>
                                                    <span className="text-sm">Add Project</span>
                                                </div>
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='flex justify-between items-center md:px-10 mt-5'>
                        <Button
                            text='Previous'
                            width='10rem'
                            isDisabled={step === 1}
                            handleClick={()=> setStep(step - 1)}
                        />
                        {errorMessage && (
                            <div className='flex flex-col text-sm text-red-500 items-center'>
                                <p>{errorMessage}</p>                                
                            </div>                            
                        )}
                        <div className='flex flex-col gap-2'>
                           {step === 3 && resumeUploading && 
                                <p className='text-xs text-red-500'>
                                    Please wait while your resume is being uploaded.
                                </p>
                            }
                            <Button
                                text={step !== 3 ? "Next" : "Submit Application"}
                                isDisabled={step === 3 && resumeUploading}
                                width='10rem'
                                handleClick={step !== 3 ? handleNext : handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${scheduleNowLaterDialog ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
                <div className="card flex flex-col justify-center items-center p-[50px] relative">
                    {loading ? (
                        <div className='font-bold text-xl p-10 text-[#888]'>
                            Loading ...
                        </div>
                    ) : (
                        <>
                            {/* <p onClick={()=>setscheduleNowLaterDialog(false)}> X </p> */}
                            <p className='font-semibold text-xl mb-5'>Application submited successfully</p>
                            <p className='text-sm text-[#919397]'>select your choise to schedule an interview</p>
                            <div className='flex mt-8 gap-5'>                        
                                <Button
                                    text="Schedule Now"
                                    idName="confirmSkip"
                                    version="secondary"
                                    handleClick={() => {
                                        setisFromScheduleNow(true);
                                        getAdminDetails();
                                    }}
                                />
                                <Button
                                    text="Schedule Later"
                                    handleClick={() => {
                                        setisFromScheduleNow(false);
                                        getAdminDetails();
                                    }}
                                />
                            </div>
                        </>
                    )}
                    
                </div>
            </div>
            {/* component new schedule */}
            <div className={`${newScheduleModuleOpen ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
                <ScheduleTestCandidate
                    closeModule={() => setNewScheduleModule(false)}
                    adminName={adminName}
                    adminEmail={adminEmail}
                    dataSheetId={dataSheetId}
                    assessmentNames={assessmentNames}
                    uid={uid}
                    candidateEmail={email}
                    candidateName={fName+" "+lName}
                    jobTitle={jobTitle}
                    isFromScheduleNow={isFromScheduleNow}
                    companyName={companyName}
                />
            </div>
        </div>
    )
}

export default CandidateApplyForm