
const examFunctions = {
    showModule : (module) => {
        module?.classList.remove("hidden");
        module?.classList.add("flex");
    },

    hideModule : (module) => {
		module?.classList.remove("flex");
		module?.classList.add("hidden");
	},
    handleFullscreen : () => {
        if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
    },
    checkAnswer : (questions, answerArr) => {
        let correct = 0;
        let unattempt = 0;
        answerArr.map((answer,index) => {
            if (answer === questions[index].answer){
                correct += 1;
            }else if (answer === ""){
                unattempt += 1;
            }
            return([correct, unattempt])
        })
        let attempted = answerArr.length-unattempt
        let score = Math.floor(correct / questions.length * 100)
        return ({correct,attempted,score});
    },
    
    examTimeDate : (startTime) => {
        let d = new Date();
        let time =  d.getTime()
		let localTime = d.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
	    const examDate = d.toDateString();
		let timeTaken = (time - startTime);
		let timeTakenMin = Math.floor(timeTaken/60000);
		let timeTakenSec = Math.floor((timeTaken % 60000)/1000);
        return({time, localTime, timeTaken : `${timeTakenMin} min ${timeTakenSec} sec`, examDate});
    },
    
    questionsTemplate : (questions,answerArr) => {
        let template = questions.map((question,index)=>{
            return (`
<br>
<b>Question ${index+1}.</b> 
<div> 
    ${question.questionStatment.split('/n').map(e => (
        `<div>
            ${e.split('<code>')[0]}
            <pre>${e.split('<code>')[1] ? e.split('<code>')[1] : ''}</pre>
        </div>`
    ))}              
</div>
<br>
    <b>Options :</b><br>
        A. <pre>${question.optionA}</pre><br>
        B. <pre>${question.optionB}</pre><br>
        C. <pre>${question.optionC}</pre><br>
        D. <pre>${question.optionD}</pre><br>

    <b>Response : </b> ${ answerArr[index] ? `<b>${answerArr[index]}.</b> 
    ${(answerArr[index] === question.answer) ? `<span style="color : green">✓ correct</span>` : (answerArr[index] !== "") ? `<span style="color : red">× incorrect</span>` : ""}
    <pre>${question[`option${answerArr[index]}`]}</pre>` 
    : `<p>Unattempted</p>` } 
    
<br>
            `)
        })
        return template;
    }
    
}

export default examFunctions