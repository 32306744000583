const day = 24*60*60*1000
const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]
const d = new Date()

const chartData = (candidates,results,chartPeriod) => {  
  let dataArray = []
  for (let i = chartPeriod-1 ; i >= 0 ; i--){
    dataArray.push(
      {
        name: i === 0 ? 'Today' : chartPeriod <= 7 ? dayArray[new Date(d - day*i).getDay()] : new Date(d - day*i).toLocaleDateString("en-in",{day: 'numeric' ,month : 'short'}),
        Candidates: candidates?.filter((candidate) => new Date(candidate.Date).toDateString() === new Date(d - day*i).toDateString()).length,
        Responses: results?.filter((result) => new Date(result.Date).toDateString() === new Date(d - day*i).toDateString()).length,
        Shortlisted: results?.filter((result) => new Date(result.Date).toDateString() === new Date(d - day*i).toDateString()).filter((result)=> result.Status === "shortlisted").length
      }
    )
  }
  return dataArray
}

export default chartData