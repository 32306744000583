import React from 'react'

import {Button} from '../../components'

const ExamLoginMobile = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
        <div className="flex flex-col w-full h-[50%] justify-center gradientPrimary rounded-b-xl px-[10%] text-white">
            <span className="font-medium text-base mb-2">
                Welcome to Naskay Technology’s
            </span>
            <h1 className="font-bold text-xl">
                Technical Skills Assessment Test
            </h1>
        </div>
        <div className='h-full flex items-center justify-center p-[10%] text-sm font-medium text-[#f00]'>
            <p className='text-center'>Please login via Laptop or PC for best experiance.</p>
        </div>
        
        <div className='flex justify-center mx-6 mb-6'>
            <Button
                text = "Close Tab"
                width="100%"
                handleClick={() => window.close()}
            />
        </div>
    </div>
  )
}

export default ExamLoginMobile
