import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import JobView from './JobView'
import {Button} from '../../components'
import PostedJob from './PostedJob'
import './job.css'

const Jobs = ({jobs,dataSheetId,jobsLoading,setUpdateJobs}) => {
    const [jobOpen, setJobOpen] = useState(false)

    const [jid, setJid] = useState()
    const [job, setJob] = useState([])
    useEffect(() => {
        const jobData = jobs?.find((job) => job?.jid === jid)
        setJob(jobData)
    },[jid,jobs])

    const [loading, setLoading] = useState(false)
    const [deleteModuleOpen, setDeleteModuleOpen] = useState(false)
    const [deleteSuccessModuleOpen, setDeleteSuccessModuleOpen] = useState(false)
    const deleteJob = async () => {
        setLoading(true)
        setDeleteModuleOpen(false)
        setDeleteSuccessModuleOpen(true)
        const deleteData = JSON.stringify([dataSheetId, 'jobs', [jid]])
        const response = await axios.post(process.env.REACT_APP_DELETE_QUESTIONS_AND_JOBS,deleteData)
        if(response.data.message === 'success'){            
            setUpdateJobs(prev => prev+1)
        }else{
            setDeleteSuccessModuleOpen(false)
        }
        setLoading(false)
    }

  return (
    <div className='relative card h-[86vh] w-full sm:w-[90vw] md:w-[85vw] lg:w-[90vw] mx-auto md:ml-20 mt-2 flex flex-col sm:px-4 lg-px-10 pt-6 pb-2 overflow-hidden'>
        
        <div className={`${deleteModuleOpen ? "flex" : "hidden"} absolute left-0 top-0 h-full w-full p-2 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
            <div className='card p-10 flex flex-col justify-center items-center w-[90%] m:w-[80%] md:w-2/3 lg:w-1/2'>
			    <p className='font-semibold text-xl mb-5 text-center w-[90%]'>
                    Do you really want to delete this Job ? 
                </p>
                <div className='w-full flex mt-8 gap-5'>
                    <Button
                        text="Delete"
                        version="red"
                        width='50%'
                        handleClick={deleteJob}
                    />
                    <Button
                        text="Cancel"
                        version='secondary'
                        width='50%'
                        handleClick={() => setDeleteModuleOpen(false)}
                    />
                </div>                
            </div>
        </div>
        {/*success module */}
        <div className={`${deleteSuccessModuleOpen ? 'flex' :'hidden'} z-10 absolute top-0 left-0 justify-center items-center w-full h-full bg-[#000]/10 backdrop-blur-[2px]`}>
            <div className='card py-10 w-1/3 flex flex-col justify-center items-center gap-5'>
                {
                    loading ? 
                    <div className='text-2xl text-[#888] font-bold'>
                        Loading...
                    </div>
                    :
                    <>
                        <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                            <p className='text-white text-4xl'>✓</p> 
                        </div>
                        <h1 className='text-lg'>The Job was successfully deleted.</h1>
                        <Link to='/jobs'>
                            <Button
                                text=" OK "
                                width='6rem'
                                handleClick={() => setDeleteSuccessModuleOpen(false)}
                            />
                        </Link>
                    </>
                }                 
            </div>
        </div>
        <div  className={`${jobOpen ? "flex" : "hidden"} absolute left-0 top-0 h-full w-full p-2 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-end`}>
            <JobView
                job = {job}
                jobOpen={jobOpen}
                closeJobView = {() => setJobOpen(false)}
            />
        </div>
        <div className='w-full h-full flex flex-col gap-4 overflow-y-scroll scrollbar-none'>
            <div className='flex items-center justify-between w-full px-6'>
                <h1 className='heading2'>All Posted Jobs </h1>                
                <Link to='/newJob'>
                    <Button text="">
                        <div className='flex gap-4 items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <p className=''>New Job</p> 
                        </div>                    
                    </Button>
                </Link>        
            </div>
            <div className='px-5 grid xl:grid-cols-2 gap-3'>
                {jobsLoading ? (
                    <>
                        <div className='bg-[#aaa]/10 w-full h-[10rem] mt-4 rounded-2xl'/>
                        <div className='bg-[#aaa]/10 w-full h-[10rem] mt-4 rounded-2xl'/>
                        <div className='bg-[#bbb]/10 w-full h-[8rem] mt-4 rounded-2xl'/>
                        <div className='bg-[#bbb]/10 w-full h-[8rem] mt-4 rounded-2xl'/>
                    </>
                    ) : (
                        jobs
                        ?.filter(job => job.active !== -1)
                        ?.map((job) => (
                            <PostedJob
                                key={job.jid}
                                JID={job.jid}
                                title = {job.title}
                                department = {job.department}
                                city = {job.city}
                                country = {job.country}
                                positions= {job.positions}
                                employmentType={job.employmentType}
                                remote = {job.remote}
                                setJobOpen={setJobOpen}
                                setDeleteModuleOpen={setDeleteModuleOpen}
                                setJid={setJid}
                            />
                        )
                    )
                )}                
            </div>
        </div>
    </div>
  )
}

export default Jobs
