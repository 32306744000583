import React,{useState} from 'react'

import {Topbar1} from '../../components'
import SetupLeft from './SetupLeft'
import SetupRight from './SetupRight'

const Setup = () => {
  const [step, setStep] = useState("camera");

  const [hasCameraPermission, sethasCameraPermission] = useState()
  const [hasMicPermission, sethasMicPermission] = useState()
  const [isMicWorking, setisMicWorking] = useState()

  return (
    <div className=''>
      <div className="mb-10">
        <Topbar1/>
      </div>
      <div className='flex items-center justify-center w-[95%] h-[90%] mx-auto my-5 bg-white rounded-[25px] backdrop-blur-[50px] shadow-[0_0_20px_rgba(0,0,0,0.05)]'>
        <div className='w-[50%] h-full rounded-l-[25px] flex flex-col justify-center items-center gradientPrimary'>
          
          <SetupLeft
            step={step}
            hasCameraPermission={hasCameraPermission}
            hasMicPermission={hasMicPermission}
            setisMicWorking={setisMicWorking}
          />
        
        </div>
          
        <div className='w-[50%] h-[70vh] flex flex-col'>
          <div className='flex gap-2.5 my-12 ml-[10%]'>
            <div className={`h-[5px] w-[114px] ${step === "camera" ? "bg-[#8A24F1]" : "bg-[#E4E4E4]"}`}></div>
            {/* <div className={`h-[5px] w-[114px] ${step === "internet" ? "bg-[#8A24F1]" : "bg-[#E4E4E4]"}`}></div> */}
            <div className={`h-[5px] w-[114px] ${step === "mic" ? "bg-[#8A24F1]" : "bg-[#E4E4E4]"}`}></div>
            <div className={`h-[5px] w-[114px] ${step === "instruction" ? "bg-[#8A24F1]" : "bg-[#E4E4E4]"}`}></div>
          </div>
          <SetupRight
            step={step}
            setStep={setStep}
            sethasCameraPermission={sethasCameraPermission}
            hasCameraPermission={hasCameraPermission}
            hasMicPermission={hasMicPermission}
            sethasMicPermission={sethasMicPermission}
            isMicWorking={isMicWorking}
          />

        </div>
      </div>
    </div>
  )
}

export default Setup
