import React,{useEffect, useState} from 'react'
import axios from 'axios'

import { Button } from '../../components'
import emailBody from '../../emailBody'

const ProfileActions = ({dataSheetId,adminName,adminEmail,candidateSchedule,candidateResult,candidateApplication, openUpdateModule,openDeleteConfirmationModule,setUpdateResults}) => {

    const [candidateStatus, setCandidateStatus] = useState()
    useEffect(()=> {
        setCandidateStatus(candidateResult?.Status)
    },[candidateResult])

    const [updateStatusLoading, setUpdateStatusLoading] = useState(false)
    const updateStatus = async(e) => {
        setUpdateStatusLoading(true)
        const sheetData = `${dataSheetId}|${candidateResult.UID}|${e.target.id}`;
        const updateStatusApi = process.env.REACT_APP_UPDATE_CANDIDATE_STATUS
        const response =  await axios.post(updateStatusApi,sheetData)
        if(response.data.message === 'success'){
            setUpdateResults(prev => prev+1)
        }
        setUpdateStatusLoading(false)

        const examDate = new Date(candidateResult?.Date).toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
        const mailApi = process.env.REACT_APP_EMAIL_API
        const shortlistedEmailSubject = `Shortlisted for the Next Round  at ${adminName}`
        const rejectedEmailSubject = ` Interview Status at ${adminName} - Rejected`
        const shortlistedEmailMessage = emailBody.shortlistEmail(candidateResult?.Name,adminName,adminEmail, examDate)
        const rejectedEmailMessage = emailBody.rejectedEmail(candidateResult?.Name,adminName) 
        const shortlistedEmailData = JSON.stringify([candidateResult.Email,shortlistedEmailSubject,shortlistedEmailMessage])
        const rejectedEmailData = JSON.stringify([candidateResult.Email,rejectedEmailSubject,rejectedEmailMessage])

        if(e.target.id === 'shortlisted'){
            axios.post(mailApi,shortlistedEmailData)
        }else if(e.target.id === 'rejected'){
            axios.post(mailApi,rejectedEmailData)
        }            
    }

  return (
    <div className='flex justify-center'>
        {candidateResult ? (
            !candidateStatus ? (
                updateStatusLoading ? (
                    <div className='text-[#666] font-semibold'>Please Wait</div>
                ) :
                <div className='flex flex-col gap-3 mt-2 w-full sm:w-2/3 md:w-1/2 lg:w-full px-10 lg:px-5 text-sm'>
                    <Button
                        text='Short List'
                        inputId='shortlisted' 
                        handleClick={updateStatus}
                        width='100%'
                    />
                    <div className='flex gap-3 w-full'>
                        <Button
                            text='Hold'
                            inputId='hold' 
                            version='outline-yellow'
                            width='50%'
                            handleClick={updateStatus}
                        />
                        <Button 
                            text='Reject'
                            inputId='rejected' 
                            version='outline-red'
                            width='50%'
                            handleClick={updateStatus}
                        />
                    </div>
                </div>
            ) : (
                updateStatusLoading ? (
                    <div className='text-[#666] font-semibold'>Please Wait</div>
                ) :
                <div className='flex gap-3 mt-6 w-full px-5 text-sm'>
                    {candidateStatus === "shortlisted" ? (
                        <div id='shortlisted' onClick={updateStatus} className="capitalize w-full flex justify-center items-center py-[6px] rounded cursor-pointer text-[#57AD55] bg-[#57AD55]/10 hover:bg-[#57AD55]/[15%]">
                            Shortlisted
                        </div>   
                    ) : (
                        candidateStatus === "hold" ? (
                            <div id='hold' onClick={updateStatus} className="capitalize w-full flex justify-center items-center py-[6px] rounded cursor-pointer text-[#FFB800] bg-[#FFB800]/10 hover:bg-[#FFB800]/[15%]">
                                Hold
                            </div>
                        ) : (
                            <div id='rejected' onClick={updateStatus} className="capitalize w-full flex justify-center items-center py-[6px] rounded cursor-pointer text-[#E5383B] bg-[#E5383B]/[6%] hover:bg-[#E5383B]/10">
                                Rejected
                                </div>
                        )
                    )}                            
                        <div id='' onClick={updateStatus} className="capitalize flex justify-center items-center py-[6px] px-2 rounded cursor-pointer text-[#9160D1] bg-[#9160D1]/10 hover:bg-[#9160D1]/[15%]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                            </svg>
                        </div>
                </div>
            )
        ) : ( candidateSchedule ? (
            <div className='flex gap-3 mt-2 text-sm'>
                <Button
                    text=""
                    width='7rem'
                    handleClick={openUpdateModule}
                >
                    <div className='flex items-center text-sm gap-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                        Reschedule
                    </div>                                    
                </Button>
                <Button 
                    text=''
                    version='outline-red'
                    handleClick={openDeleteConfirmationModule}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </Button>
            </div>                    
        ) : (
            <div className='flex gap-3 mt-2 text-sm'>
                {/* <Button
                    text=""
                    width='10rem'
                    isDisabled={true}
                    // handleClick={openUpdateModule}
                >
                    <div className='flex items-center text-sm gap-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Schedule Assessment
                    </div>                                    
                </Button>
                <Button 
                    text=''
                    version='outline-red'
                    isDisabled={true}
                    // handleClick={openDeleteConfirmationModule}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </Button> */}
            </div>
        ))}

        {!candidateResult ? "" : ""}
    </div>
  )
}

export default ProfileActions
