import React,{useState,useRef } from 'react'
import axios from 'axios';

import emailBody from '../../emailBody';
import {Button,TextInput,RadioInput,Success} from '../../components'

const UpdateModule = ({adminName,dataSheetId,candidate, setUpdateModuleOpen,assessmentNames,setUpdateSchedules}) => {

  const [name, setName] = useState(candidate.Name);
  const [email, setEmail] = useState(candidate.Email);
  const [profile, setProfile] = useState(candidate.ApplyFor)
  const [inviteExpiry, setInviteExpiry] = useState('')
  const [expDate, setExpDate] = useState('')
  const [expTime, setExpTime] = useState('')
  const [examDate, setExamDate] = useState('')
  const [examTime, setExamTime] = useState('')
  const updateForm = useRef();
  const [openSuccessModule, setOpenSuccessModule] = useState(false) 
  const [loading, setLoading] = useState(false) 

  const handleUpdate = async (e) => {
    setLoading(true)
    e.preventDefault();

    const currentDate = new Date();
    const startDate = currentDate.toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
    const startTime = currentDate.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    const scheduleDate =  new Date(examDate).toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
    const scheduleTime = new Date(`${examDate} ${examTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})

    let expiryDate, expiryTime;
    if(candidate?.inviteSchedule === 'invite') {
      if(inviteExpiry === '48hrs'){
        expiryDate = new Date(currentDate.getTime() + 48 * 60 * 60 * 1000).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
        expiryTime = currentDate.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
      }else{
          expiryDate = new Date(expDate).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
          expiryTime = new Date(`${expDate} ${expTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
      }
    }else {
      expiryDate = new Date(new Date(`${examDate} ${examTime}`).getTime() + 48 * 60 * 60 * 1000).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
      expiryTime = new Date(`${examDate} ${examTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"}) 
    }    
    const expireDateTime = new Date(`${expiryDate} ${expiryTime}`).getTime()

    const HrEmail= candidate.HrEmail
    const candidateName = name !== candidate.Name ? name : candidate.Name
    const candidateEmail = email !== candidate.Email ? email : candidate.Email
    const applyFor = profile !== candidate.ApplyFor ? profile : candidate.ApplyFor
    const uid = candidate.UID

    // mails
    const mailApi = process.env.REACT_APP_EMAIL_API
    const sendCandidateEmail = () => {
      const candidateEmailSubject = `Thank you for applying at ${adminName} - Assessment Round`
      const candidateEmailMessage = candidate.inviteSchedule === 'invite' 
            ? emailBody.candidateEmail(adminName,name,email,startDate,startTime,expiryDate,expiryTime,candidate.ExamCode)
            : emailBody.candidateEmail(adminName,name,email,scheduleDate,scheduleTime,expiryDate,expiryTime,candidate.ExamCode)
      const candidateEmailData = JSON.stringify([candidateEmail,candidateEmailSubject,candidateEmailMessage])
      axios.post(mailApi,candidateEmailData)
    }
    const sendDateUpdateEmail = () => {
      const candidateUpdateEmailSubject = `Assessment Round at ${adminName} - Schedule Updated`
      const candidateUpdateEmailMessage = candidate.inviteSchedule === 'invite'
        ? emailBody.dateUpdateCandidaateEmail(adminName,HrEmail,candidateName,candidateEmail,startDate,startTime,expiryDate,expiryTime, candidate.ExamCode)
        : emailBody.dateUpdateCandidaateEmail(adminName,HrEmail,candidateName,candidateEmail,scheduleDate,scheduleTime,expiryDate,expiryTime, candidate.ExamCode)
      const candidateUpdateEmailData = JSON.stringify([candidateEmail,candidateUpdateEmailSubject,candidateUpdateEmailMessage])
      axios.post(mailApi,candidateUpdateEmailData)
    }

    //update data in google sheet (exam-schedule)
    const sheetData =  candidate.inviteSchedule === 'invite'
      ? JSON.stringify([dataSheetId,uid,HrEmail,candidateName,candidateEmail,applyFor,'','',expireDateTime])
      : JSON.stringify([dataSheetId,uid,HrEmail,candidateName,candidateEmail,applyFor,scheduleDate,scheduleTime,expireDateTime])
    const updateScheduleApi = process.env.REACT_APP_UPDATE_SCHEDULE_API
    const response = await axios.post(updateScheduleApi,sheetData)
    if(response.data.message === 'success'){
      setName(""); setEmail(""); setProfile(""); setExpDate(); setExpTime();
      setOpenSuccessModule(true)
      email !== candidate?.Email ? sendCandidateEmail() : sendDateUpdateEmail()
    }
    setUpdateModuleOpen(false)
    setLoading(false)
    setUpdateSchedules(prev => prev+1)
  }

  return (
    <div className='h-full w-full flex flex-col gap-6 justify-center'>
        <h1 className='text-lg font-semibold text-center'>Update Assessment Details</h1>
        <form id="updateForm" onSubmit={handleUpdate} ref={updateForm}>
          <div className="w-full sm:w-2/3 lg:w-1/2 mx-auto flex flex-col gap-5">
          <div className="flex flex-col capitalize">
                <label className="text-xs mb-1 text-[#666]">
                  Assessment Type
                </label>
                <TextInput
                  type="select"
                  idName="jobProfile"
                  inputName="applyFor"
                  isRequired={true}
                  inputValue={profile}
                  handleChange={(e) => setProfile(e.target.value)}
                  selectOptions= {assessmentNames}
                  placeholderText="Select Assessment"
                />            
            </div>
            <div className="flex flex-col">
              <label className="text-xs mb-1 text-[#666]">
                  Candidate Name
                </label>
                <TextInput
                  type='text'
                  inputName="candidateName"
                  inputValue={name}
                  handleChange={(e) => setName(e.target.value)}
                  placeholderText="eg. John Deo"
                />
            </div>
            <div className="flex flex-col">
                <label className="text-xs mb-1 text-[#666]">
                  Candidate Email
                </label>
                <TextInput
                  type='email'
                  sRequired = {true}
                  inputName="candidateEmail" 
                  inputValue={email}
                  handleChange={(e) => setEmail(e.target.value)}
                  placeholderText="eg. johndeo@gmail.com"
                />
            </div>
            {candidate?.inviteSchedule === 'invite' ?
              <div className='flex flex-col gap-2'>
                <div>
                  <label className="text-xs text-[#888]">
                    Invitation Expiry
                  </label>
                  <div className='w-full items-center gap-5 flex'>
                    <RadioInput
                      inputName='expiry'
                      inputValue='48hrs'
                      iniputId='48hs'
                      text='48 hrs from update'
                      handleChange={(e) => setInviteExpiry(e.target.value)}
                    />
                    <RadioInput
                      inputName='expiry'
                      inputValue='customExpiry'
                      iniputId='customExpiry'
                      text='Set Expiry Date'
                      handleChange={(e) => setInviteExpiry(e.target.value)}
                    />
                  </div>
                </div>  
                { inviteExpiry === 'customExpiry' &&
                  <div className='flex items-center gap-4'>
                    <TextInput
                      type="date"
                      inputName="expDate"
                      // isRequired = {true}
                      inputValue={expDate}
                      handleChange={(e) => setExpDate(e.target.value)}                   
                    />
                    <TextInput
                      type="time"
                      inputName="expTime"
                      // isRequired = {true}
                      inputValue={expTime}
                      handleChange={(e) => setExpTime(e.target.value)}
                    />
                  </div>
                } 
              </div>
              :
              <div className='flex flex-col gap-2'>
                <label className="text-xs text-[#888]">
                  Assessment Date & Time
                </label>
                <div className='flex items-center gap-4'>
                  <TextInput
                    type="date"
                    inputName="examDate"
                    // isRequired = {true}
                    inputValue={examDate}
                    handleChange={e => setExamDate(e.target.value)}
                  />
                  <TextInput
                    type="time"
                    inputName="examTime"
                    // isRequired = {true}
                    inputValue={examTime}
                    handleChange={e => setExamTime(e.target.value)}
                  />
                </div>
              </div>
            }
            <div className='mt-4 flex justify-center'>
              {loading ? (
                <div className='text-[#ccc] font-semibold'>
                  Loading ...
                </div>  
              ) : (
                <Button
                  text="Update"
                  handleClick={handleUpdate}
                  width="50%"
                />
              )}
            </div>            
          </div>
        </form>
        <div className={`${openSuccessModule ? 'flex' : 'hidden'} absolute top-0 left-0 w-full h-full justify-center items-center bg-black/10 backdrop-blur-[2px] z-50`}>
            <Success
                closeSuccessModule={() => {
                  setOpenSuccessModule(false)
                  setUpdateModuleOpen(false)
                }}
                module="updateSchedule"
            />
        </div>
    </div>
  )
}

export default UpdateModule
