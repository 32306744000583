import React,{useEffect, useState} from 'react'
import axios from 'axios'

import ProfileLeftPanel from './ProfileLeftPanel'
import ProfileRightPanel from './ProfileRightPanel'
import DeleteConfirmationModule from './DeleteConfirmationModule'
import ResumeView from './ResumeView'

const CandidateProfile = ({loading,adminName,adminEmail,questionSheetId,dataSheetId, schedules, results,applications,assessmentNames,setUpdateSchedules,setUpdateResults}) => {

    const params = new URLSearchParams(window.location.search);
    const candidateUid = params.get('id');
    
    const [deleteConfirmationModule, setDeleteConfirmationModule] = useState()
    useEffect(()=>{
        setDeleteConfirmationModule(document.getElementById('deleteConfirmationModule'))
    },[])

    const candidateSchedule = schedules?.find((schedule) => schedule?.UID === candidateUid);
    const candidateResult = results?.find((result) => result?.UID === candidateUid);
    const candidateApplication = applications?.find((application) => application.uid === candidateUid)

    const [candidate, setCandidate] = useState({})
    const [updateModuleOpen, setUpdateModuleOpen] = useState(false)
    const openUpdateModule = () => {
        setUpdateModuleOpen(true)
        const candidateData = schedules.find((candidate) => candidate.UID === candidateSchedule?.UID);
        setCandidate(candidateData);
    }

    const [deleteLoading, setDeleteLoading] = useState(false)
    const deleteRecord = async () => {
        setDeleteLoading(true)
        const deleteCandidateApi = process.env.REACT_APP_DELETE_SCHEDULE_API
        let deleteUid = candidateSchedule.UID
        let deleteData = `${dataSheetId}|${deleteUid}`
        await axios.post(deleteCandidateApi, deleteData)
        setDeleteLoading(false)
        setUpdateSchedules((prev) => prev+1)
    }

    const openDeleteConfirmationModule = () => {
        deleteConfirmationModule.classList.remove('hidden')
        deleteConfirmationModule.classList.add('flex')
    }
    const hideDeleteConfirmation = () => {
        deleteConfirmationModule.classList.remove('flex')
        deleteConfirmationModule.classList.add('hidden')
    }    
    const closeModule = () => {
        hideDeleteConfirmation();
    }

    const [resumeOpen, setResumeOpen] = useState(false)
    const [resumeUrl,setResumeUrl] = useState()
    const openResume = (url) => {
        setResumeOpen(true)
    }

  return (
    <div className='main-container relative mx-auto md:ml-20 mt-2 lg:h-[90vh] flex flex-col lg:flex-row gap-3 rounded-[25px] overflow-hidden'>
        <ProfileLeftPanel
            loading={loading}
            dataSheetId={dataSheetId}
            adminName={adminName}
            adminEmail={adminEmail}
            candidateSchedule={candidateSchedule}
            candidateResult={candidateResult}
            candidateApplication={candidateApplication}
            candidateUid={candidateUid}
            openUpdateModule={openUpdateModule}
            openDeleteConfirmationModule={openDeleteConfirmationModule}
            setResumeUrl={setResumeUrl}
            openResume={openResume}
            setUpdateResults={setUpdateResults}
        />
        <ProfileRightPanel
            loading={loading}
            adminName={adminName}
            dataSheetId={dataSheetId}
            questionSheetId={questionSheetId}
            candidateResult={candidateResult}
            candidateSchedule={candidateSchedule}
            candidateApplication={candidateApplication}
            updateModuleOpen={updateModuleOpen}
            setUpdateModuleOpen={setUpdateModuleOpen}
            candidate={candidate}
            assessmentNames={assessmentNames}
            setUpdateSchedules={setUpdateSchedules}
        />
        <div id='deleteConfirmationModule' className='absolute left-0 h-full w-full bg-black/10 backdrop-blur-[2px] hidden items-center justify-center'>
            <DeleteConfirmationModule
                confirmationMessage={['confirm delete', 'Do you really want to delete this record ? ']}
                confirmationButton={['Delete','Cancel']}
                cancelAlert={hideDeleteConfirmation}
                closeModule={closeModule}
                confirmDelete={deleteRecord}
                deleteLoading={deleteLoading}
            />  
        </div>
        <div
            className={`${resumeOpen ? 'flex' : 'hidden'} absolute h-full w-full top-0 left-0 bg-black/10 backdrop-blur-[2px] items-center justify-end p-2`}
            onClick={() => setResumeOpen(false)}
        >
            <ResumeView
                resumeUrl={resumeUrl}
                setResumeOpen={setResumeOpen}
                resumeOpen={resumeOpen}
            />
        </div>
    </div>   
  )
}

export default CandidateProfile
