import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

import {Logo,Button, TextInput} from '../components'
import emailBody from '../emailBody';

const ForgotPass = () => {
    document.title = "Talentigo"
    
    const [email, setEmail] = useState("")
    const [error, setError] = useState("");
    const [successModule, setSuccessModule] = useState()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setSuccessModule(document.getElementById('sucessModule'))
    },[])

    document.addEventListener('keypress',(e)=> {
        if (e.keyCode === 13) {
            handleSubmit()
          }
    })

    const handleSubmit = async () => {

        setError("")
        
        const fetchAdmin = async () => {
            setLoading(true)
            const fetchData = email
            const response = await axios.post(process.env.REACT_APP_ADMIN_LOGIN ,fetchData);
            console.log(response?.data)
            if(response?.data?.status === 'success'){
                const emailSubject = `Reset Password - ${response.data.admin.companyName}`
                const emailMessage = emailBody.forgetPass(response.data.admin.companyName,response.data.admin.dataSheetId)
                const emailData = JSON.stringify([email,emailSubject,emailMessage])
                const mailApi = process.env.REACT_APP_EMAIL_API
                const emailResponse = await axios.post(mailApi,emailData)
                if(emailResponse.data.message === 'success'){
                    successModule?.classList.remove('hidden')
                    successModule?.classList.add('flex')
                }else{
                    setError("something went wrong")
                }
                
            }else{
                setError("No account exists with this email Id.")
            }
            
            setLoading(false)
        }

        email ? await fetchAdmin() : setError("Please enter your Emaili")
    }
    const closeModule = () => {
        successModule?.classList.remove('flex')
        successModule?.classList.add('hidden')
    }

    return (
        <div className='admin-main'>
            <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
                <div className='relative h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-4'>
                    <div id='sucessModule' className='hidden backdrop-blur-sm h-full w-full absolute items-center justify-center'>
                        <div className='card h-2/3 w-[90%] flex flex-col items-center justify-center gap-8 p-5'>
                            {
                                    <>
                                        <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                                            <p className='text-white text-4xl'>✓</p> 
                                        </div>
                                        <h1 className='text-xl font-medium'>Email Found !</h1>
                                        <p className='text-sm px-12 text-center'>
                                            We have send you email. Please check and follow the link to reset your password.
                                        </p>
                                        <Link to= '/admin'>
                                            <Button
                                                text='OK'
                                                width='10rem'
                                                handleClick={closeModule}
                                            />
                                        </Link>
                                    </>
                            }
                            

                        </div>
                    </div>
                    <Logo/>
                    <form className="flex flex-col gap-4 w-full mt-12">  
                        <h1>Find your account</h1>
                        <div className="flex flex-col">
                            <label htmlFor="email" className="font-medium text-xs leading-[12px] mb-2">
                                Email id
                            </label>
                            <TextInput
                                type="email"
                                placeholderText="Enter your Email id"
                                inputName="email"
                                isRequired = "true"
                                inputValue={email}
                                handleChange={(e) => setEmail(e.target.value)}
                            />

                        </div>
                            
                        <div className='flex flex-col items-center justify-center gap-2 mt-4'>
                            {
                                <div className="font-medium text-xs leading-3 h-3 text-[#f00]">
                                    {error}
                                </div>
                            }

                            {loading ? (
                                <div className='text-[#ccc] font-semibold'>
                                    Loading ...
                                </div>
                                
                            ) : (
                                <Button
                                    version="primary"
                                    text="Submit"
                                    type="button"
                                    handleClick={handleSubmit}
                                    width="15rem"
                                />
                            )}
                            
                        </div>
                    </form>
                    <div className='mt-4 flex flex-col justify-center items-center gap-4 w-full'>
                        <p className='text-sm'>OR</p>
                        <div className='flex justify-center gap-2 w-full'>
                            <Link
                                to='/register'
                                className='text-right w-full font-medium hover:underline'
                            >
                                Register
                            </Link>
                            |
                            <Link
                                to='/admin'
                                className='w-full font-medium hover:underline '
                            >
                                Login
                            </Link>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
  )
}

export default ForgotPass
