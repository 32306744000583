import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '../../components'

const DeleteConfirmationModule = ({cancelAlert,closeModule,confirmationButton,confirmationMessage,confirmDelete,deleteLoading}) => {
    const [successModule, setSuccessModule] = useState()
    useEffect(() => {
        setSuccessModule(document.getElementById('success'))
    },[])
    const handleDelete = () => {
        successModule.classList.remove('hidden')
        successModule.classList.add('flex')
        confirmDelete()
    }
    const close = () => {
        successModule.classList.remove('flex')
        successModule.classList.add('hidden')
        closeModule()
    }
  return (
    <div className="common-popup relative card flex flex-col justify-center items-center p-[50px] w-[95%]">
        <div id='success' className='absolute h-full w-full card z-10 hidden flex-col gap-5 items-center justify-evenly p-5'>
            {deleteLoading ? (
                <h1 className='text-lg text-[#888] font-bold'>Please Wait ... </h1>
            ) : (
                <>
                    <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                        <p className='text-white text-4xl'>✓</p> 
                    </div>
                    <h1 className='text-lg'>Successfully Deleted !</h1>
                    <Link to='/invitations'>
                        <Button
                            text=" OK "
                            width='6rem'
                            handleClick={close}
                        />
                    </Link>
                </>
            )}        
        </div>
            <div
                onClick={cancelAlert}
                className='flex justify-center items-center absolute top-5 right-5 rounded-full w-10 h-10 bg-[#F9FAFB] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-7 h-7">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
            </div>

			<p className='text-center font-semibold text-xl mb-5 capitalize'>{confirmationMessage[0]}</p>
            <p className='text-center text-sm text-[#919397]'>{confirmationMessage[1]}</p>

            <div className='flex mt-8 gap-5'>
				<Button
					text={confirmationButton[0]}
					version= 'red'
					handleClick={handleDelete}
				/>
				<Button
					text={confirmationButton[1]}
					handleClick={cancelAlert}
					version= 'secondary'
				/> 
			</div>
		</div>
  )
}

export default DeleteConfirmationModule
