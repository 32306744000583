import React, { useState,useEffect } from 'react'
import axios from 'axios';

import {Button, TextInput, Topbar1} from '../../components'

const ExamLogin = () => {
    const [userEmail, setUserEmail] = useState("")
    const [code, setCode] = useState("")
    const [mailError, setMailError] = useState("");
    const [passError, setPassError] = useState("");
    const [otherError, setOtherError] = useState("");
    const [loading, setLoading] = useState(false)

    const [loginButton, setLoginButton] = useState()
    useEffect(() => setLoginButton(document.getElementById("loginButton")),[])

    const params = new URLSearchParams(window.location.search);
    const companyName = params.get('key');

    document.addEventListener('keypress',(e)=> {
        if (e.keyCode === 13) {
            loginButton?.click()
        }
    })

    const handleSubmit = async() =>{

        //reset error messages
        setMailError("");
        setPassError("");
        setOtherError("");

        let candidateData;        
        const fetchCandidate = async () => {
            setLoading(true)
            const fetchData = `${companyName}|${userEmail}`
            const candidateResponse = await axios.post(process.env.REACT_APP_EXAM_LOGIN ,fetchData);
            if(candidateResponse?.data?.status === 'success'){
                candidateData = candidateResponse?.data
            }else if(candidateResponse?.data?.status === 'emailError'){
                setMailError("Invalid Candidate Email")
            }else if(candidateResponse?.data?.status === 'linkError'){
                setOtherError("Something went wrong. Please check your exam link")
            }
            setLoading(false)
        }

        if(userEmail && code){
            await fetchCandidate()
        }

        if (candidateData) {
            if (candidateData.candidate.ExamCode !== code) {
                // Invalid Exam Code
                 setPassError("Invalid Exam Code");                
            } else if (new Date(candidateData.time) - new Date() < -100000 || new Date(candidateData.time) - new Date() > 100000) {
                // System clock not correct 
                setOtherError("Please set your system date & time correctly")
            } else {
                if (candidateData.candidate.Attempt){
                    // already attempted
                    setOtherError("You have already attempted the test.");
                } else if(window.innerWidth <= 820){
                    // Device width check
                    setOtherError("Please join via a Laptop/PC.");
                } else {
                    //saving exam Details to local storage
                    let candidateDetails = {
                        dataSheetId : candidateData.dataSheetID,
                        questionSheetId : candidateData.questionSheetId,
                        uid :candidateData.candidate?.UID,
                        emailId : userEmail,
                        name : candidateData.candidate?.Name,
                        exam : candidateData.candidate?.ApplyFor,
                        hrEmail : candidateData.candidate?.HrEmail,
                        maxViolations : candidateData.candidate?.maxViolations,
                        maxQuestions : candidateData.candidate?.maxQuestions,
                        examDate : new Date(candidateData.candidate?.Date).toDateString(),
                        examTime : new Date(candidateData.candidate?.Time).toLocaleTimeString(),
                        inviteSchedule : candidateData.candidate?.inviteSchedule,
                        expireDateTime : candidateData.candidate?.expireDateTime,
                        timeNow : candidateData.time,
                        isCandidateLogin : true,
                        companyName:companyName
                    }
                    localStorage.removeItem('refresh')
                    localStorage.setItem('candidateDetails', JSON.stringify(candidateDetails));
                    window.location.href = "/start"
                }
            }
        }

        if(userEmail && code){
            await fetchCandidate()
        }

        if (userEmail === ""){
            setMailError("Please fill your email Id")
        }
        if (code === ""){
        setPassError("Please fill the Assessment Code")
        }
    }

  return (
    <div className='right-item w-[70%] h-full mx-auto flex flex-col justify-center'>
        <div className="flex flex-col">
            <div className="left-logo mb-12">
                <Topbar1/>
            </div>
            <span className="font-medium text-lg leading-[27px] mb-3">
                Welcome to
            </span>
            <h1 className="heading1 mb-10">
                Technical Skills Assessment Test
            </h1>
        </div>
        <div className="w-full">
            <form className="">
                <div className="mb-[14px]">
                    <label htmlFor="email" className="font-medium text-sm leading-[12px] mb-3">
                        Email Id<sup>*</sup>
                    </label>
                    <TextInput
                        type="email"
                        placeholderText="Enter your Email id"
                        error={mailError && "error"}
                        inputName="email"
                        isRequired = {true}
                        inputValue={userEmail}
                        handleChange={(e) => setUserEmail(e.target.value)}
                    />
                    {
                        <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                            {mailError}
                        </div>
                    }
                </div>
                <div className="mb-[14px]">
                    <label htmlFor="examCode" className="font-medium text-sm leading-[12px] mb-3">
                        Assessment Code<sup>*</sup>
                    </label>
                    
                    <TextInput
                        type="password"
                        placeholderText="Assessment Code"
                        error={passError && "error"}
                        inputName="examCode"
                        inputValue={code}
                        handleChange={(e) => setCode(e.target.value)}
                        isRequired = {true}
                    />
                    
                    {
                        <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                            {passError}
                        </div>
                    }
                </div>
                    
                <div className='flex items-start gap-4 flex-col'>
                    {loading ? (
                        <div className='text-[#ccc] font-semibold'>
                            Loading ...
                        </div>
                        
                    ) : (
                        <>
                            <Button
                                version="primary"
                                text="Proceed"
                                type="button"
                                handleClick={handleSubmit}
                                width="20m"
                                inputId= 'loginButton'
                            />
                            {otherError && (
                                <div className="font-medium text-sm leading-4 text-[#f00]">
                                    {otherError}
                                </div>
                            )}
                        </>                        
                    )}
                        
                </div>
               
            </form>
        </div>
    </div>
  )
}

export default ExamLogin
