import React,{useEffect,useState} from 'react'
import axios from 'axios';

const Verification = () => {
    const [ loading, setLoading] = useState(true)
    const [ error, setError] = useState()

    const params = new URLSearchParams(window.location.search);
    const companyId = Number(params.get('key'))

    const [companies, setCompanies] = useState([])
    useEffect(() => {
        let fetchData = async() => {
            const sheetId = process.env.REACT_APP_COMPANY_LOGIN_SHEET_ID
            const data = JSON.stringify([sheetId,'login'])
            const response = await axios.post(process.env.REACT_APP_FETCH_API, data)
            console.log(response.data.allData)
            setCompanies(response.data.allData)
            setLoading(false)
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (!loading){
            axios.post(process.env.REACT_APP_ACTIVATE_ACCOUNT_API,companyId)

            const companyData = companies.find((company) => company.id === companyId);

            if(companyData){
                localStorage.setItem('adminLogin', JSON.stringify({
                    isAdminLogedIn : true, 
                    name : companyData?.companyName,
                    email : companyData?.companyEmail,
                    dataSheetId : companyData?.dataSheetId,
                    questionSheetId : companyData?.questionsSheetId
                }));
                    setTimeout(()=> {
                        window.location.href = '/admin';
                },1000)
            }else{
                setError("No Data Available")
            }
           
        }
        
    },[companies,loading])
    
  return (
    <div className='flex flex-col justify-center items-center h-full gap-10  bg-white'>
        {error ? (
            <div className='text-2xl font-bold text-[#888] flex flex-col gap-10'>
                <img src="/img/error.png" alt="" />
                {error}
            </div>
        ) : (
            <>
                <img src="/img/loading.gif" className='w-[400px]' alt="" />
                <p className='text-lg mt-[-100px]'>
                    Please wait, while we are Verifying  your account.
                </p>
            </>
        )}       

    </div>
  )
}

export default Verification
