import React,{useState, useEffect} from 'react'
import { Button, TextInput, Topbar2} from '../../components'
import axios from 'axios'

// import {candidateLoginWindow} from '../../constant/Constants'

const CountDown = () => {

    const [date, setDate] = useState()
    const [time, setTime] = useState()
    const [inviteSchedule, setInviteSchedule] = useState()
    const [expireDateTime, setExpireDateTime] = useState()
    const [duration, setDuration] = useState()
    const [ days, setDays ] = useState();
	const [ hours, setHours ] = useState();
    const [ minutes, setMinutes ] = useState();
	const [ seconds, setSeconds ] = useState();

    const [rescheduleModule, setRescheduleModule] = useState(false)
    const [scheduleNowLaterDialog, setscheduleNowLaterDialog] = useState(false)

    const [companyName, setcompanyName] = useState()
    const [companyEmail, setCompanyEmail] = useState()
    const [dataSheetId, setdataSheetId] = useState()
    const [candidateUid, setCandidateUid] = useState()
    const [candidateName, setCandidateName] = useState()
    const [email, setEmail] = useState()    

    const [examDate ,setExamDate] = useState()
    const [examTime ,setExamTime] = useState()

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const candidate = JSON.parse(localStorage.getItem('candidateDetails'))
        if(candidate){
            setCandidateUid(candidate.uid)
            setCandidateName(candidate.name)
            setEmail(candidate.emailId)
            setCompanyEmail(candidate.hrEmail)
            setcompanyName(candidate.companyName);
            setdataSheetId(candidate.dataSheetId)
            setInviteSchedule(candidate.inviteSchedule)
            setDate(candidate.examDate);
            setTime(candidate.examTime);
            setExpireDateTime(candidate.expireDateTime 
                ? candidate.expireDateTime 
                : new Date(`${candidate.examDate} ${candidate.examTime}`).getTime() + 2*24*60*60*1000
            )
        }        
    },[date,time])

    const [dateNow, setDateNow] = useState()
    useEffect(() => {
        setInterval(() => {
            setDateNow(new Date())
        },1000)
    })
    useEffect(() => {
        setDuration( Math.floor((new Date(`${date} ${time}`) - dateNow)/1000))
    },[date,time,dateNow])
    
    useEffect(()=> {
        setDays(Math.floor(duration/(60*60*24)))
        setHours(Math.floor(duration/3600 % 24))
		setMinutes( Math.floor(duration/60 % 60));
		setSeconds(Math.floor(duration % 60));
	},[duration])

    const [rescheduleSuccess, setRescheduleSuccess] = useState(false)
    const [rescheduleError, setRescheduleError] = useState('')
    const handleReSchedule = async() => {       
            const expireDateTime =  new Date(`${examDate} ${examTime}`).getTime() + 48 * 60 * 60 * 1000
            setLoading(true)
            const sheetData = JSON.stringify([dataSheetId,candidateUid,'','','','',examDate,examTime,expireDateTime])
            const updateScheduleApi = process.env.REACT_APP_UPDATE_SCHEDULE_API
            const response = await axios.post(updateScheduleApi,sheetData)
            if(response?.data?.message === 'success'){   
                setRescheduleSuccess(true);
            }
            setLoading(false)
    }

    const startNow = () => {
        setLoading(true)
        const dateNow = new Date()
        const scheduleDate = dateNow.toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
        const scheduleTime = dateNow.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
        const expireDateTime = dateNow.getTime() + 60 * 60 * 1000
        const sheetData = JSON.stringify([dataSheetId,candidateUid,'','','','',scheduleDate,scheduleTime,expireDateTime]);
        const updateScheduleApi = process.env.REACT_APP_UPDATE_SCHEDULE_API
        axios.post(updateScheduleApi,sheetData)
        examLogin()
        setLoading(false)
    }

    const examLogin = async () => {
        let candidateData;        
        const fetchCandidate = async () => {
            const fetchData = `${companyName}|${email}`
            const candidateResponse = await axios.post(process.env.REACT_APP_EXAM_LOGIN ,fetchData);
            if(candidateResponse?.data?.status === 'success'){
                candidateData = candidateResponse?.data
            }
        }

        await fetchCandidate()

        if (candidateData) {            
            //saving exam Details to local storage
            let candidateDetails = {
                dataSheetId : candidateData.dataSheetID,
                questionSheetId : candidateData.questionSheetId,
                uid :candidateData.candidate?.UID,
                emailId : email,
                name : candidateData.candidate?.Name,
                exam : candidateData.candidate?.ApplyFor,
                hrEmail : candidateData.candidate?.HrEmail,
                maxViolations : candidateData.candidate?.maxViolations,
                maxQuestions : candidateData.candidate?.maxQuestions,
                examDate : new Date(candidateData.candidate?.Date).toDateString(),
                examTime : new Date(candidateData.candidate?.Time).toLocaleTimeString(),
                inviteSchedule : candidateData.candidate?.inviteSchedule,
                expireDateTime : candidateData.candidate?.expireDateTime,
                timeNow : candidateData.time,
                isCandidateLogin : true,
                companyName:companyName
            }
            localStorage.removeItem('refresh')
            localStorage.setItem('candidateDetails', JSON.stringify(candidateDetails));
            window.location.href = "/examSetup"
        }
    }   

  return (
    <div className='w-full h-full'>
        <Topbar2
            name = {candidateName}
            email= {email} 
        />
        <div className='w-[95%] h-[80%] mx-auto py-8 flex card my-6 flex-col gap-5 justify-center items-center overflow-hidden'>
            
            {( expireDateTime - dateNow >= 0 && duration < 0 ) && (
                <>
                    <Button
                        version='green'
                        text="Start Assessment"
                        handleClick={() => {window.location.href = '/examSetup'}}
                    />
                    {
                        inviteSchedule !== 'invite' &&
                        <>
                            <div className='my-2'>OR</div>
                            <div>
                                <Button
                                    text= "Reschedule Assessment"
                                    handleClick={() => {
                                        setRescheduleModule(true);
                                    }}
                                />
                            </div>
                        </>
                    }                    
                </>               
            )}
            {(expireDateTime - dateNow) < 0 && (
                <div className='flex flex-col gap-5 justify-center items-center'>
                    <div className='text-2xl font-semibold'>
                        Your Assesment has been expired on
                    </div>
                    <div className='text-4xl gradientText font-medium'>
                        {new Date(expireDateTime).toLocaleString("en-in", {weekday: "short", day:"numeric", month: "long", year: "numeric"})}
                    </div>
                    <div className='text-4xl gradientText font-medium'>
                        {new Date(expireDateTime).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})}
                    </div>
                    {inviteSchedule !== 'invite' &&
                        <>
                             <div className='mt-5'>
                                <Button
                                    text= "Reschedule Assessment"
                                    handleClick={() => setscheduleNowLaterDialog(true)}
                                />
                            </div>
                            <div>OR</div>
                        </>
                    }                   
                    <div className='text-lg font-semibold'>
                        Contact -<a href={`mailto:${companyEmail}`} className='gradientText mx-2'>{companyEmail}</a>
                    </div>
                </div>
            )}


            <div className={`${scheduleNowLaterDialog ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
                <div className="card flex flex-col gap-2 justify-center items-center p-10 relative">
                    <div
                        onClick={() => setscheduleNowLaterDialog(false)}
                        className='absolute cursor-pointer top-4 right-4 w-8 h-8 rounded-full bg-[#000]/10 flex items-center justify-center text-sm'
                    >✖️</div>
                    <h1 className='heading1'>Reschedule</h1>
                    <p className='text-sm text-[#919397]'>select your choise to reschedule an interview</p>
                    {loading ? 
                        <>
                            <div className='text-[#ccc] font-semibold'>
                                Loading ...
                            </div>
                        </>
                        :
                        <div className='flex mt-8 gap-5'>
                            <Button
                                text="Start Now"
                                idName="startNow"
                                version="green"
                                width='10rem'
                                handleClick={() => {
                                    startNow()
                                }}
                            />
                            <Button
                                text="Schedule Later"
                                width='10rem'
                                handleClick={() => {
                                    setRescheduleModule(true);
                                }}
                            />
                        </div>
                    }
                    
                </div>
            </div>

            {duration > 0 && (
                <>
                    <div className='w-full p-10 flex absolute top-0 justify-between'>
                        <div>
                            <p>Your Assesment is Scheduled for</p>
                            <p className='flex gap-2 justify-center text-xl gradientText'>
                                <span>{date}</span>-
                                <span>{time}</span>
                            </p>
                        </div>
                        
                        <div className=''>
                            <Button
                                text= "Reschedule Assessment"
                                handleClick={() => setscheduleNowLaterDialog(true)}
                            />
                        </div>
                    </div>
                    
                    <div className='text-2xl font-semibold'>
                        Time Left to Start
                    </div>
                    <div className='text-lg flex gap-5'>

                        {days !== 0 && (
                            <div className=' w-[100px]'>
                                <span className='text-4xl gradientText w-[45px] inline-block font-medium'>
                                    {days ? (days < 10 ? `0${days}` : days ) : '00' }
                                </span> {days === 1 ? "Day" : "Days"}
                            </div>
                        )}

                        {(days || hours !== 0) && (
                            <div className='b w-[105px]'>
                                <span className='text-4xl gradientText w-[45px] inline-block font-medium'>
                                    {hours ? (hours < 10 ? `0${hours}` : hours ) : '00' }
                                </span> {hours === 1 ? "Hour" : "Hours"}
                            </div>
                        )}

                        {(days || hours || minutes || seconds !==0) && (
                            <>
                                <div className='w-[120px]'>
                                    <span className='text-4xl gradientText w-[45px] inline-block font-medium'>
                                        {minutes ? (minutes < 10 ? `0${minutes}` : minutes ) : '00' }
                                    </span> {minutes === 1 ? "Minute" : "Minutes"}
                                </div>

                                <div className='w-[128px]'>
                                    <span className='text-4xl gradientText w-[45px] inline-block font-medium'>
                                        {seconds ? (seconds < 10 ? `0${seconds}` : seconds ) : "00" }
                                    </span> {seconds === 1 ? "Second" : "Seconds"}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            <div className={`${rescheduleModule ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
                <div className='card w-1/3 flex flex-col items-center justify-center p-10 gap-6'>
                    {loading ? 
                        <div className='w-full h-full py-20 text-2xl font-bold text-[#666] flex items-center justify-center'>
                            Loading ...
                        </div>
                        :
                        <>
                            <div
                                onClick={() => setRescheduleModule(false)}
                                className='absolute cursor-pointer top-4 right-4 w-8 h-8 rounded-full bg-[#000]/10 flex items-center justify-center text-sm'
                            >✖️</div>
                            <h1 className='heading2'>Reschedule</h1>
                            <div className='w-full flex flex-col  gap-1'>
                                <label className="text-sm text-[#888]">
                                    Select Date and Time for Assessment
                                </label>
                                <div className='flex items-center gap-4'>
                                    <TextInput
                                        type="date"
                                        InputName="date"
                                        isRequired = {true}
                                        inputValue={examDate}
                                        handleChange={(e) => setExamDate(e.target.value)}
                                    />
                                    <TextInput
                                        type="time"
                                        InputName="time"
                                        isRequired = {true}
                                        inputValue={examTime}
                                        handleChange={(e) => setExamTime(e.target.value)}
                                    />
                                </div>                                
                            </div>
                            {rescheduleError &&
                                <div className='text-red-500 text-center text-sm mb-2'>
                                    {rescheduleError} 
                                </div>
                            }
                            
                            <Button
                                text="Update"
                                handleClick={handleReSchedule}
                                width='10rem'
                            /> 
                        </>
                    }                    
                </div>
            </div>   
            <div className={`${rescheduleSuccess ? 'flex' : 'hidden'} absolute w-full h-full left-0 top-0 bg-[#000]/10 backdrop-blur-[2px] z-10 items-center justify-center`}>
                <div className='card w-1/3 flex flex-col items-center justify-center p-10 gap-6'>
                    <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                        <p className='text-white text-4xl'>✓</p> 
                    </div>
                    <h1 className='heading2 text-center'>
                        Successfully Updated your Assessment Schedule
                    </h1>
                    <Button
                        text=" OK "
                        width='50%'
                        handleClick={() => window.location.href = `https://staginginterview.naskay.in/?key=${companyName}`}
                    />
                </div>
            </div>             
        </div>
    </div>
  )
}

export default CountDown
