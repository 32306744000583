import React,{useState} from 'react'
import axios from 'axios';

import {Button, TextInput} from '../../components'

export const UploadSingleQuestion = ({questionSheetId,assessmentNames,uploadSuccessModule,updateRecords,setUpdateRecords}) => {
    
    //States
    const [profile, setProfile] = useState("");
    const [questionType, setQuestionType] = useState("");
    const [questionDifficulty, setQuestionDifficulty] = useState("");
    const [questionStatment, setQuestionStatment] = useState("");
    const [optionA, setOptionA] = useState("");
    const [optionB, setOptionB] = useState("");
    const [optionC, setOptionC] = useState("");
    const [optionD, setOptionD] = useState("");
    const [time, setTime] = useState("");
    const [answer, setAnswer] = useState("");

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const optionInputs = [
        { placeholder : "Option A", value : optionA, onChange : e => setOptionA(e.target.value) },
        { placeholder : "Option B", value : optionB, onChange : e => setOptionB(e.target.value) },
        { placeholder : "Option C", value : optionC, onChange : e => setOptionC(e.target.value) },
        { placeholder : "Option D", value : optionD, onChange : e => setOptionD(e.target.value) }
    ] 
                            
    const handleUpload = async (e) => {        
        e.preventDefault();
        setError("")

        if(profile && questionStatment && optionA && optionB && optionC && optionD && time && answer && questionDifficulty) {
            //sending question data to google sheet
            setLoading(true)
            const questionData = `${questionSheetId}|${profile}|${questionStatment}|${optionA}|${optionB}|${optionC}|${optionD}|${time}|${answer}|${questionDifficulty}`;
            const response = await axios.post(process.env.REACT_APP_SINGLE_QUESTION_UPLOAD,questionData)
            // console.log(response?.data)
            setLoading(false)
        
            // displaying question upload success module
            uploadSuccessModule?.classList.remove("hidden");
            uploadSuccessModule?.classList.add("flex");
            window.location.reload();
            setUpdateRecords(updateRecords+1)
        }else{
            setError("All fields are required.")
        }
       
    }
  return (
    <div className='w-full h-full overflow-y-auto scrollable'>
        <form className="flex flex-col justify-center items-center gap-2"> 
				<h1 className="heading2">Single Upload Question</h1>
				<div className="flex flex-col gap-2 w-[90%]">
                    <div>
                        <label className="text-xs mb-1 text-[#888]">
                            Assessment Tye<sup>*</sup>
                        </label>
                        <TextInput
                            type="select"
                            idName="jobProfile"
                            inputName = "applyFor"
                            isRequired={true}
                            placeholderText="Select Assessment"
                            inputValue={profile}
                            handleChange={e => setProfile(e.target.value)}
                            selectOptions= {assessmentNames}
                        />
                    </div>
                    <div className='flex flex-col sm:flex-row gap-2'>
                        <div className='w-full'>
                            <label className="text-xs mb-1 text-[#888]">
                                Question Type<sup>*</sup>
                            </label>
                            <TextInput
                                type="select"
                                idName="type"
                                inputName = "type"
                                isRequired={true}
                                placeholderText="Select Question Type"
                                inputValue={questionType}
                                handleChange={e => setQuestionType(e.target.value)}
                                selectOptions= {['MCQ']}
                            />
                        </div>
                        <div className='w-full'>
                            <label className="text-xs mb-1 text-[#888]">
                                Question Difficulty<sup>*</sup>
                            </label>
                            <TextInput
                                type="select"
                                idName="type"
                                inputName = "type"
                                isRequired={true}
                                placeholderText="Select Question Difficulty"
                                inputValue={questionDifficulty}
                                handleChange={e => setQuestionDifficulty(e.target.value)}
                                selectOptions= {['Easy','Medium','Hard']}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="text-xs mb-1 text-[#888]">
                            Question Statement<sup>*</sup>
                        </label>
                        <TextInput
                            type="textbox"
                            textRows="4" 
                            placeholderText='Question Statement'
                            isRequired = {true}
                            inputValue={questionStatment}
                            handleChange={(e) => setQuestionStatment(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="text-xs mb-1 text-[#888]">
                            Options<sup>*</sup>
                        </label>
                        <div className='flex flex-col gap-2'>
                            {[0,2].map((i) => {
                                return(
                                    <div key={i} className='flex flex-col sm:flex-row gap-2'>
                                        {optionInputs.slice(i,i+2).map((optionInput) =>{
                                            return(
                                                <TextInput 
                                                    key={optionInput.placeholder}
                                                    placeholderText={optionInput.placeholder}
                                                    inputValue={optionInput.value}
                                                    handleChange={optionInput.onChange}
                                                    innerLabel={optionInput.placeholder.slice(-1)+'.'}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div> 
                    </div>
                                  
                            
                    <div className='flex gap-2 mb-4 w-full flex-col sm:flex-row'>
                        <div className='w-full'>
                            <label className="text-xs mb-1 text-[#888]">
                                Correct Option<sup>*</sup>
                            </label>
                            <TextInput
                                type="select"
                                idName="answer"
                                inputName="answer"
                                placeholderText="Select Correct Answer"
                                isRequired = {true}
                                inputValue={answer}
                                handleChange={e => setAnswer(e.target.value)}
                                selectOptions = {["A", "B", "C", "D"]}
                            />
                        </div>
                        <div className='w-full'>
                            <label className="text-xs mb-1 text-[#888]">
                                Duration<sup>*</sup>
                            </label>
                            <TextInput
                                type="number"
                                placeholderText="Question Duration (in minutes)"
                                inputValue={time}
                                handleChange={e => setTime(e.target.value)}
                                isRequired = {true}
                            />
                        </div> 
                    </div>
                    {error && (
                        <div className='text-red-500 text-center text-sm'>
                            {error}
                        </div>
                    )}
                    <div className='flex justify-center w-full'>
                        {loading ? (
                            <div className='text-[#ccc] font-semibold text-center'>
                                Loading ...
                            </div>
                        ) : (
                            <Button
                                text="Upload"
                                handleClick={handleUpload}
                                width='20rem'
                            /> 
                        )}                        
                    </div>
                </div>                               
			</form>
    </div>
  )
}
