import React from 'react'
import {Button} from '../'

const Success = ({closeSuccessModule,message, module,closeScheduleModule,successInviteEmails,unSuccessInviteEmails}) => {
  const handleClick = () => {
    if(module === 'inviteBulk'){
        closeSuccessModule()
        closeScheduleModule()
    }else{
      closeSuccessModule()
    }
  }
  return (
    <div className='card common-popup w-[95%] p-10  flex flex-col justify-center items-center gap-5'>
      <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
        <p className='text-white text-4xl'>✓</p> 
      </div>
      {message ? (
        <h1 className='text-lg'>
          {message}
        </h1>
      ) : (successInviteEmails || unSuccessInviteEmails) ? (
        <div className='flex flex-col gap-2 px-2 max-h-[16rem] overflow-y-auto scrollable'>
          {successInviteEmails?.length !==0 &&
            <>
              <h1 className='text-lg text-[#57AD55]'>
                Successfully Sent Invitations to
              </h1>
              <ul className=''>
                {successInviteEmails?.map(email => 
                  <li className='text-sm flex gap-2 items-center'>
                    <span className='text-[#57AD55] text-base font-bold'>✓</span>
                    {email}
                  </li>
                )}
              </ul>
            </>
          }
          {unSuccessInviteEmails?.length !==0 &&
            <>
              <h1 className='text-lg text-[#f00]'>
                Unsuccessfull for
              </h1>
              <ul className=''>
                {unSuccessInviteEmails?.map(email => 
                  <li className='text-sm flex gap-2 items-center'>
                    <span className='text-[#f00] text-base font-semibold'>×</span>
                    {email}
                  </li>
                )}
              </ul>
            </>
          }         
        </div>       
      ) : (
        <h1 className='text-lg'>Successfully {module === "newSchedule" ? "Scheduled" : "Updated"} !</h1>
      )}    
      
      <Button
        text=" OK "
        width='6rem'
        handleClick={handleClick}
      />
    </div>
  )
}

export default Success
