import React from 'react'

const ExamLoadingModule = ({loadingPercent}) => {
    return (
        <div className='card w-1/2 h-1/2 p-10 flex justify-center items-center'>
            <div className='flex flex-col items-center justify-center gap-8'>
                <img src='/img/exam-writing.png' alt='icon'/>
                {/* <div className='w-[80%] flex flex-col gap-1'>
                    <div className='relative w-full h-[10px] bg-[#F2F8FF] rounded-[10px]'>
                        <div className={`absolute top-0 left-0 w-[${loadingPercent}%] h-full bg-[#558BE4] rounded-[10px]`}/>
                    </div>
                    <div className='flex justify-between text-[#979797]'>
                        <span>Loading...</span>
                        <span>{loadingPercent}%</span>
                    </div>
                </div> */}
                <div className='w-full flex flex-col gap-3 items-center justify-center'>
                    <h1 className='heading2'>Please wait while we prepare your test</h1>
                    <p className='text-center text-[#666]'>We're getting everything ready to ensure you have the best experience possible. Thank you for your patience!</p>
                </div>
            </div>
        </div>
    )
}

export default ExamLoadingModule