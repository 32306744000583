import React,{useState, useCallback} from "react";
import { useDropzone } from "react-dropzone";

const UploadCV = ({setResumeUrl,resumeUploading,setResumeUploading,companyName}) => {

  const [files, setFiles] = useState([]);

  const resumeUploadApi = 'https://script.google.com/macros/s/AKfycbyo_ykvX_nE6P1ZhYh74AgxYrLDltsxuxABcAfHiDBFckoltb5xVNlaoKIcxr5yKgRR/exec';
  const onDrop = useCallback(acceptedFiles => {
    setResumeUploading(true)
    const reader = new FileReader()
    reader.readAsArrayBuffer(acceptedFiles[0])

    reader.onload = (f) => {
      if (!!reader.result) {
        const resumeId = `resume_${Date.now()}`
        const qs = new URLSearchParams({filename: resumeId ,companyName: companyName, mimeType: "application/pdf"});
        fetch(`${resumeUploadApi}?${qs}`, {method: "POST", body: JSON.stringify([...new Int8Array(f.target.result)])})
        .then(res => res.json())
        .then(e => {
          setResumeUrl(e?.fileUrl)
          setResumeUploading(false)
        })
        .catch(err => console.log(err));          
      }
    }    
    setFiles(prev => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop,multiple: false, accept: {'application/pdf' : '', 'image/*' : '','.docx' : ''}});

  const fileList = files.map(file => (
    <div key={file.path} className="text-center">
      <p>File - {file.path}</p>
      <p>Size - {Math.round(file.size/1024)} kb</p >
    </div>
    
  ));

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div {...getRootProps({ className: "dropzone flex flex-col cursor-pointer w-full h-full flex flex-col items-center" })}>
        <input {...getInputProps()} />
          {resumeUploading ? (
            <div className="w-full h-full flex items-center justify-center font-medium text-[#888]">
              Uploading ...
            </div>
            
          ) : fileList.length > 0 ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
                {fileList[0]}
            </div>
          ) : (
            <div className="w-full h-full flex flex-col items-center justify-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-[#888]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
              </svg>       
                <p className="text-center text-sm text-[#666]">
                    Choose or drop your Resume here.
                </p>
                <p className="text-center text-xs text-[#666]">
                    ( pdf / word / image format )
                </p>
            </div>
          )}
               
      </div>      
    </div>
  );
}

export default UploadCV
