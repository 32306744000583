import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { 
  // GoogleLogin,
  // GoogleOAuthProvider, 
  useGoogleLogin 
} from '@react-oauth/google';
import GitHubLogin from 'react-github-login';
import { LinkedIn } from 'react-linkedin-login-oauth2'
// import { FacebookProvider, Like, ShareButton } from 'react-facebook';
import { LINKEDIN_CLIENT_ID,REDIRECT_URI,SCOPE,constgetLinkedInUserInfoFromAccessCode,REDIRECT_URI_GITHUB } from '../../constant/Constants'

import {Logo} from '../../components'

const CandidateRegistration = () => {
    const [ user, setUser ] = useState([]);
    const [companyName,setCompanyName] = useState("");
    const getLinkedInUserInfoFromAccessCode = async (accessCode) => {
        fetch(constgetLinkedInUserInfoFromAccessCode, {
            method: 'POST',
            body: JSON.stringify({accessCode: accessCode }),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(response =>{
            var fname = response.user.userInfo.localizedFirstName;
            var lname = response.user.userInfo.localizedLastName;
            var email = response.user.email.emailAddress;
            window.location.href = '/applyForm?key='+companyName+'&firstName='+fname+'&lastName='+lname+'&email='+email+'&isLinkedInProfile=true';
          }).catch(error => console.error('Error:', error));
    }
    
    const getGitHubUserInfoFromAccessCode = async (accessCode) => {
        fetch("http://3.127.9.55:9095/api/v1/getGitHubUserInfoFromAccessCode", {
            method: 'POST',
            body: JSON.stringify({accessCode: accessCode }),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(response =>{
            // console.log("------------------------------")
            // console.log(JSON.stringify(response));
            var fname = response.name;
            var lname = "";
            var email = response.email;
            // console.log(fname);
            window.location.href = '/applyForm?key='+companyName+'&firstName='+fname+'&lastName='+lname+'&email='+email+'&isLinkedInProfile=false';
            // var lname = response.user.userInfo.localizedLastName;
            // var email = response.user.email.emailAddress;
            // console.log(response.user.userInfo.localizedFirstName+" "+response.user.userInfo.localizedLastName);
            // window.location.href = '/applyForm?key='+companyName+'&firstName='+fname+'&lastName='+lname+'&email='+email+'&isLinkedInProfile=true';
          }).catch(error => console.error('Error:', error));

    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
          setUser(codeResponse)
          // console.log("--------------------------")
          // console.log(JSON.stringify(codeResponse))
          // console.log("--------------------------")
        },
        // onError: (error) => console.log('Login Failed:', error)
      });
    
    useEffect(
    () => {
        const params = new URLSearchParams(window.location.search);
        const companyName = params.get('key');
        setCompanyName(companyName)
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                  var fname = res.data.given_name;
                  var lname = res.data.family_name;
                  var email = res.data.email;
                  window.location.href = '/applyForm?key='+companyName+'&firstName='+fname+'&lastName='+lname+'&email='+email+'&isLinkedInProfile=false';
                })
                .catch((err) => console.log(err));
        }
        },[ user ]
    );

    return (
      <div className="flex justify-center items-center h-full sm:py-5 w-full gradient2"> 
        <div className='relative overflow-hidden bg-white sm:rounded-[25px] w-full h-full sm:h-[90%] sm:w-[80%] md:w-2/3 lg:w-1/2 xl:w-2/5 flex flex-col p-10 sm:px-16 gap-12'>
          <Logo/>
          <div className='w-full flex flex-col items-center gap-8'>
            <h1 className='heading1'> Registration</h1>
            <div className='flex flex-col w-full justify-center items-center gap-4'>
              <div className="border-2 p-4 w-full rounded-xl hover:bg-[#aaa]/10 hover:border-[#aaa]/20">
                  <LinkedIn
                    className='w-full flex gap-4 justify-center items-center'
                    clientId={LINKEDIN_CLIENT_ID}
                    scope={SCOPE}
                    redirectUri={REDIRECT_URI}
                    onFailure={() => {}}
                    onSuccess={(code) => {
                      // console.log("-----------------------")
                      // console.log(JSON.stringify(code))
                      // console.log("-----------------------")
                      getLinkedInUserInfoFromAccessCode(code.code)
                    }}
                  > 
                    <img src='/img/linkedin_logo.png' alt="Log in with Linked In" width="35"/>
                    <p className='text-lg font-medium'>Register with LinkedIn</p>
                  </LinkedIn>                  
              </div>
             
              <div className='border-2 p-3 w-full rounded-xl hover:bg-[#aaa]/10 hover:border-[#aaa]/20'>
                <GitHubLogin 
                  clientId="4d24546912b9ff769349" 
                  redirectUri={REDIRECT_URI_GITHUB}
                  className='w-full flex gap-4 items-center justify-center'
                  onSuccess={(response) => {
                    // console.log("--------------------------------")
                    // console.log(JSON.stringify(response))
                    // console.log("success")
                    // console.log("--------------------------------")
                      getGitHubUserInfoFromAccessCode(response.code)
                    }}
                    onFailure={(a) => {
                      // console.log("failer")
                    }}
                >
                  <img src='/img/github_logo.png' alt="Log in with Linked In" width="40"/>
                  <p className='text-lg font-medium'>Register with GitHub</p>
                </GitHubLogin>                
              </div>
              
              <div className='border-2 p-3 px-8 w-full rounded-xl hover:bg-[#aaa]/10 hover:border-[#aaa]/20'>
                <button onClick={() => login()} className='flex gap-4 w-full justify-center items-center'>
                  <img src='/img/google_logo.png' alt="Log in with Linked In" width="40"/>
                  <p className='text-lg font-medium'>Register with Google</p>
                </button>                
              </div>            

            </div>
          </div>          
            
        </div>
      </div>
    )
}

export default CandidateRegistration