import React from 'react';
import './TextInput.css';

const TextInput = ({
	type,
	placeholderText,
	innerLabel,
	width,
	idName,
	inputName,
	inputValue,
	handleChange,
	isRequired,
	selectOptions,
	textRows,
	accept,
	defaultDate,
	error
}) => {
	return (
		<>
			{type === "select" ? (
				<select
					className = {`${error} selectInput`}
					id={idName}
					name={inputName}
					required={isRequired}
					value={inputValue}
					onChange={handleChange}
				>
					<option className='text-[#C4C4C4] text-sm' value="" disabled>{placeholderText}</option>
					{selectOptions.map((selectOption) => {
						return(
							<option className='font-medium text-sm capitalize text-[#666]' key={selectOption} value={selectOption}>{selectOption}</option>
						)
					})}
				</select>

			) : type === "textbox" ? (
				<textarea
                    className={`${error} textArea`}
                    rows={textRows}
                    placeholder={placeholderText}
                    required = {isRequired}
                    value={inputValue}
                    onChange={handleChange}
                />
			) :  type === "file" ? (

				<div className='fileInputContainer'>
				<label className='fileInputPlaceholder'>{placeholderText}</label>
					<input
						className={`${error} fileInput`}
						name={inputName}
						type='file'
						style={{width: `${width}`}}
						value={inputValue}
						onChange={handleChange}
						required={isRequired}
						accept={accept}
					/>
				</div>
				
			) : (
				<div className={`flex items-center w-full ${innerLabel && 'gap-1 bg-[#F5F5F5] rounded-lg px-3 text-sm'}`}>
					{innerLabel}
					<input
						className={`${error} textInput`}
						name={inputName}
						type={type}
						style={{width: `${width}`}}
						placeholder={placeholderText}
						value={inputValue}
						onChange={handleChange}
						required={isRequired}
						defaultValue={defaultDate}
					/>
				</div>
				
			)}
		</>
		
	);
};

TextInput.defaultProps = {
	type : 'text',
	idName : "",
	inputName : "",
	placeholderText : "",
	width : "100%",
	inputValue : "",
	handleChange : () => {},
	isRequired : false,
	selectOptions :[],
}

export default TextInput;
