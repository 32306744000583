import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { ResponsiveContainer,BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from "recharts";
import { motion } from "framer-motion"

import chartData from './chartData';

const Dashboard = ({candidates, results,loading}) => {

    const [shortlisted,setShortlisted] = useState()
    const [onhold,setOnhold] = useState()
    const [chartPeriod,setChartPeriod] = useState(7)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 80);
    const [windowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 768;

    useEffect(() => {
        setShortlisted(results?.filter((result) => result?.Status === "shortlisted"))
        setOnhold(results?.filter((result) => result?.Status === "hold"))
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
          };
      
          // Add an event listener to update the window height when it changes
          window.addEventListener('resize', handleResize);
      
          return () => {
            // Remove the event listener to prevent memory leaks
            window.removeEventListener('resize', handleResize);
          };
    },[results])

    const LoadingSmallComponent = () => {
        return(
            <>
                <div className={`bg-[#666]/10 w-[2.5rem] h-[2.5rem] sm:w-[3.5rem] sm:h-[3.5rem] rounded-2xl`}></div>
                <div className='flex flex-col h-full w-1/2 gap-2 '>
                    <div className='bg-[#666]/10 w-1/2 h-1/2 rounded-2xl'></div>
                    <div className='bg-[#666]/10 w-full h-1/2 rounded-2xl'></div>
                </div>
            </>
        )
    }
    

  return (
    <div className='main-container dashboard-container mx-auto md:ml-20 mt-0 lg:h-[90vh] flex flex-col gap-3' style={{ height: isMobile ? 'auto' : `${windowHeight}px` }}>
        <motion.div
            className='flex flex-col lg:flex-row gap-3 w-full h-[15%] text-sm md:text-base'
            initial={{opacity:0}}
            animate={{opacity:1}}
            transition={{duration:0.5, delay:0.5}} 
        >   
            <div className='flex gap-2 sm:gap-3 w-full'>
                <div className='dashboard-card card h-[4.5rem] sm:h-[5.5rem] w-1/2 flex items-center px-5 py-5 gap-4'>
                    {loading ? (
                        <LoadingSmallComponent/>
                    ) : (
                        <>
                            <div className={`dash-icon bg-[#0066FF]/10 text-[#0066FF] rounded-2xl flex justify-center items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-6 h-6 sm:w-8 sm:h-8'>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                            </div>    
                            <div className='flex flex-col'>
                                <div className='font-semibold text-xl'>
                                    {candidates?.length < 10 ? `0${candidates?.length}` : candidates?.length}
                                </div>
                                <div className='text-[#757575] font-medium'>Interviews Scheduled</div>
                            </div>
                        </>
                    )}
                    
                </div>
                <div className='dashboard-card card h-[4.5rem] sm:h-[5.5rem] w-1/2 flex items-center px-5 py-5 gap-4'>
                    {loading ? (
                        <LoadingSmallComponent/>
                    ) : (
                        <>
                            <div className={`dash-icon bg-[#FE823D]/10 text-[#FE823D] rounded-2xl flex justify-center items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-6 h-6 sm:w-8 sm:h-8'>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </div>    
                            <div className='flex flex-col'>
                                <div className='font-semibold text-xl'>
                                    {results?.length < 10 ? `0${results?.length}` : results?.length}
                                </div>
                                <div className='text-[#757575] font-medium'>Candidate Responses</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className='flex gap-2 sm:gap-3 w-full'>
                <div className='dashboard-card card h-[4.5rem] sm:h-[5.5rem] w-1/2 flex items-center px-5 py-5 gap-4'>
                    {loading ? (
                        <LoadingSmallComponent/>
                    ) : (
                        <>
                            <div className={`dash-icon bg-[#57AD55]/10 text-[#57AD55] rounded-2xl flex justify-center items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-6 h-6 sm:w-8 sm:h-8'>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                </svg>
                            </div>    
                            <div className='flex flex-col'>
                                <div className='font-semibold text-xl'>
                                    {shortlisted?.length < 10 ? `0${shortlisted?.length}` : shortlisted?.length}
                                </div>
                                <div className='text-[#757575] font-medium'>Candidates Shortlisted</div>
                            </div>
                        </>
                    )}
                </div>
                <div className='dashboard-card card h-[4.5rem] sm:h-[5.5rem] w-1/2 flex items-center px-5 py-5 gap-4'>
                    {loading ? (
                        <LoadingSmallComponent/>
                    ) : (
                        <>
                            <div className={`dash-icon bg-[#FFB800]/10 text-[#FFB800] rounded-2xl flex justify-center items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-6 h-6 sm:w-8 sm:h-8'>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                            </div>    
                            <div className='flex flex-col'>
                                <div className='font-semibold text-xl'>
                                    {onhold?.length < 10 ? `0${onhold?.length}` : onhold?.length}
                                </div>
                                <div className='text-[#757575] font-medium'>Candidates On Hold</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </motion.div>
        <div className='flex flex-col md:flex-row gap-3 h-[85%] w-full container-inner-height'>
            <motion.div
                className='card w-full md:w-1/2 lg:w-1/3 xl:w-1/4 sm:h-full py-6 px-3 flex flex-col gap-6 overflow-hidden'
                initial={{opacity:0}}
                animate={{opacity:1}}
                transition={{duration:0.5, delay:0.5}}
            >
                <div className='flex justify-between items-center px-3'>
                    <h1 className='font-bold'>Recent assessments</h1>
                    <Link to = '/results' className='text-[#11031D] font-bold cursor-pointer text-xs'>
                        <motion.p whileHover={{scale : 1.03}} whileTap={{scale : 0.98}}>View All</motion.p>
                    </Link>
                </div>
                <motion.div
                    className='flex flex-col gap-5 px-3 h-full left-card overflow-y-scroll scrollable'
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    transition={{duration:0.5, delay:0.5}}
                >                
                    {
                        loading ? (
                            <>
                                <div className='bg-[#666]/10 w-full h-[1.5rem] rounded-2xl'/>
                                <div className='bg-[#666]/10 w-full h-[1.5rem] rounded-2xl'/>
                                <div className='bg-[#666]/10 w-full h-[1.5rem] rounded-2xl'/>
                                <div className='bg-[#666]/10 w-3/4 h-[1.5rem] rounded-2xl'/>
                                <div className='bg-[#666]/10 w-1/2 h-[1.5rem] rounded-2xl'/>
                            </>
                            
                        ) : (
                            results?.length === 0 ?
                                <div className='empty-placeholder h-4/5 flex justify-center items-center text-center px-4 text-[#888] font-semibold'>
                                    Recently attempted assessments will appear here.
                                </div>
                                : 
                                results.slice(0,12).map((result) => {
                                    return (
                                        <div className="pr-4" key={result.UID}>
                                            <div className='flex justify-between text-sm font-medium mb-1'>
                                                <p>{result.Name}</p>
                                                <p>{result.Score === 'NaN' ? 0 : result.Score }%</p>
                                            </div>
                                            <div className="w-full">
                                                <div className="bg-[#eee] relative h-[5px] w-full rounded-2xl">
                                                    <motion.div
                                                        initial={{width:0}}
                                                        animate={{width : `${result?.Score}%`}}
                                                        transition={{duration:0.5, delay:0.2}}
                                                        className={`${result?.Score < 40 ? "bg-[#e88]" : result?.Score < 70 ? "bg-[#fd8]" : "bg-[#8d8]"} absolute top-0 left-0 h-full rounded-2xl`}
                                                        ></motion.div>
                                                </div>
                                            </div>
                                        </div>    
                                    )                                    
                                })   
                        )
                    }
                </motion.div>         
            </motion.div>

            <motion.div
                className='w-full md:w-1/2 lg:w-2/3 xl:w-3/4 h-full card'
                initial={{opacity:0}}
                animate={{opacity:1}}
                transition={{duration:0.5, delay:0.5}}
            >
                {loading ? (
                    <div className='w-full h-full flex justify-center items-center text-2xl font-bold text-[#888]'>
                        Loading...
                    </div>
                ) : (
                    candidates?.length === 0 ? (
                        <div className='empty-placeholder w-full h-full flex justify-center items-center p-5 bg-[#F5F5F5]'>
                            <img src="./img/tour.png" alt="eye-icon" className='h-3/4' />
                        </div>
                    ) : (
                        <>
                            <div className='flex justify-between px-10 py-6 mb-5'>
                                <div className='flex gap-4 items-baseline'>
                                    <h1 className='font-bold text-lg'>Statistics</h1>
                                    <p className='text-[#757575] text-sm'>(Last {chartPeriod} Days)</p>
                                </div>                                
                                <div className='flex items-center gap-4'> 
                                    <select
                                        className='border-none bg-[#f5f5f5] w-24 md:w-32 px-4 rounded-md md:rounded-xl focus:ring-0 text-sm'
                                        onChange={(e) => setChartPeriod(e.target.value)}
                                        value = {chartPeriod}
                                    >
                                        <option className='' value={7}>Last Week</option>
                                        <option className='' value={10} >10 Days</option>
                                        <option className='' value={30} >Last Month</option>
                                    </select>                                   
                                </div>
                            </div>
                            <motion.div
                                initial={{opacity:0}}
                                animate={{opacity:1}}
                                transition={{duration:0.5, delay:1}}
                                className='flex items-center justify-center mt-16 dashboard-bar-chat'
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={chartData(candidates,results,chartPeriod)}>
                                        <CartesianGrid vertical={false} stroke="#eee" strokeDasharray="" />
                                        <XAxis dataKey="name" axisLine={true} tickLine={false} tick={{fill:'#666', fontSize:'10px' }} />
                                        <YAxis axisLine={false} tickLine={false} tick={{fill:'#666', fontSize:'12px'  }}/>
                                        <Legend
                                            iconType="circle" iconSize={10} 
                                            wrapperStyle={{ bottom: -10 }}
                                            formatter={(value) => <div><span className="text-[#666] text-sm">{value}</span></div>}
                                        />
                                        <Bar dataKey="Candidates" stackId="a" fill="#0066FF" barSize={10} radius={[10,10,0,0]}/>
                                        <Bar dataKey="Responses" stackId="b" fill="#FE823D" barSize={10} radius={[10,10,0,0]} />
                                        <Bar dataKey="Shortlisted" stackId="c" fill="#57AD55" barSize={10} radius={[10,10,0,0]} />       
                                    </BarChart>
                                </ResponsiveContainer>
                            </motion.div>
                        </>
                    )
                )}
            </motion.div>
        </div>       
    </div>
  )
}

export default Dashboard
