import React,{useState, useEffect} from 'react'

const Timer = ({ loading, duration, qusNum, timeUp, timerPause}) => {

	const [ minutes, setMinutes ] = useState(0);
	const [ seconds, setSeconds ] = useState(0);

	useEffect(()=> {
		const min = Math.floor(duration)
		const sec = Math.ceil((duration % min)*100)
		setMinutes(min);
		setSeconds(sec);
	},[duration, qusNum])

	useEffect(() => {
		let timerInterval = setInterval(() => {
			if (!timerPause) {
				if (seconds > 0){
					setSeconds(seconds-1);
				}else{
					if ( minutes > 0 ){
						setMinutes(minutes - 1);
						setSeconds(59)
					}else{
						clearInterval(timerInterval);
						timeUp();
					}
				}
			}
		}, 1000);
		return () => {
			clearInterval(timerInterval);
		};
	});

  return (
    <>
        <span className='font-medium'>Time Remaining</span>
		{
			loading ? (
				<div className='ml-5 mr-10 w-[120px] font-bold text-xl text-[#bbb] flex justify-center items-center text-center'>
					Loading...
				</div>
			) : (
				<span className='ml-5 mr-10 w-[120px] font-medium text-3xl flex justify-center items-center text-center'>
					{(minutes === 0 && seconds===0) ?
						(
							<span className='text-2xl text-red-500 font-semibold'> Time's Up </span>
						) : (
							<div className={(minutes === 0 && seconds < 40) ? 'text-red-500' : ''}>
								<span className='w-[50px] text-center'>
									{minutes ? (minutes < 10 ? `0${minutes}` : minutes ) : '00' }
								</span>
								<span> : </span>
								<span className='w-[50px] text-center'>
									{seconds < 10 ? `0${seconds}` : seconds}
								</span>
							</div>
							
						)
					}
				</span>
			)
		}
        
    </>
  )
}

export default Timer
