import React,{useState, useCallback} from "react";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import {Button, TextInput} from '../../components'

const UploadCSV = ({assessmentNames, questionSheetId, uploadSuccessModule}) => {

  const [profile, setProfile] = useState()
  const [files, setFiles] = useState([]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)
  
  const onDrop = useCallback(acceptedFiles => {
    setFiles(prev => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: {'text/csv': []}});

  const fileList = files.map(file => (
    <div key={file.path} className="text-lg text-center">
      <p>File - {file.path}</p>
      <p>Size - {file.size} bytes</p >
    </div>
    
  ));

  const handleUpload = (e) => {
    setError("");
    e.preventDefault();
    if (!profile || !files) return setError("All fields are required");
    setLoading(true)
    let parsedData;
    const reader = new FileReader(); 
    reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result);
        parsedData = csv?.data.slice(1);

        const questionsData = JSON.stringify([questionSheetId,profile,parsedData])
        const response = await axios.post(process.env.REACT_APP_BULK_QUESTION_UPLOAD,questionsData)
        setLoading(false)
        // console.log(response?.data.status)
        
        uploadSuccessModule?.classList.remove("hidden");
        uploadSuccessModule?.classList.add("flex");
    };
    reader.readAsText(files[0]);
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center rounded-xl">
      <div {...getRootProps({ className: "dropzone flex flex-col cursor-pointer w-full h-full flex flex-col p-5 items-center" })}>
        <input {...getInputProps()} />

        <h1 className='heading2 text-center'>
          Upload Bulk Questions <span className='text-sm lowercase font-normal ml-1'>(.csv format)</span>
        </h1>
        <div
          onClick={(e) => e.stopPropagation()}
          className='w-3/4 mt-5'
        >
            <label className="text-xs mb-1 text-[#888]">
              Assessment Type
            </label>
            <TextInput
                type="select"
                idName="jobProfile"
                inputName = "applyFor"
                isRequired={true}
                placeholderText="Select Assessment Type"
                inputValue={profile}
                handleChange={e => setProfile(e.target.value)}
                selectOptions= {assessmentNames}
            />
          </div>
        <div className="w-full h-3/5 flex flex-col items-center justify-center">
          {fileList.length > 0 ? (
            fileList[0]
          ) : (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mb-5 w-16 h-16 text-[#888]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
              </svg>        

              <p className="text-center text-sm text-[#666]">
                  Choose or drop a .csv file here
              </p>
            </>
          )}
        </div>
        <div className='flex flex-col justify-center w-3/4'>
            <div className='text-xs text-center mb-4' onClick={(e) => e.stopPropagation()}>
                <a href='/questions.csv'>
                  <span className="text-[#55F] text-sm font-medium hover:underline">Click here </span>to download supported CSV format file
                </a>
            </div>
            {error && (
              <div className='text-red-500 text-center text-sm mb-2'>
                {error}
              </div>
            )}
            {loading ? (
              <div className='text-[#ccc] font-semibold text-center'>
                Loading ...
              </div>
            ) : (          
              <div className="upload-wrapper" onClick={(e) => e.stopPropagation()}>
                <Button
                  text="Upload"
                  handleClick={handleUpload}
                  width='100%'
              /> 
              </div>
              
            )}  
        </div>
        
      </div>

      
    </div>
  );
}

export default UploadCSV
