import React from 'react'
// import { motion } from 'framer-motion'

import "./Button.css"

const Button = ({
  className,
  text,
  children,
  type,
  isDisabled,
  version,
  handleClick,
  inputId,
  display,
  width,
}) => {
  return (
    <button
        className={`btn btn-${version} ${className}`}
        type={type}
        disabled={isDisabled}
        onClick={handleClick}
        style={{display: `${display}`, minWidth: `${width}`}}
        id={inputId}
    >{text} {children}</button>
  )
}

Button.defaultProps = {
    text:'Button',
    version:'primary',
    type:'button',  
    isDisabled: false,
    inputId:"",
    handleClick : () => {},
    display: "flex",
    width:"fit-contact"
}

export default Button
