import React,{useState, useEffect} from 'react'
import axios from 'axios'

import UpdateModule from './UpdateModule'
import QuestionModule from './QuestionModule'
import QuestionAnalyse from './QuestionAnalyse'
import {candidateLoginWindow} from '../../constant/Constants'

const ProfileRightPanel = ({loading,adminName,dataSheetId,questionSheetId,candidateResult, candidateSchedule,candidateApplication, updateModuleOpen, setUpdateModuleOpen, candidate,assessmentNames,setUpdateSchedules}) => {

    const answer = (candidateResult?.AnswerArray === "NA" ? "N,N,N,N,N,N,N,N,N,N" : candidateResult?.AnswerArray)
    const answerArray = answer?.split(",")
    const notViewedQus = candidateResult?.TotalQuestions - answerArray?.length
    for(let i=0 ; i <= notViewedQus ; i++) {
        answerArray?.push("")
    }
    
    const questionArry = candidateResult?.qusArray ? candidateResult?.qusArray?.split(",") : ['1','2','3',"4",'5','6','7','8','9','10']
    const violations = candidateResult?.violations
    const violationArr = violations?.split(",")
    const violationQuestion = candidateResult?.violationQuestion
    const violationQuestionArr = violationQuestion?.split(",")
    
    const violationQuestionArray =Array(questionArry?.length).fill("");
    for (let i = 0; i < violationQuestionArr?.length; i++) {
        const index = violationQuestionArr[i];
        const value = violationArr[i];
        if (violationQuestionArray[index-1] === "") {
            violationQuestionArray[index-1] = value;
        } else {
            violationQuestionArray[index-1] += ", " + value;
        }
    }

    const [questions, setQuestions] = useState()
    const [quesNo, setQuesNo] = useState()
    const [quesLoading, setQuesLoading] = useState()
    useEffect(() => {
        let fetchQuestions = async() => {
            setQuesLoading(true)
            const sheetName = candidateResult?.Exam
            const questionData = JSON.stringify([questionSheetId,sheetName])
            const response =  await axios.post(process.env.REACT_APP_FETCH_API,questionData);
            setQuestions(response?.data.allData.reverse())
            setQuesLoading(false)
        }
        candidateResult && fetchQuestions()
    }, [candidateResult,questionSheetId])

    const openQuestionModule = (e) => {
        setQuesNo(e.target.id - 1)
        document.getElementById('questionModule').classList.remove('hidden')
        document.getElementById('questionModule').classList.add('flex')
    }
    const closeQuestionModule = (e) => {
        document.getElementById('questionModule').classList.add('hidden')
        document.getElementById('questionModule').classList.remove('flex')
    }
    
  return (
    <>
        {loading ? 
            <div className='card flex w-full lg:w-[70%] text-2xl font-bold justify-center items-center text-[#888]'>
                Loading . . .
            </div>
        : 
            (
                !candidateResult ? (
                    <div className={`card ${updateModuleOpen ? 'flex' : 'hidden'} lg:flex w-full lg:w-[70%] gap-3 p-10 overflow-hidden`}>
                        {updateModuleOpen ? (
                            <UpdateModule
                                adminName={adminName}
                                dataSheetId={dataSheetId}
                                candidate={candidate}
                                setUpdateModuleOpen={setUpdateModuleOpen}
                                assessmentNames={assessmentNames}
                                setUpdateSchedules={setUpdateSchedules}
                            />
                        ) : (
                            <div className='flex flex-col gap-8 w-full h-full justify-center items-center'>
                                <img src="img/error.png" alt="" />
                                <div className='lex flex-col justify-center items-center text-center'>
                                    <p className='mb-3'>
                                        <span className='font font-semibold capitalize'>{candidateSchedule?.Name || candidateApplication?.name}</span>
                                        <span>
                                            {candidateSchedule ? (
                                                candidateSchedule.expireDateTime < Date.now()  && candidateSchedule?.Attempt !== 1 
                                                    ? " has Missed the Scheduled Assessment"
                                                    : candidateSchedule?.Attempt === 1 
                                                        ? " had attempted but not finished or submitted the assessment."
                                                        : " has not attempted the assessment yet."
                                            ) : (
                                                " has no scheduled assessments."
                                            )}             
                                        </span> 
                                    </p>                            
                                    <p className='text-[#888] text-sm font-medium'>
                                        {candidateSchedule ? (
                                            new Date(candidateSchedule?.Date) - new Date() < candidateLoginWindow || candidateSchedule?.Attempt === 1 
                                            ? "You can reschedule the assessment."
                                            : "The assessment evaluation will appear here once it has been attempted."
                                        ) : (
                                            ""
                                        )
                                        }
                                    </p> 
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='flex flex-col w-full lg:w-[70%] gap-3'>
                        <div className='flex flex-col lg:flex-row gap-2 md:gap-3 w-full'>
                            <div className='flex gap-2 md:gap-3 w-full'>
                                <div className='dashboard-card card h-[5rem] w-full flex items-center px-4 gap-2'>
                                    <div className={`dash-icon bg-[#01C851]/10 text-[#01C851] w-[3rem] h-[3rem] rounded-xl flex justify-center items-center`}>
                                        <p className="font-semibold text-2xl">✓</p>
                                    </div> 
                                    <div className='flex flex-col gap-1'>
                                        <div className='font-semibold'>
                                            {candidateResult?.Correct < 10 && 0 }{candidateResult?.Correct} / 
                                            {candidateResult?.TotalQuestions < 10 && 0 }{candidateResult?.TotalQuestions}
                                        </div>
                                        <div className='text-[#757575] text-xs font-medium'> Correct Answers </div>
                                    </div>
                                </div>
                                <div className='dashboard-card card h-[5rem] w-full flex items-center px-4 gap-2'>
                                    <div className={`dash-icon bg-[#0066FF]/10 text-[#0066FF] w-[3rem] h-[3rem] rounded-xl flex justify-center items-center`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </div> 
                                    <div className='flex flex-col gap-1'>
                                        <div className='font-semibold'>
                                            {candidateResult?.Attempted < 10 && 0}{candidateResult?.Attempted} / 
                                            {candidateResult?.TotalQuestions < 10 && 0 }{candidateResult?.TotalQuestions}
                                        </div>
                                        <div className='text-[#757575] text-xs font-medium'> Attempted </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex gap-2 md:gap-3 w-full'>
                                <div className='dashboard-card card h-[5rem] w-full flex items-center px-4 gap-2'>
                                    <div className={`dash-icon bg-[#C562F1]/10 text-[#C562F1] w-[3rem] h-[3rem] rounded-xl flex justify-center items-center`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div> 
                                    <div className='flex flex-col gap-1'>
                                        <div className='font-semibold'>
                                            {candidateResult?.TimeTaken}
                                        </div>
                                        <div className='text-[#757575] text-xs font-medium'> Time Taken </div>
                                    </div>
                                </div>
                                <div className='dashboard-card card h-[5rem] w-full flex items-center px-4 gap-2'>
                                    <div className={`dash-icon bg-[#FFB800]/10 text-[#FFB800] w-[3rem] h-[3rem] rounded-xl flex justify-center items-center`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                        </svg>
                                    </div> 
                                    <div className='flex flex-col gap-1'>
                                        <div className='font-semibold'>
                                            0{candidateResult?.Warnings}
                                        </div>
                                        <div className='text-[#757575] text-xs font-medium'>Total Violations </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className='card w-full h-full flex items-center justify-center'>                                 
                            <QuestionAnalyse
                                quesLoading={quesLoading}
                                questions ={questions}
                                answerArray = {answerArray}
                                openQuestionModule={openQuestionModule}
                                violationQuestionArray={violationQuestionArray}
                                questionArry={questionArry}
                            />
                            <div id='questionModule' className='hidden h-full w-full absolute justify-center items-center backdrop-blur-sm'>
                                <QuestionModule
                                    quesNo = {quesNo}
                                    setQuesNo={setQuesNo}
                                    questions ={questions}
                                    answerArray = {answerArray}
                                    questionsArr={questionArry}
                                    closeQuestionModule = {closeQuestionModule}
                                    violationQuestionArray={violationQuestionArray}
                                />
                            </div> 
                        </div>
                    </div>
                )
            )
        }
    </>
  )
}

export default ProfileRightPanel
