import React,{useState, useEffect} from 'react'
import Webcam from "react-webcam";
import { motion } from 'framer-motion';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { Button, TextInput } from '../../components';
import SoundMeter from './SoundMeter';
import { BROWSER_CHROME, BROWSER_EDGE, BROWSER_SAFARI, BROWSER_FIREFOX, BROWSER_OTHER } from "../../constant/Constants";


const SetupLeft = ({step,hasCameraPermission,hasMicPermission,sethasMicPermission,setisMicWorking}) => {

  const [microphones, setmicrophones] = useState([])
  const [selectedMicrophone, setselectedMicrophone] = useState()
  const [isMiceTesting,setisMiceTesting] = useState(0) // 1: testing is in process  0: no testing 2: testing finish 3: fail testing
  const {
    transcript,
    // listening,
    resetTranscript,
    // browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() =>{
    getAllMicrophones()
  },[])

  useEffect(() =>{
    console.log("transcript - ")
    console.log(JSON.stringify(transcript))
    console.log(transcript)
    if((transcript.includes("Hello") || transcript.includes("hello")) && transcript.includes("testing")){
      setisMicWorking(true)
      setisMiceTesting(2)
    }else{
      if(isMiceTesting === 1){
        setTimeout(() => {
          setisMiceTesting(3)
          resetTranscript()
        }, 20000);
      }      
    }
  },[transcript,isMiceTesting])

  function getBrowserName(){    
      let userAgent = navigator.userAgent;
      let browserName;
      // console.log(JSON.stringify(userAgent))
      if(userAgent.match(/chrome|chromium|crios/i)){
          browserName = BROWSER_CHROME;
        }else if(userAgent.match(/firefox|fxios/i)){
          browserName = BROWSER_FIREFOX;
        }  else if(userAgent.match(/safari/i)){
          browserName = BROWSER_SAFARI;
        } else if(userAgent.match(/edg/i)){
          browserName = BROWSER_EDGE;
        }else{
          browserName=BROWSER_OTHER;
        }
        return browserName;
  }

  function renderWarningView(){
    var browserName =  getBrowserName();
    if(browserName === BROWSER_SAFARI){
      return(
        <motion.div
        className='small-card w-full py-5 px-12 flex flex-col gap-2 items-center'
        transition={{ease: "linear", duration: 0.5}}
        variants={{
          initial: { scale: 0 ,y: 0},
          final:{scale: 1, y: -20},
        }}
        animate= {hasCameraPermission === false ? 'final' : 'initial'}
      >
        <h1 className='text-xl font-semibold text-center'>Camera access is blocked</h1>
        <p className='text-lg text-[#666] font-medium text-center'>App requires access to your camera.</p>
        <ul className='list-disc ml-8 flex flex-col gap-1 mb-2'>
          <li>Go to the safari browser's settings ( by clicking on "safari" in top bar )</li>
          <li>Give camara and microphone permission from safari settings dialog </li>
          <li>and then Retry</li>
        </ul>
        <Button
          text='Retry'
          width='6rem'
          handleClick={()=> window.location.reload()}
        />
      </motion.div>
      )
    }else{
      return(
        <motion.div
        className='small-card w-full py-5 px-12 flex flex-col gap-2 items-center'
        transition={{ease: "linear", duration: 0.5}}
        variants={{
          initial: { scale: 0 ,y: 0},
          final:{scale: 1, y: -20},
        }}
        animate= {hasCameraPermission === false ? 'final' : 'initial'}
      >
        <h1 className='text-xl font-semibold text-center'>Camera access is blocked</h1>
        <p className='text-lg text-[#666] font-medium text-center'>App requires access to your camera.</p>
        <ul className='list-disc ml-8 flex flex-col gap-1 mb-2'>
          <li>
            Click the camerea blocked icon 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mx-2 text-red-400">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 01-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 00-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
            </svg><br/>
            in your browser's address bar
          </li>
          <li>Select always allow from the popup</li>
          <li>and then Retry</li>
        </ul>
        <Button
          text='Retry'
          width='6rem'
          handleClick={()=> window.location.reload()}
        />
      </motion.div>
      )
    }
   
  }

  function getAllMicrophones(){
    var stream;
    navigator.mediaDevices.getUserMedia({ audio:true })
    .then(s => (stream = s), e => console.log(e.message))
    .then(() => navigator.mediaDevices.enumerateDevices())
    .then(devices => {
    // stream && stream.stop();
    // console.log("-----------------------------------")
    // console.log(JSON.stringify(devices))
    // console.log("-----------------------------------")

    // console.log(devices.length + " devices.");
    var arrMicrophones = []
    devices.forEach(d => {
      if(d.kind === "audioinput"){
        microphones.push(d.label)
      }
      // console.log(d.kind + ": " + d.label)
    });
    })
    .catch(e => console.log(e));
  }

  function renderMiceView(){
    if(isMiceTesting <= 1){
      return(
        <motion.div
          className='small-card w-[30rem] p-8 flex flex-col gap-4'
          transition={{ease: "linear", duration: 0.5}}
          variants={{
            initial: { scale: 0 ,y: 0},
            final:{scale: 1, y: -20},
          }}
          animate= {hasMicPermission === true ? 'final' : 'initial'}
        >
          <div className='w-full'>   
            <label className="mb-1 text-[#000]">
              Test your Microphone
            </label>
            {/* <TextInput
              type="select"
              idName="Microphones"
              inputName="Microphones"
              isRequired={true}
              inputValue={selectedMicrophone}
              handleChange={(e) => {
                console.log(e.target.value)
                setselectedMicrophone(e.target.value)
              }}
              selectOptions= {microphones}
              placeholderText={"Select Microphone"}
              /> */}
          </div>
          <div className='my-2'>
            <SoundMeter/>
          </div>
        <div className='flex gap-2'>
                {isMiceTesting === 0 ?
                  <Button
                  version="primary"
                  text="Test Mic"
                  // width="fit-content"
                  handleClick={()=>{
                    setisMiceTesting(1);
                    SpeechRecognition.startListening({ continuous: true })
                  }}
                  />
                : 
                <div className='flex flex-col gap-2'>
                  <label className="text-sm mb-1">
                    Say <span className=' text-[#8A24F1]'>"Hello, This is testing"</span>
                  </label>
                  {/* <lable>{transcript}</lable> */}
                </div>
               
                }
        </div>        
      </motion.div>
      )
    }else if(isMiceTesting === 2){
      // finish
      return(
        <motion.div
          className='small-card w-[25rem] p-8 flex flex-col gap-2 items-center'
          // transition={{ease: "linear", duration: 0.5}}
          // variants={{
          //   initial: { scale: 0},
          //   final:{scale: 1},
          // }}
          // animate= {hasMicPermission === true ? 'final' : 'initial'}
        >
        <img src="/img/miceworking.png" alt="mic" width='120px'/>
        Microphone testing successful !
      </motion.div>
      )
    }else{
      // error
      return(
        <motion.div
        className='small-card w-[25rem] p-8 flex flex-col gap-2 items-center'
        // transition={{ease: "linear", duration: 0.5}}
        // variants={{
        //   initial: { scale: 0},
        //   final:{scale: 1},
        // }}
        // animate= {hasMicPermission === true ? 'final' : 'initial'}
      >
      <img src="/img/micenotworking.png" alt="mic" width='120px'/>
      Voice not detected or mached.
      <Button
        version="primary"
        text="Test Again"
        // width="fit-content"
        handleClick={()=>{
          setisMiceTesting(1);
          SpeechRecognition.startListening({ continuous: true })
        }}
      />
      </motion.div>
      )
    }
  }
  return (
    <>
      {step === "camera" && 
        <div className='flex flex-col justify-center items-center'>
          <motion.div
            className='z-10'
            transition={{ease: "linear", duration: 0.5}}
            variants={{
              initial: { scale: 2},
              final:{scale: 1},
            }}
            animate= {hasCameraPermission === true || hasCameraPermission === false ? 'final' : 'initial'}
          >
            <img src="/img/camera.png" alt="camera" width='120px'/>
          </motion.div>          
          {hasCameraPermission === true ? (
              <motion.div
                transition={{ease: "linear", duration: 0.5}}
                variants={{
                  initial: { scale: 0 ,y: 0},
                  final:{scale: 1, y: -30},
                }}
                animate= {hasCameraPermission === true ? 'final' : 'initial'}
              >
                <Webcam width="400" className='rounded-3xl' audio={false}/>
              </motion.div>
          ) : hasCameraPermission === false ? (
            renderWarningView()
          ) : (
            ""
          )
          }
        </div>
      }
        
      {step === "internet" && 
        <div className=''>
          <img src="img/internet.png" alt="internet" />
        </div>
      }
        
      {step === "mic" && 
        <div className='flex flex-col justify-center items-center'>
          <motion.div
            className='z-10'
            transition={{ease: "linear", duration: 0.5}}
            variants={{
              initial: { scale: 2},
              final:{scale: 1},
            }}
            animate= {hasMicPermission === true || hasMicPermission === false ? 'final' : 'initial'}
          >
            <img src="/img/mic.png" alt="mic" width='120px'/>
          </motion.div>          
          {hasMicPermission === true ? (
            renderMiceView()
          ) : hasMicPermission === false ? (
            <motion.div
              className='small-card w-full py-5 px-12 flex flex-col gap-2 items-center'
              transition={{ease: "linear", duration: 0.5}}
              variants={{
                initial: { scale: 0 ,y: 0},
                final:{scale: 1, y: -20},
              }}
              animate= {hasMicPermission === false ? 'final' : 'initial'}
            >
              <h1 className='text-xl font-semibold text-center'>Microphone access is blocked</h1>
              <p className='text-lg text-[#666] font-medium text-center'>App requires access to your microphone.</p>
              <ul className='list-disc ml-8 flex flex-col gap-1 mb-2'>
                <li>
                  Click the camerea/mic blocked icon 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mx-2 text-red-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 01-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 00-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
                  </svg><br/>
                  in your browser's address bar
                </li>
                <li>Select always allow from the popup</li>
                <li>and then Retry</li>
              </ul>
              <Button
                text='Retry'
                width='6rem'
                handleClick={()=> window.location.reload()}
              />
            </motion.div>
          ) : (
            ""
          )
          }
        </div>
      }

      {step === "instruction" && 
        <div className=''>
          <img src="img/refresh.png" alt="refresh" />  
        </div>
      }
    </>
  )
}

export default SetupLeft
