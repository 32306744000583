import React from 'react'
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const ResumeView = ({resumeUrl,setResumeOpen, resumeOpen}) => {
    const [show, setShow] = useState(false)
  return (
    <motion.div
        className='relative card h-full w-1/2 flex justify-center overflow-hidden'
        onHoverStart={() => setShow(true)}
        onHoverEnd={() => setShow(false)}
        transition={{duration:0.4}}
        variants={{
            initial: { scale: 0},
            final:{scale: 1},
        }}
        animate= {resumeOpen ? 'final' : 'initial'}
    >
        <motion.div
            variants={{
                initial: { y: -100 , opacity: 0},
                final:{y: 0 , opacity: 1},
            }}
            animate= {show ? 'final' : 'initial'}
            className='fixed flex gap-2 top-6 right-6 z-20 text-[#666]'
        >
           
            <Link 
                to={resumeUrl} 
                target="_blank" rel="noopener noreferrer"
            >
                 <div className={`bg-[#000]/10 backdrop-blur-sm rounded-full cursor-pointer p-2`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                </div>
            </Link>
            <div
                className={`bg-[#000]/10 backdrop-blur-sm rounded-full cursor-pointer p-2`}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

            </div>

        </motion.div>

        {/* <motion.div
            variants={{
                initial: { y: -100 , opacity: 0},
                final:{y: 0 , opacity: 1},
            }}
            animate= {showX ? 'final' : 'initial'}
            onClick={() => setResumeOpen(false)} 
            className={`fixed top-6 right-6 p-2 bg-slate-100 rounded-full cursor-pointer z-20`}
        > ✖️ </motion.div> */}
        <div className='absolute  top-0 left-0 w-full h-full overflow-y-auto'>
            <iframe
                src={resumeUrl}
                className="h-full w-full"
                allow="autoplay"
                title="resume"
            />
        </div>
        
    </motion.div>
  )
}

export default ResumeView