import React,{useState,useEffect} from 'react';

import {Topbar2} from '../../components'
import InstructionsTopPanel from './InstructionsTopPanel';
import InstructionsMainPanel from './InstructionsMainPanel';

const ExamInstructions = () => {

	const [ examDetails, setExamDetails] = useState([]);

	//fetching examDetails from local storage
	useEffect(() => {
		const examDetails = JSON.parse(localStorage.getItem('candidateDetails'));
		if (examDetails) {
			setExamDetails(examDetails);			
		}
	}, []);

  return (
    <div className='w-full overflow-y-auto scrollable'>
		<div className='fixed w-full z-20'>
			<Topbar2 name={examDetails.name} email={examDetails.emailId} />
		</div>

		<div className='sticky z-10 top-[-52px]'>
			<InstructionsTopPanel exam={examDetails.exam} />
		</div>
		
		<div>
			<InstructionsMainPanel details={examDetails} />
		</div>
		

    </div>
  )
}

export default ExamInstructions
