import React from 'react'
import './checkbox.css'

const Checkbox = ({checkId,checkName,checkValue,handleChange,labelText}) => {
  return (
    <div className='checkBtn'>
        <input
            className='hidden'
            type="checkbox"
            id={checkId}
            name={checkName}
            value={checkValue}
            onChange={handleChange}
        />
        <label htmlFor={checkId} className='checkLabel'>
            {labelText}
        </label>                        
    </div>
  )
}

export default Checkbox