import React,{useEffect, useState,useCallback} from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";

import emailBody from '../../emailBody';
import {Button,TextInput,Success,RadioInput } from '../../components'

const NewSchedule = ({closeModule,newScheduleModuleOpen,adminName,adminEmail,dataSheetId,assessmentNames,setUpdateSchedules}) => { 

const [inviteSchedule, setInviteSchedule] = useState('invite')
const [name, setName] = useState();
const [email, setEmail] = useState();
const [emailAddresses, setEmailAddresses] = useState();
const [profile, setProfile] = useState()
const [maxQuestions, setMaxQuestions] = useState()
const [maxViolations, setMaxViolations] = useState()
const [invites, setInvites] = useState('single')
const [inviteExpiry, setInviteExpiry] = useState('48hrs')
const [expDate, setExpDate] = useState()
const [expTime, setExpTime] = useState()
const [examDate, setExamDate] = useState()
const [examTime, setExamTime] = useState()
const [errorMessage, setErrorMessage] = useState("")
const [openSuccessModule, setOpenSuccessModule] = useState(false)
const [bulkInviteSuccessModule, setBulkInviteSuccessModule] = useState(false)
const [successInviteEmails, setSuccessInviteEmails] = useState([])
const [unSuccessInviteEmails, setUnSuccessInviteEmails] = useState([])
const [loading, setLoading] = useState(false)

useEffect(() => {
    const fetchAdmin = async () => {
        if(adminEmail){
            setLoading(true)
            const response = await axios.post(process.env.REACT_APP_ADMIN_LOGIN ,adminEmail);
            const adminData = response?.data.admin
            setMaxQuestions(adminData.maxQuestions);
            setMaxViolations(adminData.maxViolations);
            setLoading(false)
        }
    }
    fetchAdmin()
},[adminEmail])

const [files, setFiles] = useState([]);
const onDrop = useCallback(acceptedFiles => {
    setFiles(prev => [...prev, ...acceptedFiles]);
}, []);
const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: {'text/csv': []}});
const fileList = files.map(file => (
    <div key={file.path} className="text-sm text-center flex gap-2">
      <p>{file.path}</p>
      <p>({(file.size/1024).toFixed(2)} kb)</p >
    </div>
    
  ));

const handleSchedule = async (e) => {
    e.preventDefault();
    setErrorMessage("")
    const code = (Math.random() + 1).toString(36).substring(6)
    const currentDate = new Date();
    const startDate = currentDate.toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
    const startTime = currentDate.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    const scheduleDate =  new Date(examDate).toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
    const scheduleTime = new Date(`${examDate} ${examTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    let expiryDate, expiryTime;
    if(inviteSchedule === 'schedule') {
        expiryDate = new Date(new Date(`${examDate} ${examTime}`).getTime() + 48 * 60 * 60 * 1000).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
        expiryTime = new Date(`${examDate} ${examTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    }else {
        if(inviteExpiry === '48hrs'){
            expiryDate = new Date(currentDate.getTime() + 48 * 60 * 60 * 1000).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
            expiryTime = currentDate.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
        }else{
            expiryDate = new Date(expDate).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
            expiryTime = new Date(`${expDate} ${expTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
        }
    }    
    const expireDateTime = new Date(`${expiryDate} ${expiryTime}`).getTime()

    const sendEmail = async () => {
        const candidateEmailSubject = `Thank you for applying at ${adminName} - Assessment Round`
        const hrEmailSubject = `Assessment Scheduled - ${name}`
        const candidateEmailMessage = inviteSchedule === 'invite'
            ? emailBody.candidateEmail(adminName,name,email,startDate,startTime,expiryDate,expiryTime,code)
            : emailBody.candidateEmail(adminName,name,email,scheduleDate,scheduleTime,expiryDate,expiryTime,code)
        const hrEmailMessage = inviteSchedule === 'invite'
            ? emailBody.hrEmail(name,email,startDate,startTime,profile,expiryDate,expiryTime)
            : emailBody.hrEmail(name,email,scheduleDate,scheduleTime,profile,expiryDate,expiryTime)
        const candidateEmailData = JSON.stringify([email,candidateEmailSubject,candidateEmailMessage])
        const hrEmailData = JSON.stringify([adminEmail,hrEmailSubject,hrEmailMessage])
        const mailApi = process.env.REACT_APP_EMAIL_API
        const candidateEmailResponse = await axios.post(mailApi,candidateEmailData)
        const hrEmailResponse = await axios.post(mailApi,hrEmailData)
    }

        if(!profile){
            return setErrorMessage("Please select Assessment Test Name")
        }else if(!name){
            return setErrorMessage("Please fill the Candidate Name")   
        }else if(!email){
            return setErrorMessage("Please fill the Candidate Email id")        
        } else if(inviteSchedule === 'invite' && inviteExpiry === 'customExpiry'){
            if(!expDate){
                return setErrorMessage("Please select a Assessment Expiry Date")
            }else if(!expTime){
                return setErrorMessage("Please select a Assessment Expiry Time")
            }
        }else if(inviteSchedule === 'schedule'){
            if(!examDate){
                return setErrorMessage("Please select a Assessment Date")
            }else if(!examTime){
                return setErrorMessage("Please select a Assessment Time")
            }
        }
        // if(inviteExpiry === 'customExpiry' && (expDate === '' || expTime === '')){
        //     return setErrorMessage("Please fill the assessment expiry date & time")
        // }
        const validateEmail = (email) => {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
        if(!validateEmail(email)){
            return setErrorMessage("Email Address is invalid")
        }
        if(new Date(`${expiryDate} ${expiryTime}`).getTime() - new Date().getTime() < 47*60*60*1000){
            return setErrorMessage("The Exam window cannot be less then 48 hours")
        }

        setLoading(true)
        const uid = `uid_${Date.now()}`
        const sheetData = inviteSchedule === 'invite' 
            ? JSON.stringify([dataSheetId,uid,adminEmail,name,email,profile,startDate,startTime,code,"",maxViolations,maxQuestions,'MCQ','all',expireDateTime,inviteSchedule])
            : JSON.stringify([dataSheetId,uid,adminEmail,name,email,profile,scheduleDate,scheduleTime,code,"",maxViolations,maxQuestions,'MCQ','all',expireDateTime,inviteSchedule])
        const postScheduleApi = process.env.REACT_APP_POST_SCHEDULE_API
        const response = await axios.post(postScheduleApi,sheetData)
        setLoading(false)
        if(response.data.status === 'added'){
            resetForm()
            setOpenSuccessModule(true)
            sendEmail();
        }else if(response.data.status === 'emailExists'){
            setErrorMessage("Email already Exists")
        }else{
            setErrorMessage('Something went wrong')
        }
        setUpdateSchedules((prev) => prev+1)        
    
}

const handleBulkInvite = async () => {
    if (!profile) return setErrorMessage("Please select assessment test name");  
    setLoading(true)
    let names = [];
    let emails = [];

    if(!profile){
        return setErrorMessage("Please select Assessment Test Name")     
    } else if(inviteSchedule === 'invite' && inviteExpiry === 'customExpiry'){
        if(!expDate){
            return setErrorMessage("Please select a Assessment Expiry Date")
        }else if(!expTime){
            return setErrorMessage("Please select a Assessment Expiry Time")
        }
    }

    const currentDate = new Date();
    const startDate = currentDate.toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
    const startTime = currentDate.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    
    let expiryDate, expiryTime;    
    if(inviteExpiry === '48hrs'){
        expiryDate = new Date(currentDate.getTime() + 48 * 60 * 60 * 1000).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
        expiryTime = currentDate.toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    }else{
        expiryDate = new Date(expDate).toLocaleString('en-in', { day: 'numeric', month: 'short', year: 'numeric' });
        expiryTime = new Date(`${expDate} ${expTime}`).toLocaleString("en-in", { hour:"numeric", minute: "numeric"})
    }
    const expireDateTime = new Date(`${expiryDate} ${expiryTime}`).getTime()
    const postScheduleApi = process.env.REACT_APP_POST_SCHEDULE_API

    const addRecord = async (name,email,code) => {
        const uid = `uid_${Date.now()}`
        const sheetData = JSON.stringify([dataSheetId,uid,adminEmail,name,email,profile,startDate,startTime,code,"",maxViolations,maxQuestions,'MCQ','all',expireDateTime,'invite'])
        const response = await axios.post(postScheduleApi,sheetData)
        response.data.status === 'added' ? setSuccessInviteEmails(prev => [...prev, email]) : setUnSuccessInviteEmails(prev => [...prev, email])
        response.data.status === 'added' && sendEmail(email,name,code)
    }
    const sendEmail = async (candidateEmail,candidateName,code) => {
        const candidateEmailSubject = `Thank you for applying at ${adminName} - Assessment Round`
        const candidateEmailMessage = emailBody.candidateEmail(adminName,candidateName,candidateEmail,startDate,startTime,expiryDate,expiryTime,code)
        const candidateEmailData = `${candidateEmail}|${candidateEmailSubject}|${candidateEmailMessage}`
        const mailApi = process.env.REACT_APP_EMAIL_API
        const candidateEmailResponse = await axios.post(mailApi,candidateEmailData)        
    }

    if (files.length !== 0) {
        let parsedData;
        const reader = new FileReader(); 
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result);
            parsedData = csv?.data.slice(1);
            for( let i = 0; i < parsedData.length; i++){
                if(parsedData[i][0] !== "" && parsedData[i][0] !== 0){
                    names.push(parsedData[i][0])
                    emails.push(parsedData[i][1])
                }
            }
            for (let i = 0 ; i < emails.length ; i++) { 
                const code = (Math.random() + 1).toString(36).substring(6)
                await addRecord(names[i],emails[i],code)
            }
            setBulkInviteSuccessModule(true)
            setUpdateSchedules(prev => prev+1)
        };
        reader.readAsText(files[0]);
    }else{
        for (let i = 0 ; i < emailAddresses.length ; i++) {
            names.push(emailAddresses[i]
                .split('@')[0]
                .replace(/\d/g, ' ')
                .replace(/[^\w\s]/g, ' ')
                .replace(/\s+/g, ' ')
                .trim()
            )
            emails.push(emailAddresses[i])
        }
        for (let i = 0 ; i < emails.length ; i++) {  
            const code = (Math.random() + 1).toString(36).substring(6)
            await addRecord(names[i],emails[i],code)
        }
        resetForm()
        setBulkInviteSuccessModule(true)
        setUpdateSchedules((prev) => prev+1)
    }
} 
const resetForm = () => {
    setName(""); setEmail(""); setProfile(""); setExpDate();setExamDate(); setExpTime(); setExamTime();
    setEmailAddresses(''); setErrorMessage('');setInvites('single');setInviteExpiry('48hrs');
}

  return (
    <motion.div 
        className='card invite-popup flex flex-col w-full sm:w-2/3 lg:w-1/2 xl:w-2/5 relative h-fit'
        variants={{initial: { scale: 0,opacity: 0}, final: { scale: 1, opacity : 1}}}            
        initial={'initial'} transition={{ duration: 0.2 }}
        animate = {newScheduleModuleOpen ? 'final' : 'initial'} 
    >
        <div className={`absolute w-full h-full top-0 left-0 justify-center items-center backdrop-blur-[2px] rounded-2xl bg-[#0000]/10 z-20 ${openSuccessModule ? 'flex' : 'hidden'}`}>
            <Success
                closeSuccessModule={() => setOpenSuccessModule(false)}
                module = "newSchedule"
                closeScheduleModule = {closeModule}
            />
        </div>
        <div className={`absolute w-full h-full top-0 left-0 justify-center items-center backdrop-blur-[2px] rounded-2xl bg-[#0000]/10 z-20 ${bulkInviteSuccessModule ? 'flex' : 'hidden'}`}>
            <Success
                closeSuccessModule={() => {
                    setBulkInviteSuccessModule(false)
                    setSuccessInviteEmails([])
                    setUnSuccessInviteEmails([])
                }}
                module = "inviteBulk"
                closeScheduleModule = {closeModule}
                successInviteEmails = {successInviteEmails}
                unSuccessInviteEmails={unSuccessInviteEmails}
            />
        </div>
        <div className='tab-wrapper w-full items-center flex text-[#666]}'>
            <div className='close-icon absolute top-3 right-4 bg-[#F4F4F4] p-1 rounded-full cursor-pointer'>
                <svg onClick={() => {closeModule(); resetForm()}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
            </div>
            <div className={`tab-btn ${inviteSchedule === 'invite' && 'active'}`}>
                <div
                    className='w-full h-full cursor-pointer text-center'
                    onClick={(e) => {
                        setInviteSchedule('invite')
                        resetForm()
                    }}
                >
                    Invite Candidate
                </div>
            </div>
            {/* <div className={`tab-btn ${inviteSchedule === 'schedule' && 'active'}`}>
                <div
                    className='w-full h-full cursor-pointer text-center'
                    onClick={(e) => {
                        setInviteSchedule('schedule')
                        resetForm()
                    }}
                >
                    Schedule Assessment
                </div>
            </div>       */}
        </div>  
        <div className='rounded-2xl flex justify-center py-5 relative z-10'>     
            <form id="scheduleForm" className="w-[90%] flex flex-col gap-3 items-center">
                <div className="flex flex-col gap-3 w-full scrollbar-none overflow-y-auto p-2">
                    <div className='w-full'>
                        <label className="text-xs mb-1 text-[#888]">
                            Assessment Test Name
                        </label>
                        <TextInput
                            type="select"
                            idName="jobProfile"
                            inputName="applyFor"
                            isRequired={true}
                            inputValue={profile}
                            handleChange={(e) => setProfile(e.target.value)}
                            selectOptions= {assessmentNames}
                            placeholderText="Select Assessment"
                        />
                    </div>
                    {  inviteSchedule === 'invite' &&
                        <div className='w-full'>
                            <label className="text-xs mb-1 text-[#888]">
                                Invites
                            </label>
                            <div className='w-full items-center gap-4 flex'>
                                <RadioInput
                                    inputName='invite'
                                    checked={invites === 'single'}
                                    inputValue='single'
                                    iniputId='singleInvite'
                                    text='Single Invite'
                                    border='none'
                                    handleChange={(e) => setInvites(e.target.value)}
                                />
                                <RadioInput
                                    inputName='invite'
                                    inputValue='bulk'
                                    iniputId='bulkInvite'
                                    text='Bulk Invite'
                                    border='none'
                                    handleChange={(e) => setInvites(e.target.value)}
                                />
                            </div>                                               
                        </div
                    >} 
                    { invites === 'single' ?
                        <>
                            <div>
                                <label className="text-xs mb-1 text-[#888]">
                                    Candidate Name
                                </label>
                                <TextInput
                                    type='text'
                                    isRequired = {true}
                                    inputName="candidateName"
                                    inputValue={name}
                                    handleChange={(e) => setName(e.target.value)}
                                    placeholderText="eg. John Deo"
                                />
                            </div>
                            <div>
                                <label className="text-xs mb-1 text-[#888]">
                                    Candidate Email
                                </label>
                                <TextInput
                                    type='email'
                                    isRequired = {true}
                                    inputName="candidateEmail" 
                                    inputValue={email}
                                    handleChange={(e) => setEmail(e.target.value)}
                                    placeholderText="eg. johndeo@gmail.com"
                                />
                            </div>
                        </>
                        :
                        <>
                            <div className='flex flex-col gap-1'>
                                <label className="text-xs text-[#888]">
                                    Email Addresses
                                </label>
                                <TextInput
                                    type='email'
                                    isRequired = {true}
                                    inputName="emailAddresses" 
                                    inputValue={emailAddresses}
                                    handleChange={(e) => setEmailAddresses(e.target.value.replace(/\s/g, "").split(','))}
                                    placeholderText="Email Addresses"
                                />
                                <p className='text-[11px]'>
                                    Enter email addresses separated by comma
                                </p>
                                <p className='text-sm font-medium p-1'>OR</p>
                                <motion.div 
                                    className='text-sm border-[3px] rounded-xl w-fit cursor-pointer hover:bg-[#eee]'
                                    whileTap={{scale: 0.97 }}
                                >                                    
                                    <div {...getRootProps({ className: "dropzone flex flex-col cursor-pointer w-full h-full flex flex-col p-3 px-10 items-center" })}>
                                        <input {...getInputProps()} />
                                        {fileList.length > 0 
                                            ? fileList[0] 
                                            : <div className='flex gap-4 items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5 text-[#666]">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                                </svg>
                                                Upload CSV
                                            </div>                                            
                                        }                                        
                                    </div>
                                </motion.div>
                                <p className='text-[11px]'>
                                    <a href = '/invite.csv'>
                                        <span className='text-[12px] text-[#55F] hover:underline mr-1'>Click here</span>
                                        to download supported CSV format file
                                    </a>                                    
                                </p>
                            </div>
                        </>
                    }
                    <div className='flex gap-4'>
                        { maxQuestions === "" && (
                            <div className='w-full'>
                                <label className="text-xs mb-1 text-[#888]">
                                    No. of questions
                                </label>
                                <TextInput
                                    type="number"
                                    idName="maxQuestions"
                                    inputName="maxQuestions"
                                    isRequired={true}
                                    inputValue={maxQuestions}
                                    handleChange={(e) => setMaxQuestions(e.target.value)}
                                    placeholderText="No. of Questions"
                                />
                            </div>   
                        )}                                                 

                            {/* questionType */}
                    </div>   
                        
                    { inviteSchedule === 'invite' ?
                        <div className='flex flex-col gap-2'>
                            <div>
                                <label className="text-xs text-[#888]">
                                    Invitation Expiry
                                </label>
                                <div className='w-full items-center gap-5 flex'>
                                    <RadioInput
                                        inputName='expiry'
                                        checked={inviteExpiry === '48hrs'}
                                        inputValue='48hrs'
                                        iniputId='48hs'
                                        text='48 hrs from invite'
                                        handleChange={(e) => setInviteExpiry(e.target.value)}
                                    />
                                    <RadioInput
                                        inputName='expiry'
                                        inputValue='customExpiry'
                                        iniputId='customExpiry'
                                        text='Set Expiry Date'
                                        handleChange={(e) => setInviteExpiry(e.target.value)}
                                    />
                                </div>
                            </div>  
                            { inviteExpiry === 'customExpiry' &&
                                <div className='flex items-center gap-4'>
                                    <TextInput
                                        type="date"
                                        inputName="expDate"
                                        // isRequired = {true}
                                        inputValue={expDate}
                                        handleChange={e => setExpDate(e.target.value)}
                                    />
                                    <TextInput
                                        type="time"
                                        inputName="expTime"
                                        // isRequired = {true}
                                        inputValue={expTime}
                                        handleChange={e => setExpTime(e.target.value)}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <div className='flex flex-col gap-2'>
                            <label className="text-xs text-[#888]">
                                Assessment Date & Time
                            </label>
                            <div className='flex items-center gap-4'>
                                <TextInput
                                    type="date"
                                    inputName="examDate"
                                    // isRequired = {true}
                                    inputValue={examDate}
                                    handleChange={e => setExamDate(e.target.value)}
                                />
                                <TextInput
                                    type="time"
                                    inputName="examTime"
                                    // isRequired = {true}
                                    inputValue={examTime}
                                    handleChange={e => setExamTime(e.target.value)}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className='invite-footer w-full flex item-center justify-end py-2'>
                    <div className='invite-error text-red-500 text-sm text-center'>
                        {errorMessage ? errorMessage : ''}
                    </div>
                    {loading ? (
                        <div className='text-[#ccc] font-semibold text-center'>
                            Loading ...
                        </div>
                    ) : (
                        <Button
                            text={inviteSchedule === 'schedule' ? 'Schedule Assessment' :
                                invites === 'single' ? 'Invite Candidate' : 'Invite Candidates'}
                            handleClick={(inviteSchedule === 'invite' && invites === 'bulk') ? handleBulkInvite : handleSchedule}
                            className='btn-primary'
                        /> 
                    )}
                      
                </div>
            </form>
        </div>
        </motion.div>
  )
}

export default NewSchedule


